h2 {
  font-size: 18px;
  font-weight: 600;
}

.checkout-page-container {
  justify-content: space-between;
  padding: 1em 0em;
  /* width: 86vw; */
  margin: 0px 3%;
}

.anyflexbox body,
.anyflexbox .content,
.anyflexbox .content .wrap,
.anyflexbox .main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.content {
  overflow: hidden;
}

.anyflexbox .content .wrap {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.wrap {
  display: block;
  /* margin: 0 auto; */
  /* max-width: 45em; */
  zoom: 1;
  margin: 0 3%;
}

/* .main .logo {
    display: none;
} */

.logo {
  display: block;
  vertical-align: middle;
}

a,
.link {
  text-decoration: none;
  color: #3d405b;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.logo__image--medium {
  max-height: 2.8571428571em;
}

.logo__image {
  display: inline-block;
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

.breadcrumb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #ffffff !important;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.anyflexbox .breadcrumb__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.breadcrumb__item {
  display: inline;
  font-size: 0.8571428571em;
  color: #737373;
}

.breadcrumb__item--current {
  font-weight: 500;
  color: #333333;
}

.main .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor;
}

.breadcrumb__chevron-icon {
  margin: 0 0.6428571429em;
}

.icon-svg--size-10 {
  width: 10px;
  height: 10px;
}

.icon-svg {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
}

.sidebar {
  position: relative;
  color: #535353;
}

.sidebar__header {
  margin-bottom: 2.5em;
  display: none;
}

@media (min-width: 1000px) {
  .anyflexbox .content .wrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .wrap {
    max-width: 85.571429em;
  }

  .page--no-banner .main,
  .page--no-banner .sidebar {
    padding-top: 4em;
  }

  .main {
    width: 52%;
    width: 52%;
    padding-right: 6%;
    float: left;
  }

  .main__header {
    padding-bottom: 2em;
  }

  .page--logo-main .main .logo {
    display: block;
  }

  .logo__image--medium {
    max-height: 4.2857142857em;
  }

  .page--logo-main .breadcrumb {
    margin-top: 1em;
  }

  .page--no-banner .main,
  .page--no-banner .sidebar {
    padding-top: 4em;
  }

  .sidebar {
    width: 47%;
    padding-left: 4%;
    background-position: left top;
    float: right;
    padding-left: 4%;
    padding-top: 4%;
  }

  .order-summary__section:first-child {
    padding-top: 0;
  }

  .order-summary__sections {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    /* height: calc(100vh - 20em); */
    width: 100%;
  }

  .order-summary__section--product-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    min-height: 7em;
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  .order-summary__section {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .no-js .order-summary__section__content,
  .no-touchevents .order-summary__section__content {
    overflow: hidden;
  }

  .order-summary__section__content {
    min-width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .order-summary__section--product-list .product-table {
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    position: relative;
    z-index: 1;
  }
}

@media (min-width: 750px) {
  .main {
    padding-top: 1.5em;
  }

  h1,
  .heading-1 {
    font-size: 2em;
  }

  .main {
    padding-top: 1.5em;
  }

  .main__content {
    padding-bottom: 4em;
  }

  .section {
    padding-top: 3em;
  }

  .section__header {
    margin-bottom: 1.5em;
  }

  .section__header {
    margin-bottom: 1.5em;
  }

  .floating-labels .field--half {
    width: 50%;
  }

  .anyflexbox .step__footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .step__footer {
    margin-top: 1.5em;
  }

  .step__footer__continue-btn {
    float: left;
  }

  .step__footer__previous-link,
  .step__footer__info {
    float: right;
  }

  .anyflexbox .step__footer__previous-link,
  .anyflexbox .step__footer__info {
    margin-left: 1.5em;
  }

  .order-summary__section--product-list+.order-summary__section {
    border: 0;
  }

  .section {
    padding-top: 3em;
  }
}

.section__header {
  position: relative;
  margin-bottom: 1em;
}

form {
  margin: 0;
}

.anyflexbox .main__content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  /* -webkit-appearance: none; */
  -moz-appearance: none;
  appearance: auto;
  -webkit-font-smoothing: inherit;
  border: none;
  background: transparent;
  line-height: normal;
}

.section:first-child {
  padding-top: 0;
}

.layout-flex--loose-horizontal {
  margin-left: -0.5714285714rem;
  margin-right: -0.5714285714rem;
}

.layout-flex--tight-vertical {
  margin-bottom: -0.1428571429rem;
  margin-top: -0.1428571429rem;
}

.layout-flex--wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.layout-flex {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: -0.2857142857rem;
}

.layout-flex--loose-horizontal .layout-flex__item {
  padding-left: 0.5714285714rem;
  padding-right: 0.5714285714rem;
}

.layout-flex--tight-vertical .layout-flex__item {
  padding-bottom: 0.1428571429rem;
  padding-top: 0.1428571429rem;
}

.layout-flex--loose-horizontal .layout-flex__item {
  padding-left: 0.5714285714rem;
  padding-right: 0.5714285714rem;
}

.layout-flex--tight-vertical .layout-flex__item {
  padding-bottom: 0.1428571429rem;
  padding-top: 0.1428571429rem;
}

.section {
  position: relative;
  padding-top: 2em;
}

.main h2,
.main .heading-2 {
  color: #333333;
}

.section__content {
  zoom: 1;
}

.field {
  width: 100%;
  float: left;
  padding: 0.4285714286em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.field__input {
  border: 1px transparent solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 0.7em 0.7857142857em;
  word-break: normal;
  line-height: inherit;
}

.main .field__input {
  background-color: white;
  color: #333333;
  border-color: #d9d9d9;
}

.field__input-wrapper {
  position: relative;
}

.floating-labels .main .field__label {
  color: #737373;
}

.animate-floating-labels .field__label {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.floating-labels .field__label {
  font-size: 0.8571428571em;
  font-weight: normal;
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 0.4285714286em;
  margin-left: 1px;
  padding: 0 0.9166666667em;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0;
}

.field__label {
  text-align: left;
  font-weight: 500;
  margin: 0.5em 0;
  display: none;
}

.field__label--visible {
  display: block;
}

.animate-floating-labels .field__input {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.step__footer {
  z-index: 2;
  /* position: relative; */
  margin-top: 2em;
}

.step__footer__previous-link,
.step__footer__info {
  display: block;
}

.btn-light:focus {}

/* .btn,
.sp-modal-toggle {
  cursor: pointer;
  display: inline-block;
  background-color: #3d405b;
  background-clip: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px transparent solid;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  padding: 1.4em 1.7em;
  text-align: center;
  position: relative;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
} */

.main__footer {
  padding: 1em 0 0 0;
  border-top: 1px solid #e6e6e6;
  margin-top: 3em;
  align-items: center;
}

.main__footer-shipping {
  padding: 1em 0 1rem 0;
  border-top: 1px solid #e6e6e6;
  margin-top: 3em;
  align-items: center;
}

.copyright-text {
  font-size: 0.8571428571em;
  color: #737373;
  text-align: center;
  margin: 0;
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

.js .visually-hidden-if-js {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

.sidebar h2,
.sidebar .heading-2 {
  color: #323232;
}

.order-summary__section {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
}

.product-table__header th {
  white-space: nowrap;
  font-weight: 500;
  color: #333333;
}

.product:first-child td {
  padding-top: 0;
}

.product td {
  padding-top: 1em;
}

.product-table td {
  border-color: #fff;
}

td:first-child,
th:first-child {
  padding-left: 0;
  text-align: left;
}

.product-table th,
.product-table td {
  padding-top: 0;
  padding-bottom: 0;
}

.product-thumbnail {
  width: 4.6em;
  height: 4.6em;
  border-radius: 8px;
  background: #fff;
  position: relative;
  border: 1px solid rgba(175, 175, 175, 0.34);
}

.product-thumbnail__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  z-index: 1;
}

.product-thumbnail__quantity {
  font-size: 0.8571428571em;
  font-weight: 500;
  line-height: 1.75em;
  white-space: nowrap;
  text-align: center;
  border-radius: 1.75em;
  background-color: rgba(114, 114, 114, 0.9);
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 1.75em;
  height: 1.75em;
  padding: 0 0.5833333333em;
  position: absolute;
  right: -0.75em;
  top: -0.75em;
  z-index: 3;
}

.product-thumbnail__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.product__description {
  text-align: left;
  width: 50%;
}

.sidebar .order-summary__emphasis {
  color: #323232;
}

.product__description__name,
.product__description__variant,
.product__description__property {
  display: block;
}

.product__description__name {
  width: 1em;
  min-width: 100%;
}

.order-summary__emphasis {
  font-weight: 500;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
}

.sidebar .order-summary__small-text {
  color: #717171;
}

/* .order-summary__small-text {
  font-size: 0.8571428571em;
} */
.product__description .reduction-code {
  margin-top: 0.2857142857em;
}

.sidebar .icon-svg--color-adaptive-light {
  color: #717171;
  fill: currentColor;
}

.reduction-code__icon {
  margin-right: 0.1428571429em;
  vertical-align: top;
}

.icon-svg--size-16 {
  width: 16px;
  height: 16px;
}

.icon-svg {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
}

td,
th {
  padding-left: 1em;
}

.product__description .reduction-code__text {
  color: #717171;
}

.sidebar .reduction-code__text {
  color: #323232;
}

.reduction-code__text {
  font-size: 1em;
}

.order-summary__section~.order-summary__section {
  border-top: 1px solid rgba(175, 175, 175, 0.34);
  /* border-bottom: 1px solid rgba(175, 175, 175, 0.34); */
}

.order-summary__section {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}

.total-line:first-child th,
.total-line:first-child td {
  padding-top: 0;
}

.total-line th,
.total-line td {
  padding-top: 0.75em;
}

td:first-child,
th:first-child {
  padding-left: 0;
  text-align: left;
}

.total-line:first-child th,
.total-line:first-child td {
  padding-top: 0;
}

.total-line th,
.total-line td {
  padding-top: 0.75em;
}

td:last-child,
th:last-child {
  text-align: right;
}

.total-line__price {
  padding-left: 1.5em;
  text-align: right;
  white-space: nowrap;
}

.total-line-table__tbody+.total-line-table__tbody .total-line:first-child th,
.total-line-table__tbody+.total-line-table__tbody .total-line:first-child td,
.total-line-table__tbody+.total-line-table__footer .total-line:first-child th,
.total-line-table__tbody+.total-line-table__footer .total-line:first-child td {
  padding-top: 1.5em;
  position: relative;
}

table {
  width: 100%;
}

.layout-flex__item--stretch {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.total-line__name_plain {
  padding-top: 1.75em !important;
}

.total-line__price_plain {
  padding-top: 1.75em !important;
}

a,
.link {
  text-decoration: none !important;
  color: #3d405b !important;
  -webkit-transition: color 0.2s ease-in-out !important;
  transition: color 0.2s ease-in-out !important;
}

.section:first-child {
  padding-top: 0;
}

.content-box:first-of-type,
.content-box-spacing:first-of-type {
  margin-top: 0;
}

.main .content-box {
  /* border-color: #d9d9d9; */
  border-color: #eeeeee;
}

.content-box {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}

.content-box__row:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.content-box__row:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.display-table .content-box__row {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.content-box__row--tight-spacing-vertical {
  padding-top: 0.8571428571em;
  padding-bottom: 0.8571428571em;
}

.review-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.review-block__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.review-block__label {
  color: #737373;
  padding-right: 1.1428571429em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 5em;
  -ms-flex: 0 1 5em;
  flex: 0 1 5em;
}

.review-block__content {
  -webkit-box-flex: 5;
  -webkit-flex: 5;
  -ms-flex: 5;
  flex: 5;
  color: #333333;
  padding-right: 1.1428571429em;
}

bdo {
  unicode-bidi: bidi-override;
}

.review-block__link {
  max-width: 10em;
  font-size: 0.8571428571em;
}

.review-block~.review-block {
  margin-top: 0.8571428571em;
  padding-top: 0.8571428571em;
  border-top: 1px solid #e6e6e6;
}

.radio-wrapper:last-child,
.checkbox-wrapper:last-child {
  margin-bottom: 0;
}

.display-table .radio-wrapper,
.display-table .checkbox-wrapper {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.radio-wrapper,
.checkbox-wrapper {
  zoom: 1;
  margin-bottom: 1em;
  display: flex;
}

.display-table .radio__input,
.display-table .checkbox__input {
  display: table-cell;
}

address {
  margin-bottom: 0 !important;
  font-style: normal;
  line-height: inherit;
}

.radio__input,
.checkbox__input {
  padding-right: 0.75em;
  white-space: nowrap;
}

.input-radio:checked {
  border-width: 7px;
}

.input-checkbox:checked,
.input-radio:checked {
  border-color: #3d405b;
}

.input-checkbox:checked,
.input-radio:checked {
  border-color: rgb(61, 64, 91);
}

.content-box .input-checkbox,
.content-box .input-radio {
  border-color: #d9d9d9;
  background-color: white;
}

.main .input-checkbox,
.main .input-radio {
  border-color: #d9d9d9;
  background-color: white;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.input-radio {
  border-radius: 50%;
}

.input-radio:checked {
  border-width: 7px;
}

.main .input-checkbox,
.main .input-radio {
  border-color: #d9d9d9;
  background-color: white;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.input-radio {
  border-radius: 50%;
}

.input-checkbox,
.input-radio {
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  vertical-align: -4px;
  border: 1px solid;
}

.display-table .radio__label,
.display-table .checkbox__label {
  display: table-cell;
  width: 100%;
}

.radio__label,
.checkbox__label {
  cursor: pointer;
  vertical-align: middle;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
}

.display-table .radio__label__primary {
  display: table-cell;
  width: 100%;
}

.radio__label__primary {
  cursor: inherit;
  font-family: inherit;
  vertical-align: top;
}

.display-table .radio__label__accessory {
  display: table-cell;
}

.radio__label__accessory {
  text-align: right;
  padding-left: 0.75em;
  white-space: nowrap;
}

.radio__label,
.checkbox__label {
  cursor: pointer;
  vertical-align: middle;
}

@media (max-width: 999px) {
  .wrap {
    width: 94%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 1em;
  }

  .continuetopayment-tab {
    display: block;
  }

  .continuetopayment-desktop {
    display: none;
  }

  .content-box-tab {
    margin-top: 15px;
  }
}

@media (min-width: 999px) {
  .continuetopayment-tab {
    display: none;
  }

  .continuetopayment-desktop {
    display: block;
  }
}

.section__text {
  font-weight: 600;
  font-size: 15px;
}

.radio-wrapper.content-box__row,
.checkbox-wrapper.content-box__row {
  margin-bottom: 0;
}

.content-box__row~.content-box__row {
  border-top: 1px solid #d9d9d9;
}

.content-box__row {
  padding: 1.1428571429em;
  position: relative;
  zoom: 1;
}

.input-checkbox:checked:hover,
.input-radio:checked:hover {
  border-color: rgb(61, 64, 91);
}

.content-box .input-checkbox:hover,
.content-box .input-radio:hover {
  border-color: rgb(204, 204, 204);
}

.main .input-checkbox:hover,
.main .input-radio:hover {
  border-color: rgb(204, 204, 204);
}

.btn:hover {
  color: #fff;
  text-decoration: none;
}

.margin-2 {
  margin-top: 2em;
}

.Information_page_login {
  margin-left: 5px;
}

input:focus-visible {
  outline-offset: none !important;
  outline: 0px;
}

.return_to_previous_page {
  margin-top: 15px;
  margin-bottom: 37px;
}

.return_to_previous_page svg {
  display: none;
}

@media (max-width: 749px) {

  .card-login-but,
  .sp-modal-toggle {
    width: 100%;
    padding-top: 1.25em;
    padding-bottom: 1.25em;
  }

  .return_to_previous_page {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
  }

  .return_to_previous_page svg {
    display: -webkit-inline-box;
    margin-bottom: 4px;
    margin-right: 5px;
  }

  .main {
    padding-top: 1.5em;
  }

  .returbackbtnphn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .section {
    position: relative;
    padding-top: 1em;
  }
}

.order-summary-toggle {
  background: #fafafa;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 1.25em 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-align: left;
  width: 100%;
}

@media (min-width: 1000px) {
  .order-summary-toggle {
    display: none;
  }
}

/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
}
.butn:hover {
  background-color: #ff3772;
} */
.shiping-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
}

.shiping-flex .butn {
  width: 100%;
  margin: 0px;
}

.checkout-box .boxes {
  padding: 12px;
  margin-top: 20px;
  border: 1px solid #eee;
  box-sizing: border-box;
  border-radius: 5px;
}

.checkout-box .address-header {
  display: flex;
  align-items: baseline;
}

.checkout-box .address-box {
  background: #fff;
}

.checkout-box p {
  margin-bottom: 0.2rem;
}

.coupon {
  border: 1px dashed #a9abb2;
  color: #535665;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 15px;
  margin: 10px 0 0px;
  font-weight: 400;
  height: 50px;
}

.invalid-coupon-code-textbox {
  margin-top: 5px;
  font-size: 14px;
  color: #f94a5b !important;
}

.invalid-coupon-code-textbox-color {
  margin-top: 5px;
  font-size: 14px;
  color: rgb(96, 178, 70);
}

.apply-offer-select-address {
  /* margin-left: 0px; */
  padding: 14px 0;
}

.section--shipping-method {
  padding-top: 2rem;
}

.content-shipping {
  overflow: hidden;
}

.store-close {
  padding: 10px;
  font-weight: 700;
  background: #ffb3cc;
  border-radius: 10px;
  border: 1px solid #535353;
}

/* add by laxmi------------------------------ */

@media (max-width: 650px) and (min-width: 280px) {
  .shippingbtn {
    padding: 10px 0px;
    border-radius: 20px;
    font-size: 14px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 184px !important;
    text-align: center;
    cursor: pointer;
  }
}

@media (max-width: 650px) and (min-width: 400px) {
  .addnewaddressbtn {
    padding: 10px 0px;
    border-radius: 20px;
    font-size: 14px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 140px !important;
    text-align: center;
    cursor: pointer;
  }
}

@media (max-width: 400px) and (min-width: 280px) {
  .checkoutimgitem {
    width: 20% !important;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 13px;
  }

  .addnewaddressbtn {
    padding: 10px 0px;
    border-radius: 20px;
    font-size: 12px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 120px !important;
    text-align: center;
    cursor: pointer;
  }

  /* .shippingaddresstexFont {
    font-size: 14px !important;
  } */

  .checkoutshowitemfontphn {
    font-size: 14px !important;
  }

  .wrap {
    display: block;
    zoom: 1;
    margin: 0 2% !important;
  }

  .wrap {
    width: 96%;
    box-sizing: border-box;
    padding: 0 10px !important;
  }

  .radio__label__primary {
    cursor: inherit;
    font-family: inherit;
    vertical-align: top;
    font-size: 13px !important;
  }
}

/* for desktop view shipping continue button ------------------*/

@media (min-width: 999px) and (max-width: 1490px) {
  .shippingbtn {
    width: 45% !important;
  }
}

/* add by laxmi------------------------------ */

@media (max-width: 430px) and (min-width: 340px) {
  .wrapperSidedrawFixBottmidth {
    padding: 8px 8px !important;
    background: #fff;
    box-shadow: 0 -4px 16px rgb(0 0 0 / 16%);
    border-radius: 5px;
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100% !important;
  }
}

@media (max-width: 340px) and (min-width: 280px) {
  .wrapperSidedrawFixBottmidth {
    padding: 8px 8px !important;
    background: #fff;
    box-shadow: 0 -4px 16px rgb(0 0 0 / 16%);
    border-radius: 5px;
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100% !important;
  }

  .sidedrawFixBottombutn2 {
    padding: 10px 0px;
    border-radius: 20px;
    font-size: 17px !important;
    background-color: #c83363;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }

  .sidedrawtotalFixBottombutn2 {
    font-size: 17px !important;
  }
}

.invalid-coupon-code-textbox {
  margin-top: 5px;
  font-size: 14px;
  color: #f94a5b !important;
}

.invalid-coupon-code-textbox-color {
  margin-top: 5px;
  font-size: 14px;
  color: rgb(96, 178, 70) !important;
}

.rgbox-bottom {
  position: relative;
}

.rgbox-bottom p:before {
  border-bottom: 1px solid #767676;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 10px;
  margin: 0 auto;
}

.rgbox-bottom p label {
  background-color: #fff;
  margin-bottom: 0;
  color: #767676;
  position: relative;
  padding: 0 5px;
}

.coupon-card {
  padding: 25px 40px;
  border: 1px solid #e9e9eb;
  color: #3e4152;
}

.coupon-card h5 {
  background-color: wheat;
  width: 50%;
  padding: 10px 20px;
  text-align: center;
  font-weight: 400;
}

.coupon-terms-condition {
  font-size: 0.92rem;
  color: #686b78;
}

.coupon-terms-condition {
  font-size: 0.92rem;
  color: #686b78;
}

.coupon-more {
  color: #c83363 !important;
  font-size: 1rem;
  cursor: pointer;
}

.removeCoupon {
  float: right;
}

.return_to_previous_page svg {
  display: -webkit-inline-box;
  margin-bottom: 4px;
  margin-right: 5px;
}

@media (max-width: 999px) {
  .shippingphnmargintop {
    margin-top: 3rem !important;
  }

  .section__contentphnview {
    margin-top: 0px !important;
  }

  .sectionphnview {
    padding-top: 0em !important;
  }

  .ordersummerbottomphn {
    margin-top: -20px !important;
  }

  .contentphnview {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .checkout-box .boxes {
    margin-top: 0px;
  }

  .section__headerphnview {
    margin-bottom: 0px !important;
  }

  .sectiontoppadding {
    padding-top: 1em !important;
  }

  .orderphntopbottom {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
}

@media (min-width: 999px) {
  .section__contentphnview {
    margin-top: 2rem !important;
  }
}

@media (max-width: 750px) {
  .returntocartbtnphn {
    text-align: left !important;
  }

  .shippinphnblockview {
    display: block !important;
  }

  .returntocheckputphn {
    margin-top: 20px !important;
    margin-left: 8px !important;
  }
}

@media (max-width: 500px) {
  .coupon-card h5 {
    width: 100% !important;
  }
}