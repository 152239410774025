.banner2 {
  padding: 40px 3%;
}

.banner2-div2 {
  padding: 30px 0;
  box-shadow: 0 0 3px #0003;
  text-align: center;
  border-radius: 5px;
}

.b2-img {
  /* border: 1px solid #eeeeee; */
  border-radius: 100%;
  min-width: 75px;
  min-height: 75px;
  padding: 18px 0;
  text-align: center;
  transition: 0.3s all ease;
  display: inline-block;
}

.b2-img img {
  width: 65px;
  height: 65px;
}

.banner2-col {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.banner2-col h2 {
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 10px;
  font-size: 18px;
  text-align: left;
}

.banner2-col p {
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 15px;
  letter-spacing: 0;
  color: #4c4f4e;
}

.banner2-div2 ul {
  list-style: none;
  display: inline-block;
}

.banner2-div2 li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
}

.li-left {
  text-align: left;
  padding: 0em 1em;
  margin-top: 1em;
  width: 70%;
}

.mg-0 {
  margin: 0px;
}

.pd-0b {
  padding: 0px;
}

@media (max-width: 995px) {
  .banner2 {
    padding: 40px 3% !important;
  }

  .li-left {
    text-align: center !important;
  }

  .banner2-div2 {
    padding: 18px 0px !important;
  }

  .li-left p {
    font-size: 12px !important;
  }
}

.footer-lastCard {
  width: 30%;
}

.footercart {
  width: 325px;
  background-color: #fff5f8;
  border-radius: 10px;
  box-shadow: none;
  margin-left: 5px;
  border: 1px solid #fff5f8;
}

.footerCard {
  display: flex;
}

.footercart img {
  transition: all .5s;
}

.footerCard img {
  width: 50%;
  margin-top: auto;
}

.CustomerPara {
  margin-top: 20px;
  margin-left: 2px;
}

.footer-card-main {
  padding: 40px 3%;
}





/* chnage by laxmi------------------- add (min-width) */
@media (min-width:769px) and (max-width: 1280px) {
  .phnTabDisplayWriteup {
    display: none !important;
  }

  .footerCard {
    display: block;
  }

  .footerCard img {
    width: 50%;
    margin-left: 25%;
    margin-left: 2;
  }

  /* mobile view change change by laxmi*/
  .CustomerPara {
    margin-left: 0px !important;
    text-align: center !important;
  }
}

/* add by laxmi */

@media(min-width:280px) and (max-width:769px) {
  .footer-card-main {
    padding: 0;
  }

  .DeskTabDisplayWriteup {
    display: none !important;
  }


  .writeupDivPhnView {
    background-color: #fff5f8;
    border-radius: 10px !important;
    box-shadow: none;
    margin-left: 0px;
    border: 1px solid #fff5f8;
    margin-bottom: 25px !important;
    text-align: center !important;
  }

  .imgWriteupHeight {
    height: 120px !important;
    margin: auto;
  }

  .CustomerPara {
    margin-top: 20px;
    margin-left: 2px;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

@media (min-width:995px) {
  .phnTabDisplayWriteup {
    display: none;
  }
}