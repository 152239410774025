.IAygG {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  /* grid-template-rows: 25% 25% 25% 25%; */

  margin: 2% 2%;
}

.iSuBqU {
  padding: 0% 0.5%;
  height: 12rem;
  cursor: pointer;
}

.che img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.category-h2 {
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 27px;

  text-align: center !important;
  font-size: 40px;
}

.che .slick-prev,
.che .slick-next {
  width: 40px !important;
  height: 40px !important;
  top: 49% !important;
}

.che .slick-prev {
  z-index: 1 !important;
}

.che .slick-prev:before,
.che .slick-next:before {
  color: #123b4f !important;
  font-size: 38px !important;
  z-index: -1 !important;
}


/* add by laxmi */
.categoryDisplay {
  margin-right: 10px;
}

@media (max-width:480px) {
  .categoryDisplay {
    margin-right: 0px !important;
  }

}

@media (min-width:400px) and (max-width:995px) {
  .category-h2 {
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 22px !important;
    text-align: center !important;
    font-size: 30px !important;
  }
}

@media (min-width:280px) and (max-width:400px) {
  .category-h2 {
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 16px !important;
    text-align: center !important;
    font-size: 22px !important;
  }
}

@media (max-width:600px) {
  .categorynameFont {
    font-size: 15px !important;
  }
}

.categorynameFont {
  text-align: center;
  font-size: 1.2rem;
  padding-top: 0.5em;
}