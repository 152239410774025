.blog-main {
  padding: 40px 3%;
}

.blog-main h2 {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px;
  /* text-align: center !important; */
  font-size: 40px;
}

.blog-main p {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  color: #3d405b;
  max-width: 850px;
  margin: auto;
  line-height: 25px;
  /* text-align: center; */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* change by laxmi */

/* .blog-img img {
  width: 100%;
  border-radius: 10px;
} */

.blog-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* change by laxmi end*/

.blog-flex {
  display: flex;
  justify-content: space-between;
}

.blog-h2p {
  /* padding: 2em 0em 0em 0em; */
}

.blog-h2 {
  font-size: 35px;
  text-transform: capitalize;
  padding: 0 0 15px;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left !important;
}

.rightup-p {
  font-size: 16px;
  color: #928f8f;
  letter-spacing: 0;
  margin-bottom: 15px;
  text-align: left;
  font-weight: 500;
  text-align: justify;
}

.pad-row {
  padding-top: 2em;
  display: flex !important;
}

.but-margin-blogbelowTestimonial {
  /* change by laxmi */
  /* margin-top: 40px; */
  padding-bottom: 40px;
  padding-top: 40px;
}

@media (max-width: 995px) {
  .blog-main h2 {
    margin: 0px !important;
    font-size: 20px !important;
    padding: 0px;
    text-align: left;
  }

  .blog-h2p {
    padding: 0em !important;
  }

  .blog-h2p p {
    font-size: 13px !important;
    margin-bottom: 1px !important;
  }

  /* add by laxmi---------------------------------------------------------- */
  .butnWriteup {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px;
    text-align: center;
    cursor: pointer;
  }
}


/* add by laxmi------------------------------------------------ */
@media (max-width:768px) {
  .writeupPehraPhn {
    margin-top: 15px;
  }

  .but-margin-blogbelowTestimonial {
    padding-bottom: 0px !important;
    padding-top: 20px !important;
  }
}