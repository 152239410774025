* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.slider {
    padding-bottom: 20px;
    position: relative;
}

.slider .owl-item.active.center .slider-card {
    transform: scale(1);
    background-color: #ffff;
    opacity: 1;
}

.slider-card {
    background-color: #f8f9fa;
    box-shadow: 0px 0px 15px 0px rgb(7 6 6 / 10%);
    transform: scale(0.9);
    transition: all 0.3s;
    border-radius: 3%;

    /* add by Laxmi */
    height: 500px !important;
}

.slider-card img {
    border-radius: 5px 2px 5px 2px;
}

.owl-nav owl-next {
    position: absolute;
    top: calc(50% - 25px);
    right: 0;
    font-size: 50px;
    opacity: 1;
    z-index: 1;
}

.owl-nav owl-prev {
    position: absolute;
    top: calc(50% - 25px);
    left: 0;
    font-size: 50px;
    opacity: 1;
    z-index: 1;
}

.testi {
    /* font-size:14px; */
    font-weight: bold !important;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: block !important;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    position: absolute;
    right: 40% !important;
    font-size: 150% !important;
    color: #fff !important;
    opacity: 1;
    z-index: 1;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font-size: 150% !important;
    color: #fff !important;
    position: absolute;
    left: 40% !important;
    font-size: 50px;
    opacity: 1;
    z-index: 1;
}

.testimonial-para p {
    font-size: 16px !important;
    text-transform: capitalize;
    font-weight: 500;
    color: #2b2b2b;
    max-width: 850px;
    margin: auto;
    line-height: 25px;
}

/* add by Laxmi */
@media (min-width:630px) and (max-width:995px) {
    .testimonial-para p {
        font-size: 13px !important;
        line-height: 22px;
    }

    .slider-card {
        background-color: #f8f9fa;
        box-shadow: 0px 0px 15px 0px rgb(7 6 6 / 10%);
        transform: scale(0.9);
        transition: all 0.3s;
        border-radius: 3%;
        height: 440px !important;
    }
}

@media (min-width:600px) and (max-width:630px) {
    .testimonial-para p {
        font-size: 15px !important;
        line-height: 25px;
    }

    .slider-card {
        background-color: #f8f9fa;
        box-shadow: 0px 0px 15px 0px rgb(7 6 6 / 10%);
        transform: scale(0.9);
        transition: all 0.3s;
        border-radius: 3%;
        height: 540px !important;
    }
}

@media (min-width:510px) and (max-width:600.9px) {
    .testimonial-para p {
        font-size: 13px !important;
        line-height: 22px;
    }

    .slider-card {
        background-color: #f8f9fa;
        box-shadow: 0px 0px 15px 0px rgb(7 6 6 / 10%);
        transform: scale(0.9);
        transition: all 0.3s;
        border-radius: 3%;
        height: 490px !important;
    }
}

@media (min-width:400px) and (max-width:510px) {
    .testimonial-para p {
        font-size: 13px !important;
        line-height: 22px;
        text-align: justify;
    }

    .slider-card {
        background-color: #f8f9fa;
        box-shadow: 0px 0px 15px 0px rgb(7 6 6 / 10%);
        /* box-shadow: unset !important; */
        transform: scale(0.9);
        transition: all 0.3s;
        border-radius: 3%;
        height: 450px !important;
    }
}

@media (min-width:280px) and (max-width:400px) {
    .testimonial-para p {
        font-size: 12px !important;
        line-height: 18px;
        text-align: justify;
    }

    .slider-card {
        background-color: #f8f9fa;
        box-shadow: 0px 0px 15px 0px rgb(7 6 6 / 10%);
        /* box-shadow: unset !important; */
        transform: scale(0.9);
        transition: all 0.3s;
        border-radius: 3%;
        height: 400px !important;
    }
}

@media (min-width:450px) {
    .textimonialpaddingallview {
        padding: 40px 3% 3px 3% !important;
    }
}

@media (max-width:450px) {
    .textimonialpaddingallview {
        padding: 40px 0% 0px 0% !important;
    }
}

.testimonialslickdotparent .testimonialslickdotchild .slick-dots {
    position: absolute;
    bottom: 5px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}