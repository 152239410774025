.static-page-container {
  justify-content: space-between;
  padding: 1em 0em;
  width: 86vw;
  margin: auto;
}

@media (max-width: 1070px) and (min-width: 960px) {
  .contact-us-captcha p {
    font-style: italic;
    background-color: wheat;
    /* padding: 8px; */
    padding: 16px 7px !important;
    letter-spacing: 26px;
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 480px) {
  .responsive-map-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }

  .responsive-map-container iframe,
  .responsive-map-container object,
  .responsive-map-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* .contact-us-captcha p {
        font-style: italic;
        background-color: wheat;
        padding: 8px;
        letter-spacing: 43px;
    } */

/* change by laxmi p */
.contact-us-captcha p {
  font-style: italic;
  background-color: wheat;
  /* padding: 8px; */
  padding: 16px 12px;
  letter-spacing: 26px;
  display: flex;
  align-items: center;
}

.blog-main {
  padding: 40px 3%;
}

.blog-main h2 {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px;
  /* text-align: center !important; */
  font-size: 40px;
}

.blog-main p {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  color: #3d405b;
  max-width: 100%;
  margin: auto;
  line-height: 25px;
  /* text-align: center; */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-img img {
  width: 100%;
  border-radius: 10px;
}

.blog-flex {
  display: flex;
  justify-content: space-between;
}

.blog-h2p {
  /* padding: 2em 0em 0em 0em; */
}

.blog-h2 {
  font-size: 35px;
  text-transform: capitalize;
  padding: 0 0 15px;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left !important;
}

.rightup-p {
  font-size: 16px;
  color: #928f8f;
  letter-spacing: 0;
  margin-bottom: 15px;
  text-align: left;
  font-weight: 500;
  text-align: justify;
}

.pad-row {
  padding-top: 2em;
  display: flex !important;
}

@media (min-width: 500px) and (max-width: 995px) {
  .blog-h2p p {
    font-size: 15px !important;
    margin-bottom: 1px !important;
    line-height: 30px;
    /* top: 13px; */
    margin-top: 10px;
  }
  .blog-h2p {
    padding: 0em !important;

    /* add by laxmi ---------------- */

    margin-top: 15px !important;

    /* end------------------- */
  }
  .blog-main h2 {
    margin: 0px !important;
    font-size: 20px !important;
    padding: 0px;
    text-align: left;
  }
}

@media (max-width: 500.9px) {
  .blog-main h2 {
    margin: 0px !important;
    font-size: 20px !important;
    padding: 0px;
    text-align: left;
  }

  .blog-h2p {
    padding: 0em !important;

    /* add by laxmi ---------------- */

    margin-top: 15px !important;

    /* end------------------- */
  }

  .blog-h2p p {
    font-size: 13px !important;
    margin-bottom: 1px !important;
    margin-top: 10px;
  }
}

/* add by laxmi */

@media (min-width: 768px) {
  .contactform {
    height: 100%;
    padding: 40px 45px 40px;
    /* background: rgb(255, 255, 255); */
    background: "#fff";
  }
}

@media (max-width: 768px) {
  .butnSubmitContactfrm {
    margin-top: 15px !important;
  }

  .contactform {
    height: 100%;
    padding: 20px 2px !important;
    background: rgb(255, 255, 255);
  }

  .such {
    margin-bottom: 20px !important;
    font-size: 14px !important;
  }

  .form-floating > label {
    position: absolute;
    font-size: 14px !important;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }

  .form-controlCaptch {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .form-controlOrderHistory {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 2.5vmin !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .such {
    margin-bottom: 20px !important;
    font-size: 18px !important;
  }

  .form-floating > label {
    position: absolute;
    font-size: 18px !important;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }

  .form-controlCaptch {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 3vmin !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

@media (min-width: 768px) and (max-width: 960px) {
  .contact-us-captcha p {
    font-style: italic;
    background-color: wheat;
    padding: 16px 8px;
    letter-spacing: 12px !important;
    display: flex;
    align-items: center;
  }

  .captchaPadding {
    padding-left: 0px !important;
  }

  .form-controlCaptch {
    display: block;
    width: 100%;
    padding-left: 4px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    /* padding: 0.375rem 0.75rem; */
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.butnSubmitContactfrm {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff !important;
  width: 140px;
  text-align: center;
  cursor: pointer;
}

.butnSubmitContactfrm:hover {
  background-color: #ff3772;
}

.texttransform-about p,
h2 {
  text-transform: none !important;
}

.termsandconditions-heading h2 {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px;
  /* text-align: center !important; */
  font-size: 40px;
}

.static-page-main {
  padding: 40px 3%;
}

.faqs .Collapsible__trigger {
  padding: 10px;
  border: 1px solid #ebebeb;
  width: 100%;
  display: block;
}

.faqs .Collapsible {
  margin-bottom: 10px;
}

.faqs .Collapsible__contentOuter p {
  padding: 10px;
  border: 1px solid #ebebeb;
  width: 100%;
}
