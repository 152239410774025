.blog-main {
  padding: 40px 3%;
}
.blog-main h2 {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px;
  /* text-align: center !important; */
  font-size: 40px;
}
.blog-main p {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  color: #3d405b;
  max-width: 850px;
  margin: auto;
  line-height: 25px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-img img {
  width: 100%;
  border-radius: 10px;
}
.blog-flex {
  display: flex;
  justify-content: space-between;
}
.blog-h2p {
  /* padding: 2em 0em 0em 0em; */
}
.blog-h2 {
  font-size: 35px;
  text-transform: capitalize;
  padding: 0 0 15px;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left !important;
}
.blog-h2p p {
  font-size: 16px;
  color: #928f8f;
  letter-spacing: 0;
  margin-bottom: 15px;
  text-align: left;
  font-weight: 500;
  text-align: justify;
}
.pad-row {
  padding-top: 2em;
  display: flex !important;
}
/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
}
.butn:hover {
  background-color: #ff3772;
} */
.but-margin-blog {
  margin-top: 40px;
}
@media (max-width: 995px) {
  .blog-main h2 {
    margin: 0px !important;
    font-size: 20px !important;
    padding: 0px;
    text-align: left;
  }
  .blog-h2p {
    padding: 0em !important;
  }
  .blog-h2p p {
    font-size: 13px !important;
    margin-bottom: 1px !important;
  }
  /* .butn {
    padding: 11px 0px !important;
    width: 30% !important;
    margin-top: 10px !important;

    text-align: center !important;
  } */
}
