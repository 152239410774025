.card4-row2 {
  margin-top: 25px;
}

.card4-div1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.card4-div2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.card4-div1 img {
  max-width: 100%;
  height: 100%;
  border-radius: 10px;
}

.card4-div2 img {
  height: 100%;
  border-radius: 10px;
  max-width: 100%;
}

.card4-mainrow {
  position: relative;
  width: 100%;
  height: 100%;
}

.card4-mainrow .collff {
  position: absolute;
  top: 82px;
  /* width: 55%; */
  width: 70%;
  left: 3%;
  display: flex;
  flex-direction: column;
}

.collff1 {
  position: absolute;
  top: 82px;
  /* width: 70%; */
  width: 90%;
  left: 8%;
  display: flex;
  flex-direction: column;
}

.collff1 h2 {
  font-size: 25px;
  text-transform: capitalize;
}

/* add by laxmi */
.collff h2 {
  font-size: 25px;
  text-transform: capitalize;
}

.collff1 p {
  text-transform: capitalize;
  font-weight: 600;
  /* color: #917a70;  */
}

.collff p {
  text-transform: capitalize;
  font-weight: 600;
  /* color: #917a70; */
}

.btnprimary-div {
  margin-top: 40px;
}

.btnprimary {
  text-decoration: none;
  color: black;
  padding: 12px 35px;
  border-radius: 10px;
  background-color: #fff;
  padding: 12px 35px;
  font-size: 14px;
  font-weight: 500;
}

.card4-pd {
  padding: 40px 3%;
}

.ban2-none {
  display: block !important;

  /* add by laxmi */
  font-size: 14px !important;
}


@media (min-width:781px) and (max-width: 995px) {
  
   .collff h2 {
    font-size: 19px !important;
    text-transform: capitalize;
  }
  .collff1 h2 {
    font-size: 19px !important;
  }
}


@media (min-width:665px) and (max-width: 781.9px) {  
  .collff h2 {
   font-size: 16px !important;
   text-transform: capitalize;
 }
 .collff1 h2 {
   font-size: 16px !important;
 }
}

@media (min-width:500px) and (max-width: 665.9px) {  
  .collff h2 {
   font-size: 14px !important;
   text-transform: capitalize;
 }
 .collff1 h2 {
   font-size: 14px !important;
 }
}

@media (min-width:595px) and (max-width: 995px) {
  .ban2-none {
    display: none !important;
  }

  /* add by laxmi-----------------*/
  .btnprimary-div {
    margin-top: 11px !important;
  }

  .card4butn {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 13px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 100px;
    text-align: center;
    cursor: pointer;
  }

  .card4-mainrow .collff {
    top: 35% !important;
  }

  .collff1 {
    top: 35% !important;
  }


 
  /* add by laxmi-------------------------------------------- */

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }
  .collff1 {
    position: absolute;
    top: 68px;
    width: 86% !important;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 12px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

}

/* add by laxmi */
@media (min-width:500px) and (max-width: 595px) {
  .collff1 h2 {
    font-size: 13px !important;
  }

  .collff h2 {
    font-size: 13px !important;
    text-transform: capitalize;
  }

  .ban2-none {
    display: none !important;
  }

  .btnprimary-div {
    margin-top: 12px !important;
  }

  .card4butn {
    padding: 7px 0px;
    border-radius: 20px;
    font-size: 12px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 90px;
    text-align: center;
    cursor: pointer;
  }

  .card4-mainrow .collff {
    top: 35% !important;
  }

  .collff1 {
    top: 35% !important;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }

  .collff1 {
    position: absolute;
    top: 68px;
    width: 86% !important;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 6px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
}

@media (min-width:440px) and (max-width: 500px) {
  .ban2-none {
    display: none !important;
  }

  .btnprimary-div {
    margin-top: 12px !important;
  }

  .card4butn {
    padding: 4px 0px;
    border-radius: 20px;
    font-size: 9px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 67px;
    text-align: center;
    cursor: pointer;
  }

  .card4-mainrow .collff {
    top: 35% !important;
  }

  .collff1 {
    top: 35% !important;
  }

  .collff1 h2 {
    font-size: 10px !important;
  }

  .collff h2 {
    font-size: 10px !important;
    text-transform: capitalize;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }

  .collff1 {
    position: absolute;
    top: 68px;
    width: 86% !important;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 6px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
}

@media (min-width:342px) and (max-width: 440px) {
  .ban2-none {
    display: none !important;
  }

  .btnprimary-div {
    margin-top: 4px !important;
  }

  .card4butn {
    padding: 3px 0px;
    border-radius: 20px;
    font-size: 7px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 54px;
    text-align: center;
    cursor: pointer;
  }

  .collff1 {
    top: 35% !important;
  }

  .card4-mainrow .collff {
    top: 35% !important;
  }

  .collff1 h2 {
    font-size: 8px !important;
  }

  .collff h2 {
    font-size: 8px !important;
    text-transform: capitalize;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }

  .collff1 {
    position: absolute;
    top: 68px;
    width: 86% !important;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 6px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
}

@media (min-width:280px) and (max-width: 342px) {
  .ban2-none {
    display: none !important;
  }

  .btnprimary-div {
    margin-top: 6px !important;
  }

  .card4butn {
    padding: 3px 0px;
    border-radius: 20px;
    font-size: 5px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 41px;
    text-align: center;
    cursor: pointer;
  }

  .collff1 {
    top: 36% !important;
  }

  .card4-mainrow .collff {
    top: 36% !important;
  }

  .collff1 h2 {
    font-size: 6px !important;
  }

  .collff h2 {
    font-size: 6px !important;
    text-transform: capitalize;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }

  .collff1 {
    position: absolute;
    top: 68px;
    width: 86% !important;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 6px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
}

@media (min-width:995px) and (max-width: 1065px) {

  .collff h2,
  .collff1 h2 {
    font-size: 19px !important;
  }

  .ban2-none {
    font-size: 14px !important;
  }

  .btnprimary-div {
    margin-top: 12px !important;
  }

  .card4butn {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px;
    text-align: center;
    cursor: pointer;
  }

  .card4-mainrow .collff {
    top: 24% !important;
  }

  .collff1 {
    top: 24% !important;
  }

  .collff h2 {
    text-transform: capitalize;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 55% !important;
    left: 3%;
    display: flex;
    text-align: justify;
    flex-direction: column;
  }

  .collff1 {
    position: absolute;
    top: 68px;
    width: 80% !important;
    text-align: justify;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 6px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
}

@media (min-width:1065px) and (max-width: 1300px) {

  .collff h2,
  .collff1 h2 {
    font-size: 20px !important;
  }

  .ban2-none {
    font-size: 16px !important;
  }

  .btnprimary-div {
    margin-top: 12px !important;
  }

  .card4butn {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px;
    text-align: center;
    cursor: pointer;
  }

  .card4-mainrow .collff {
    top: 24% !important;
  }

  .collff1 {
    top: 24% !important;
  }

  .collff h2 {
    text-transform: capitalize;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 75% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }

  .collff1 {
    position: absolute;
    top: 68px;
    width: 90% !important;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 6px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
}


@media (min-width:380px) and (max-width:559px){
  .deskview{
    display: none;
  }
  .collffphn h2{
    font-size: 4vmin !important;
    text-transform: capitalize;
  }
  .card4-mainrow .collffphn {
    position: absolute;
    top: 20px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
    top: 11px !important;
}
.card4butnphn {
  padding: 7px 0px;
  border-radius: 20px;
  font-size: 14px;
  background-color: #c83363;
  color: #ffffff !important;
  width: 100px;
  text-align: center;
  cursor: pointer;
}
.collffphntopmargin{
  margin-top: 14px !important;
}

.collffphntopmargin h2{
  font-size: 4vmin !important;
  text-transform: capitalize;
}
.card4-mainrow .collffphntopmargin {
  position: absolute;
  top: 0;
  width: 88% !important;
  left: 3%;
  display: flex;
  flex-direction: column;
}
.collffphntopmargintop{
  margin-top: 11px !important;
}
}


@media (min-width:0px) and (max-width:380px){
  .deskview{
    display: none;
  }
  .collffphn h2{
    font-size: 5vmin !important;
    text-transform: capitalize;
  }
  .card4-mainrow .collffphn {
    position: absolute;
    top: 20px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
    top: 11px !important;
}
.card4butnphn {
  padding: 5px 0px !important;
  border-radius: 20px;
  font-size: 11px !important;
  background-color: #c83363;
  color: #ffffff !important;
  width: 80px !important;
  text-align: center;
  cursor: pointer;
}

.collffphntopmargin{
  margin-top: 14px !important;
}

.collffphntopmargin h2{
  font-size: 5vmin !important;
  text-transform: capitalize;
}
.card4-mainrow .collffphntopmargin {
  position: absolute;
  top: 0;
  width: 88% !important;
  left: 3%;
  display: flex;
  flex-direction: column;
}
.collffphntopmargintop{
  margin-top: 11px !important;
}
}

@media (min-width:560px){
  .phnview{
    display: none;
  }
}
