.content-head {
  padding: 40px 3%;
}

.content-head h2 {
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 20px;
  text-align: center !important;
  font-size: 40px;
}

.content-head1 {
  font-size: 16px !important;
  text-transform: capitalize;
  font-weight: 500;
  color: #2b2b2b;
  max-width: 850px;
  margin: auto;
  line-height: 25px;
  text-align: center;
}

.slider1-con1 {
  padding: 0 15px;
  width: 100%;
}

.slider1-card {
  width: 100% !important;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 0px;
}

.slider1-con1 {
  padding-bottom: 2em;
}

.slider1-con1 p {
  font-size: 16px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 12px;
}

.slider1-con1 h6 {
  text-align: center;
}

.slider1-con1 ul {
  text-decoration: none;
  text-align: center;
  padding: 0em;
}

.slider1-con1 li {
  display: inline-block;
  color: #ababab;
}

.content-pd {
  padding-top: 30px;
}

.content-head .slick-prev,
.content-head .slick-next {
  width: 40px !important;
  height: 40px !important;
  top: 49% !important;
}

.content-head .slick-prev {
  z-index: 1 !important;
}

.content-head .slick-prev:before,
.content-head .slick-next:before {
  color: #123b4f !important;
  font-size: 38px !important;
  z-index: -1 !important;
}

.margin-1card {
  padding: 0.5em;
}

@media (max-width: 995px) {

  .content-head .slick-prev,
  .content-head .slick-next {
    display: none !important;
  }
}


/* add by Laxmi */
@media (min-width:600px) and (max-width:995px) {
  .content-head h2 {
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 6px;
    text-align: center !important;
    font-size: 30px !important;
  }

  .content-head1 {
    font-size: 15px !important;
    text-transform: capitalize;
    font-weight: 500;
    color: #2b2b2b;
    max-width: 850px;
    margin: auto;
    line-height: 22px;
    text-align: center;
  }
}

@media (min-width:500px) and (max-width:600px) {

  .content-head h2 {
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 6px;
    text-align: center !important;
    font-size: 30px !important;
  }

  .content-head1 {
    font-size: 15px !important;
    text-transform: capitalize;
    font-weight: 500;
    color: #2b2b2b;
    max-width: 850px;
    margin: auto;
    line-height: 22px;
    text-align: center;
  }
}

@media (min-width:400px) and (max-width:500.9px) {

  .content-head h2 {
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 6px;
    text-align: center !important;
    font-size: 30px !important;
  }

  .content-head1 {
    font-size: 13px !important;
    text-transform: capitalize;
    font-weight: 500;
    color: #2b2b2b;
    max-width: 850px;
    margin: auto;
    line-height: 22px;
    text-align: center;
  }
}

@media (min-width:280px) and (max-width:400px) {
  .content-head h2 {
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 6px;
    text-align: center !important;
    font-size: 22px !important;
  }

  .content-head1 {
    font-size: 13px !important;
    text-transform: capitalize;
    font-weight: 500;
    color: #2b2b2b;
    max-width: 850px;
    margin: auto;
    line-height: 18px;
    text-align: center;
  }
}

@media (min-width:481px) and (max-width:700px) {
  .butn2 {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 12px;
    background-color: #c83363;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
}