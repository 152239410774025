@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap);
body {
  margin: 0px;
  padding: 0px;
  font-family: "Raleway", sans-serif !important;
  overflow-x: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(0, 0, 0) !important;
}

p {
  color: #2b2b2b !important;
  font-size: 1rem !important;
}

.butn {
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff !important;
  width: 145px;
  text-align: center;
  cursor: pointer;
}

.butn:hover {
  background-color: #ff3772;
}

/* Add by laxmi */
.pagenotfoundbtn {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff !important;
  width: 140px;
  text-align: center;
  cursor: pointer;
}

.pagenotfoundbtn:hover {
  background-color: #ff3772;
}

.payment-icons svg {
  height: 70px;
  width: 70px;
}

.payment-icons li {
  list-style: none;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
}

.contact-list {
  justify-content: center;
}

/* add by laxmi------------------- Account css */

@media (max-width: 310px) {
  .accountbutn {
    padding: 9px 0px !important;
    border-radius: 20px;
    font-size: 14px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 128px;
    text-align: center;
    cursor: pointer;
  }
}

.butnsearchicondrps {
  padding: 10px 13px !important;
  border-radius: 20px;
  font-size: 13px !important;
  background-color: #c83363;
  color: #ffffff !important;
  width: 140px;
  text-align: center;
  cursor: pointer;
}

.butnsearchicondrpssoldout {
  padding: 10px 13px !important;
  border-radius: 20px;
  font-size: 13px !important;
  color: #ffffff !important;
  width: 90px !important;
  text-align: center;
  cursor: pointer;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 4px !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 4px !important;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/");
}

/* banner arrow */

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  background: none !important;
}

/* .carousel-control-next-icon::after {
  background-image: none !important;
  content:'>';
  color: #c83363 !important;
  font-size: 50px;
  font-weight: 800;
}

.carousel-control-prev-icon::after {
  background-image: none !important;
  content:'<';
  color: #c83363 !important;
  font-size: 50px;
  font-weight: 800;
} */
.header-flex {
  display: flex;
  justify-content: space-between;
  background: #c83363;
  padding: 10px 30px;
}

/* .top-header {
  background: #3d405b;
  padding: 8px 0;
} */
.parent-1 p {
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  /* margin-top: revert; */
}

.parent-2 {
  display: flex;
  justify-content: space-between;
  width: 20%;
  align-items: center;
}

.parent-2 p {
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  /* margin-top: revert; */
}

.header-2 {
  display: flex;
  justify-content: space-between;
  padding: 1em 0em;
  width: 86vw;
  margin: auto;
}

.header-dropdown {
  position: absolute;
  width: 190px;
  left: auto;
  right: auto;
  padding: 15px 15px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  z-index: 9999;
  display: none;
}

.dropdown1:hover .header-dropdown {
  display: block;
}

.dropdown2:hover .header-dropdown {
  display: block;
}

.header-dropdown a {
  color: #22b7c0 !important;
  line-height: 28px;
  /* padding: 12px 16px; */
  padding: 3px 0px;
  text-decoration: none;
  display: block;
  text-transform: capitalize;
}

.header-dropdown a:hover {
  color: #c83363 !important;
}

.ul-inline {
  list-style: none;
  margin-top: auto;
}

.ul-inline li {
  position: relative;
  list-style: none;
  display: inline-block;
  padding-left: 1.5em;
  font-size: 18px;

  font-weight: 400;
}

/*  */

.li-link {
  display: inline-block;
  color: #22b7c0 !important;
  text-decoration: none;
}

.li-link:hover {
  color: #c83363 !important;
}

.li-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #22b7c0;
  transition: width 0.5s;
}

.li-link:hover::after {
  width: 100%;
  /* transition: width 0.3s; */
}

.header-3 {
  display: flex;
  justify-content: space-between;
}

.header-3-icon {
  width: 24px;
}

/* new */
.icons-header {
  font-size: 25px !important;
  color: #22b7c0 !important;
}

.icons-header a {
  color: #22b7c0 !important;
}

.header-text {
  /* padding-right: 1.5em; */
  font-size: 18px;
  color: #22b7c0 !important;
  font-weight: 400;
}

.header-text a {
  color: #22b7c0 !important;
}

.close-head {
  font-size: 1.5em;
  color: white;
}

.div-searchrecip {
  padding: 0.5em 0em;
}

.search-close-head {
  font-size: 2.5em;
}

/* add by laxmi */
.crossChngepassword {
  font-size: 2em !important;
  color: #c83363;
}

.offcanvas-top {
  top: 7% !important;
  height: 11vh !important;
}

.input-search {
  height: 40px !important;
  border-radius: 30px 0px 0px 30px !important;
  border: 1px solid #999 !important;
  /* box-shadow: 0 0 5px #0003 !important; */
}

.search-but-add {
  height: 40px !important;
  border-radius: 0px 30px 30px 0px !important;
  border: none !important;
  /* box-shadow: 0 0 3px #c83363 !important; */
  width: 9% !important;
  background-color: #c83363 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-but-icon {
  font-size: 30px;
  color: white;
}

.mb-32 {
  width: 70% !important;
  margin: 0px auto 0px auto !important;
  align-items: center !important;
  justify-content: space-around;
}

.contaner-head {
  width: 100%;
  display: flex;
  align-items: center;
  width: 96%;
  margin: auto;
}

@media (max-width: 912px) {
  /* .parent-2 {
    width: 35%;
    font-size: 0.8em;
  } */
}

.hide-mobile {
  /* display: block !important; */
}

.pd-r-but {
  display: none !important;
}

.flex-left-header {
  flex-direction: column !important;
  align-items: end !important;
  -webkit-justify-content: flex-end;
  align-items: flex-end !important;
}

.marin-ri-0 {
  margin-right: 0px !important;
}

/* change by laxmi */

@media (min-width: 800px) and (max-width: 995px) {
  .hide-mobile {
    display: none !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 2em;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 35%;
    font-size: 0.8em;
  }

  .logo {
    width: 65px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 17px !important;
    top: 29px !important;
  }
}

.link-flex-header {
  display: flex;
  align-items: center;
}

.pa-lr-text {
  padding: 0px 0.5em;
}

.account-dropdown {
  position: absolute;
  width: auto;
  left: auto;
  right: 20px;
  /* padding: 15px 15px; */
  padding: 11px 12px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  z-index: 5;
  display: none;
  font-size: 1.1rem;
}

.account-dropdown a {
  color: black;
  line-height: 28px;
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
  text-transform: capitalize;
}

.predictive-search {
  position: absolute;
  /* top: calc(100% + 0.1rem); */
  width: 68%;
  left: 15%;
  z-index: 3;
  background: rgb(255, 255, 255);
  border-radius: 4px;
}

#predictive-search-results {
  background: rgb(255, 255, 255);
  border: 1px solid #eeeeee;
  padding: 15px;
  border-radius: 4px;
  overflow-y: scroll;
  max-height: 500px;
}

.predictive-search__heading {
  margin: 0 auto 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(14 * 1.29px);
}

.predictive-search-results .list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.predictive-search__item--link {
  display: grid !important;
  grid-template-columns: 6rem 1fr;
  grid-column-gap: 0rem;
  grid-template-areas: "product-image product-content";
  align-items: center;
  margin-bottom: 15px;
}

.predictive-search__item {
  display: flex;
  text-align: left;
  width: 100%;
}

a {
  color: rgb(0, 0, 0);
  transition: 0.3s ease;
}

.predictive-search__image {
  grid-area: "product-image";
  object-fit: contain;
  font-family: "object-fit: contain";
  border: 1px solid #eeeeee;
  border-radius: 4px;
}

.predictive-search__item-content {
  display: flex;
  line-height: normal;
  justify-content: space-between;
}

.predictive-search__item-heading {
  font-size: calc(14 * 1.15px);
  text-transform: capitalize;
  margin-bottom: 10px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.price--on-sale .price__sale {
  display: block;
  letter-spacing: 0;
}

.predictive-search .price .price-item {
  margin: 0 3px 0 0;
  font-size: calc(14 * 1.15px);
  font-weight: 700;
  letter-spacing: 0;
  display: inline-block;
}

.predictive-search .price--on-sale .price-item--regular {
  text-decoration: line-through;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.6);
  font-size: calc(14 * 1.15px);
  display: inline-block;
}

.predictive-search__item--term {
  justify-content: space-between;
  align-items: center;
  padding: 0;
  word-break: break-all;
  margin: 0;
  font-size: calc(14 * 1.15px);
}

.predictive-search__item--term .icon-arrow {
  flex-shrink: 0;
}

.icon-arrow {
  width: 1.5rem;
}

.predictive-search .spinner {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0;
}

.spinner {
  animation: rotator 1.4s linear infinite;
}

predictive-search:not([loading]) .predictive-search__heading .spinner,
predictive-search:not([loading]) .predictive-search__loading-state,
predictive-search:not([loading]) .predictive-search-status__loading {
  display: none;
}

.wbhcartitem,
.wbwishcount {
  background: #c83363;
  color: #ffffff;
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 14px;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  right: 26px;
  top: 25px;
}

/* Add by laxmi */

.modal-open {
  padding-right: 0 !important;
}

body.modal-open {
  padding-right: 0 !important;
}

.modal {
  padding-right: 0px !important;
}

@media (min-width: 1300px) and (max-width: 995px) {
  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 21px;
    height: 20px;
    text-align: center;
    font-size: 11px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 27px;
    top: 29px;
  }
}

@media (min-width: 995px) and (max-width: 1414px) {
  .parent-2 {
    display: flex;
    justify-content: space-between;
    width: 30%;
    align-items: center;
  }
}

@media (min-width: 800px) and (max-width: 995px) {
  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .headerIconSize {
    font-size: 30px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 30px;
  }

  .logo {
    width: 55px !important;
  }
}

@media (min-width: 710px) and (max-width: 800px) {
  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 18px !important;
    height: 18px !important;
    text-align: center;
    font-size: 12px !important;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }

  .close-head {
    font-size: 25px;
    color: white;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 12px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 30%;
    font-size: 0.8em;
  }

  .headerIconSize {
    font-size: 25px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 25px;
  }

  .logo {
    width: 50px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 17px !important;
    top: 29px !important;
  }

  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
}

@media (min-width: 620px) and (max-width: 710px) {
  .paddingPhnView {
    padding: 0% !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 16px !important;
    height: 16px !important;
    text-align: center;
    font-size: 9px !important;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }

  .close-head {
    font-size: 20px !important;
    color: white;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 12px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 34%;
    font-size: 0.8em;
  }

  .headerIconSize {
    font-size: 20px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 20px;
  }

  .logo {
    width: 45px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 17px !important;
    top: 15px !important;
  }

  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
}

@media (min-width: 550px) and (max-width: 800px) {
  .mb-32 {
    width: 88% !important;
    margin: 0px auto 0px auto !important;
    align-items: center !important;
    justify-content: space-around;
  }
}

@media (min-width: 400px) and (max-width: 550.9px) {
  .search-but-add {
    height: 40px !important;
    border-radius: 0px 30px 30px 0px !important;
    border: none !important;
    width: 13% !important;
    background-color: #c83363 !important;
    justify-content: center;
  }
}

@media (min-width: 200px) and (max-width: 400.9px) {
  .search-but-add {
    height: 40px !important;
    border-radius: 0px 30px 30px 0px !important;
    border: none !important;
    width: 16% !important;
    background-color: #c83363 !important;
    justify-content: center;
  }
}

@media (min-width: 550px) and (max-width: 620px) {
  .paddingPhnView {
    padding: 0% !important;
  }

  .search-but-add {
    height: 40px !important;
    border-radius: 0px 30px 30px 0px !important;
    border: none !important;
    width: 13% !important;
    background-color: #c83363 !important;
    justify-content: center;
  }

  .headerIconSize {
    font-size: 20px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 20px;
  }

  .logo {
    width: 45px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 12px !important;
    top: 13px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 16px !important;
    height: 16px !important;
    text-align: center;
    font-size: 11px !important;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }

  .close-head {
    font-size: 20px;
    color: white;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    background: #c83363;
    padding: 5px 19px;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 12px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 40%;
    font-size: 0.8em;
  }

  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
}

@media (min-width: 400px) and (max-width: 550px) {
  .mb-32 {
    width: 88% !important;
    margin: 0px auto 0px auto !important;
    align-items: center !important;
    justify-content: space-around;
  }

  .paddingPhnView {
    padding: 0% !important;
  }

  .headerIconSize {
    font-size: 20px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 20px;
  }

  .logo {
    width: 45px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 16px !important;
    height: 16px !important;
    text-align: center;
    font-size: 11px !important;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }

  .close-head {
    font-size: 20px;
    color: white;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    background: #c83363;
    padding: 4px 20px;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 10px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 44%;
    font-size: 0.8em;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 10px !important;
    top: 12px !important;
  }

  .freeShippingTop {
    padding-top: 0.25rem !important;
  }

  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
}

@media (min-width: 370px) and (max-width: 400px) {
  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .mb-32 {
    width: 88% !important;
    margin: 0px auto 0px auto !important;
    align-items: center !important;
    justify-content: space-around;
  }

  .paddingPhnView {
    padding: 0% !important;
  }

  .headerIconSize {
    font-size: 20px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 20px;
  }

  .logo {
    width: 45px !important;
  }

  .close-head {
    font-size: 20px;
    color: white;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    background: #c83363;
    padding: 4px 17px;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 10px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 48%;
    font-size: 0.8em;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 11px !important;
    top: 12px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 9px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }

  .freeShippingTop {
    padding-top: 0.25rem !important;
  }
}

@media (min-width: 340px) and (max-width: 370px) {
  .mb-32 {
    width: 88% !important;
    margin: 0px auto 0px auto !important;
    align-items: center !important;
    justify-content: space-around;
  }

  .paddingPhnView {
    padding: 0% !important;
  }

  .headerIconSize {
    font-size: 18px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 18px;
  }

  .logo {
    width: 40px !important;
  }

  .freeShippingTop {
    padding-top: 0.25rem !important;
  }

  .close-head {
    font-size: 18px;
    color: white;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    background: #c83363;
    padding: 4px 14px;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 9px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 48%;
    font-size: 0.8em;
  }

  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 10px !important;
    top: 12px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 16px;
    height: 16px;
    text-align: center;
    font-size: 10px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }
}

@media (min-width: 300px) and (max-width: 340px) {
  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .mb-32 {
    width: 88% !important;
    margin: 0px auto 0px auto !important;
    align-items: center !important;
    justify-content: space-around;
  }

  .paddingPhnView {
    padding: 0% !important;
  }

  .headerIconSize {
    font-size: 18px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 18px;
  }

  .logo {
    width: 40px !important;
  }

  .freeShippingTop {
    padding-top: 0.25rem !important;
  }

  .close-head {
    font-size: 18px;
    color: white;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    background: #c83363;
    padding: 4px 14px;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 8px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 49%;
    font-size: 0.8em;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 10px !important;
    top: 12px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 16px;
    height: 16px;
    text-align: center;
    font-size: 10px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }
}

@media (min-width: 280px) and (max-width: 300px) {
  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .mb-32 {
    width: 88% !important;
    margin: 0px auto 0px auto !important;
    align-items: center !important;
    justify-content: space-around;
  }

  .paddingPhnView {
    padding: 0% !important;
  }

  .headerIconSize {
    font-size: 17px !important;
  }

  .freeShippingTop {
    padding-top: 0.25rem !important;
  }

  .close-head {
    font-size: 17px;
    color: white;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    background: #c83363;
    padding: 1px 12px;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 6px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 17px;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 42%;
    font-size: 0.8em;
  }

  .logo {
    width: 40px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 10px !important;
    top: 12px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 16px;
    height: 16px;
    text-align: center;
    font-size: 10px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }
}

.header-flex marquee {
  color: #ffffff;
}

@media (max-width: 625px) {
  .butnsearchphnhome {
    display: none !important;
  }

  .pricephnserchome {
    display: none !important;
  }
}

@media (min-width: 760px) and (max-width: 800px) {
  .predictive-search {
    position: absolute;
    width: 75.3% !important;
    left: 8% !important;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }
}

@media (min-width: 621px) and (max-width: 760.9px) {
  .predictive-search {
    position: absolute;
    width: 75%;
    left: 8%;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }

  #predictive-search-results {
    background: rgb(255, 255, 255);
    border: 1px solid #eeeeee;
    padding: 8px;
    border-radius: 4px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 500px;
  }
}

@media (min-width: 760px) {
  .predictive-search {
    position: absolute;
    /* top: calc(100% + 0.1rem); */
    width: 59.8%;
    left: 17%;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }

  #predictive-search-results {
    background: rgb(255, 255, 255);
    border: 1px solid #eeeeee;
    padding: 11px;
    border-radius: 4px;
    overflow-y: scroll;
    max-height: 500px;
  }
}

@media (max-width: 621px) {
  #predictive-search-results {
    background: rgb(255, 255, 255);
    border: 1px solid #eeeeee;
    padding: 8px;
    border-radius: 4px;
    overflow-y: scroll;
    max-height: 500px;
  }
}

@media (min-width: 500px) and (max-width: 621.9px) {
  .predictive-search {
    position: absolute;
    width: 70%;
    left: 9%;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }
}

@media (min-width: 400px) and (max-width: 500.9px) {
  .predictive-search {
    position: absolute;
    width: 68% !important;
    left: 9%;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }
}

@media (min-width: 200px) and (max-width: 400.9px) {
  .predictive-search {
    position: absolute;
    width: 64% !important;
    left: 9%;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }
}

@media (max-width: 500px) {
  .searchdropitemlastarrow {
    font-size: 15px !important;
  }

  .predictive-search__item--link {
    display: grid !important;
    grid-template-columns: 4rem 1fr !important;
    grid-column-gap: 0rem;
    grid-template-areas: "product-image product-content";
    align-items: center;
    margin-bottom: 15px;
  }

  .predictive-search {
    position: absolute;
    /* top: calc(100% + 0.1rem); */
    width: 71.5%;
    left: 10%;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }

  .predictive-search__item-heading {
    font-size: calc(11 * 1.15px) !important;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .imgphnviewsearchheader {
    width: 50px !important;
    height: 100% !important;
  }

  .predictive-search__heading {
    margin: 0px auto 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc(12 * 1.29px);
  }
}

.offcanvas-end {
  width: 430px !important;
  height: 100% !important;
  background: #fbfbfb !important;
  box-shadow: -5px 0 74px rgb(0 0 0 / 80%) !;
}

.offcanvas-body {
  padding: 0px !important;
}

.free-delivery-text {
  background: #417505;
  padding: 8px 0;
  margin-bottom: 10px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  margin: 0 auto;
}

.li-cart-container {
  overflow: hidden;
  bottom: 0;
  margin: 10px 0px 10px;
  padding: 0px 5px 15px 5px;
}

.li-cart-message {
  text-align: center;
  padding: 5px;
  border: 1px solid rgb(200, 51, 99) !important;
  border-radius: 10px;
  /* border-left: 1px solid #cbcbcb !important;
  border-right: 1px solid #cbcbcb !important; */

  background: #f9fffa;
  display: none;
  /* margin-bottom: 5px; */
  display: block;
}

.item-available {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background: #fff;
  /* border-top: 1px solid #cbcbcb !important; */
  border-radius: 10px;
  box-shadow: 0 5px 15px -5px rgb(0 0 0 / 10%);
}

.item-available ul {
  list-style: none;
  padding: 0em;
  margin: 0px;
}

.item-available li {
  border-bottom: 1px solid #cbcbcb !important;
  margin-top: 10px;
  list-style: none;
}

.item-available-list {
  display: flex;
  justify-content: space-between;
}

.item-available-text span {
  background: #f2f2f2;
  margin-right: 10px;
  color: #6d6e71;
  padding: 4px 7px;
}

.item-available-text {
  color: rgb(47, 47, 47);
  font-weight: 700;
  font-size: 15px;
  vertical-align: middle;
}

.item-available-list2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  padding: 15px 0;
}

.item-qty {
  border: 1px solid #6d6e71;
  border-radius: 5px;
  /* margin-left: 32px; */
  padding: 3px 5px;
}

.text-red-rupee {
  margin-left: 5px;
  font-weight: 700;
  color: #c83363;
}

.discount-rupee {
  color: #6d6e71;
  text-decoration: line-through;
  margin-left: 5px;
  font-weight: 700;
}

.qty-minus {
  background-color: #f2f2f2;
  border-radius: 2px;
  padding: 0.5em;
  cursor: pointer;
  font-size: 13px;
  color: #c83363;
}

.qty-add {
  padding: 15px;
  font-size: 13px;
}

.qty-plus {
  border-radius: 2px;
  padding: 0.5em;
  font-size: 13px;
  color: #c83363;
  cursor: pointer;
  background-color: #f2f2f2;
}

.banner {
  padding: 1em 0em;
}

.banner-title {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #313131;
  margin: 12px 15px;
}

.banner-nm {
  cursor: pointer;
  border-radius: 5px;
  background: #ffdc93;
  margin: 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 2% 2% 2.3%;
  border: 1px solid #9b9b9b;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 16%);
}

.banner-nm-join {
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  color: #c83363;
  cursor: pointer;
}

.banner-nm-img img {
  height: 35px;
  width: 60%;
  object-fit: contain;
}

.bill {
  width: 95%;
  margin: 50px auto 140px;
  background-color: #fff;
  border: 1px dashed #979797;
  padding: 10px;
}

.bill-heading {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  width: 100%;
  margin-bottom: 7px;
}

.bill-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0px;
}

.bill-details-total {
  color: #6d6e71;
  font-size: 14px;
  font-weight: 700;
}

.bill-details-amount {
  color: #6d6e71;
  font-size: 14px;
  font-weight: 400;
}

.bill-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  margin: 5px 0 0;
  padding-top: 5px;
  border-top: solid 1px #979797;
}

.bill-details-totalam {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.bill-details-amru {
  color: #c83363;
  font-size: 14px;
  font-weight: 700;
}

.wrapper {
  padding: 10px 15px;
  background: #fff;
  box-shadow: 0 -4px 16px rgb(0 0 0 / 16%);
  border-radius: 5px;
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 428px;
  max-width: 100%;
  /* width: 95%;
  margin: 10px; */
}

.wrapper1-flex {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 20px 0px;
}

.wrapper1-flex-div1 {
  font-size: 24px;
  letter-spacing: 0;
  color: #000;
  font-weight: 600;
}

.wrapper1-flex-div2 {
  font-size: 16px;
  color: #c83363;
  font-weight: 700px;
}

.wrapper2-flex {
  display: flex;
  /* justify-content: space-between; */
}

.but-com {
  width: 50%;
}

.but-2 {
  border-radius: 5px;
  text-align: center;
  border: none;
  font-weight: 600;
  transition: all 50ms ease-out;
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
  padding: 12px 30px;
  height: 56px;
  font-size: 15px !important;
  /* background: #cbcbcb; */
  background-color: #c83363 !important;
  width: 100%;
}

.but-1 {
  border-radius: 5px;
  text-align: center;
  border: none;
  font-weight: 600;
  transition: all 50ms ease-out;
  /* box-shadow: 0 0 15px rgb(0 0 0 / 20%); */
  padding: 12px 30px;
  height: 56px;
  font-size: 15px !important;
  background: #cbcbcb;
  width: 100%;
}

.li-empty-cart-container {
  background: #fff;
  overflow: hidden;
  bottom: 0;
  margin: 15px 10px 10px;
}

.li-empty-cart-container .empty-cart {
  text-align: center;
}

.cart-drawer-image {
  width: 25% !important;
}

.cart-drawer-image img {
  width: 71%;
}

.cart-drawer-other-details {
  width: 75% !important;
}

/* .item-available li:last-child{
  border-bottom: none !important;
} */
.item-available li:nth-last-child(0n + 1) {
  border-bottom: none !important;
}

.empty-cart-msg {
  font-size: 1.5rem !important;
}

.clearCart {
  color: #c83363 !important;
  cursor: pointer;
}

.confirmtoDelete {
  padding: 5px 10px;
  background: #c83363;
  border-radius: 5px;
  color: #ffffff !important;
  font-size: 14px;
  text-decoration: none !important;
  cursor: pointer;
  text-transform: uppercase;
}

.cart-sum h1 {
  font-size: 18px;
}

@media (max-width: 330px) {
  .bill-details-amount {
    color: #6d6e71;
    font-size: 13px;
    font-weight: 400;
  }

  .bill {
    width: 90%;
    margin: 70px auto 140px;
    background-color: #fff;
    border: 1px dashed #979797;
    padding: 10px;
  }

  .bill-details-total {
    color: #6d6e71;
    font-size: 13px;
    font-weight: 700;
  }

  .bill-heading {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    width: 100%;
    margin-bottom: 7px;
  }

  .bill-details-amru {
    color: #c83363;
    font-size: 13px;
    font-weight: 700;
  }
}
.leftd-ul {
  list-style: none;
}

.leftd-ul li {
  border-top: 1px solid #dddbdb;
  font-size: 18px;
  font-weight: 500;
  color: black;
  padding: 15px;
}
.leftd-ul li:last-child {
  border-bottom: 1px solid #dddbdb;
}

.hiplus {
  float: right;
}

.hiplus-icon {
  font-size: 2em;
}

.mobile-menu-categories {
  margin-top: 10px;
  margin-left: 0px;
}

.mobile-menu-categories li {
  border: none !important;
}

.fixed-footer-drawer {
  padding: 0px 15px;
}

.footer-col1-ul li:hover {
  animation: shake 150ms 2 linear;
  -moz-animation: shake 150ms 2 linear;
  -webkit-animation: shake 150ms 2 linear;
  -o-animation: shake 150ms 2 linear;
}

@keyframes shake {
  0% {
    transform: translate(3px, 0);
  }

  50% {
    transform: translate(-3px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* add by laxmi */
.logosidedraw {
  height: 20px;
}

.socialiconDraw {
  border-top: 1px solid #dddbdb;
}

.offcanvas-body {
  overflow-y: auto;
  overflow-x: hidden !important;
}

.socialiconDrawmargin {
  margin-top: 30px;
}

.offcanvas-header {
  justify-content: flex-end;
}

.got-question {
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-wrapper {
  padding: 15px 15px;
  font-weight: 500;
  border-bottom: 1px solid #dddbdb;
}
.drawer-contact {
  margin-bottom: 0;
}
.drawer-contact a {
  color: #c83363 !important;
  font-size: 26px !important;
  font-weight: bold;
}

@media (min-width: 360px) and (max-width: 710px) {
  .listIconDraw {
    display: block !important;
    text-align: left;
  }
  .drawsocialicon {
    height: 25px !important;
    width: 25px !important;
    color: #fff;
  }

  .footer-col1-ul .facebook {
    background-color: #4267b2;
    border-radius: 50%;
  }

  .footer-col1-ul .instagram {
    background: radial-gradient(
        circle farthest-corner at 32% 106%,
        rgb(255, 225, 125) 0%,
        rgb(255, 205, 105) 10%,
        rgb(250, 145, 55) 28%,
        rgb(235, 65, 65) 42%,
        transparent 82%
      ),
      linear-gradient(135deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
    border-radius: 50%;
  }

  .fixediconsize {
    width: 100% !important;
  }

  .imgsizedraw {
    height: 90px;
    width: 130px;
  }
}

@media (max-width: 360px) {
  .listIconDraw {
    display: block !important;
    text-align: center;
  }

  .fixediconsize {
    width: 100% !important;
  }

  .imgsizedraw {
    height: 78px;
    width: 120px;
  }
  .drawsocialicon {
    height: 25px !important;
    width: 25px !important;
  }
}

.drawfontanchor {
  width: 100% !important;
}
.drawfontanchor:hover {
  text-decoration: none !important;
}
.drawfontanchor::after {
  text-decoration: none !important;
  background: transparent !important;
}

.left-drawer-logoposition {
  text-align: center;
}

@media (min-width: 710px) and (max-width: 994.9px) {
  .imgsizedraw {
    height: 90px;
    width: 130px;
  }
  .listIconDraw {
    display: block !important;
    text-align: center;
  }
  .drawsocialicon {
    height: 25px !important;
    width: 25px !important;
  }
}

@media (max-width: 994.9px) {
  .Collapsible__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Collapsible__trigger.is-open svg {
    transform: rotate(-90deg);
    font-size: 26px;
  }
  .Collapsible__trigger svg {
    font-size: 26px;
    transition: all ease-in-out 0.3s;
  }
}

.footer {
  margin-top: 40px;
  color: #ffffff;
  background: #c83363;
  padding: 5px 3% 5px 3%;
}

.footer-content {
  padding: 20px 0 20px;
}

.footer-col1p {
  line-height: 28px;
  font-size: 14px;
  text-align: justify;
  color: #ffffff !important;
  width: 85%;
}

.foot-col2-h3 {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 15px;
  color: #ffffff !important;
}

.foot-col2-UL {
  list-style: none;
  /* text-align: justify; */

  /* change by laxmi */
  text-align: unset;
}

.foot-col2-UL li {
  list-style: none;
  line-height: 30px;
}

.footer-col1-ul {
  list-style: none;
  padding: 0em;
}

.footer-col1-ul li {
  display: inline-block;
  min-width: 40px;
  min-height: 40px;
  border-radius: 10px;
  box-shadow: 0 0 5px #0003;
  display: inline-block;
  text-align: center;
  padding-top: 8px;
  margin: 0 6px;
  background: white;
  color: black;
}

.footer-con2 {
  /* padding: 60px 60px; */
  text-align: center;
}

.footer-con2 p {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  line-height: 45px;
  color: #ffffff !important;
  font-size: 14px;
}

/* .footer-con2 p:after {
  content: "|";
  margin: 0 22px;
  color: #ffffff;
} */

.footer-end {
  background-color: #f5f5f5;
  padding: 15px 3%;
  font-size: 14px;
  text-align: center;
}

.footer-end-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
}

.footer-end-left img {
  width: 30px;
  /* height: 22px; */
}

.co-bac-foot {
  background-color: #f5f5f5;
}

.foot-col2-UL a {
  color: #ffffff !important;
}

.babystreetWaveWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 140px;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: auto;
  max-width: 103%;
}

.bgTop {
  z-index: 15;
  opacity: 0.5;
}

.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: -1px;
}

.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  animation-delay: 1s;
}

.waveTop {
  background-size: 50% 100px;
  background-image: url("https://babystreet.althemist.com/wp-content/themes/babystreet/image/wave-top.png");
}

.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat-x;
  background-position: 0 100%;
  transform-origin: center bottom;
}

.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}

.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}

.waveMiddle {
  background-size: 50% 120px;
  background-image: url("https://babystreet.althemist.com/wp-content/themes/babystreet/image/wave-mid.png");
}

.bgBottom {
  z-index: 5;
}

.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}

.waveBottom {
  background-size: 50% 100px;
  background-image: url("https://babystreet.althemist.com/wp-content/themes/babystreet/image/wave-bot.png");
}

.footer-logo-wrap {
  background-color: #ffffff;
  width: 100px;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 20px;
}

.we-accept.we-accept {
  color: #ffffff;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 10px;
}

.paymentImgPhnview.paymentImgPhnview {
  width: 45px;
  height: auto !important;
  border-radius: 3px !important;
  margin: auto;
}

.footer .slick-slider {
  width: 50%;
  margin: auto;
}

/* add by laxmi --------------------------------------------------------------*/
@media (max-width: 998px) {
  .addressphnview {
    display: flex !important;
  }

  .locationicon {
    margin-top: 8px !important;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .slick-arrow {
    display: none !important;
  }

  .slick-slider {
    width: 90%;
    margin: auto;
  }

  .copywriteGlobalname {
    justify-content: center !important;
    margin-bottom: 8px;
  }

  .footer-con2 p {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    line-height: 45px;
    color: #ffffff !important;
    font-size: 14px;
    font-weight: 600 !important;
  }

  .copywriteyear {
    justify-content: center !important;
  }

  .foot-col2-UL {
    list-style: none;
    text-align: unset;
    margin-top: 28px !important;
  }

  .foot-col2-h3 {
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 7px;
    color: #ffffff !important;
  }

  .footerPaddingPhnview {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  .paymentImgPhnview {
    width: 45px;
    height: auto !important;
    border-radius: 3px !important;
  }

  .payment-icons svg {
    width: 45px !important;
    height: auto !important;
  }

  .payment-icons {
    width: 70%;
    margin: auto;
  }
}

@media (min-width: 768px) {
  .copywriteGlobalname {
    float: left;
  }

  .copywriteyear {
    float: right;
  }
}

@media (min-width: 450px) and (max-width: 768px) {
  .footer-end {
    background-color: #f5f5f5;
    padding: 6px 3% !important;
    font-size: 15px !important;
    text-align: center;
    font-weight: 500 !important;
  }
}

@media (min-width: 280px) and (max-width: 450px) {
  .footer-end {
    background-color: #f5f5f5;
    padding: 8px 3% !important;
    font-size: 11px !important;
    text-align: center;
    font-weight: 500 !important;
  }

  .foot-col2-UL li {
    list-style: none;
    line-height: 28px;
    font-size: 14px !important;
  }

  .footer-col1p {
    line-height: 28px;
    font-size: 14px !important;
    text-align: justify;
    color: #ffffff !important;
    width: 100% !important;
  }
}

.banner1 .carousel-caption {
  position: absolute;
  top: 35px;
  left: 3%;
  /* padding-top: 1.25rem; */
  /* padding-bottom: 1.25rem; */
  color: #fff;
  text-align: left !important;
}

.left-text-banner {
  position: relative;
  width: 100%;
  padding: 5% 0px;
}

.left-text-banner h3 {
  color: #3d405b;
  font-size: 16px !important;
  font-weight: bolder !important;
  width: 50% !important;
}

.left-text-banner h2 {
  color: #000000;
  font-size: 48px !important;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 30px !important;
  margin-top: 30px !important;
  /* width: 50% !important; */

  /* change by laxmi */
  width: 60% !important;
}

.left-text-banner p {
  color: #000000;
  text-transform: capitalize;
  line-height: 24px !important;
  opacity: 0.9;
  font-size: 16px !important;
  font-weight: 500;
  width: 48%;
}

.but-banner {
  background: #3d405b;
  color: #ffffff;
  display: inline-flex;
  border-radius: 10px;
  font-size: 15px;
  margin-top: 40px;
  padding: 12px 35px;
  text-align: center;
}

.slick-slider {
  outline: none !important;
}

/* .left-text-banner::before {
  background-image: url(/../pinkparrotkids/src/Component/Images/ban1.webp);
  background-size: cover;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
} */
.text-ab {
  position: absolute;
  top: 20%;
  left: 30px;
  width: 100%;
  /* padding: 50px 0px; */
}

/* Add by laxmi */

.homeBannerbtn {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff !important;
  width: 140px;
  text-align: center;
  cursor: pointer;
}

@media (min-width: 940px) and (max-width: 1150px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 35px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 30px !important;
    margin-top: 10px !important;
    width: 51% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 22px !important;
    opacity: 0.9;
    font-size: 15px !important;
    font-weight: 500;
    width: 48%;
  }

  /* .carousel-indicators {
    display: none !important;
  } */

  .banner1 .carousel-caption {
    position: absolute;
    top: 10px !important;
    left: 3%;
    color: #fff;
    text-align: left !important;
  }
}

@media (min-width: 700px) and (max-width: 940px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 28px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 13px !important;
    margin-top: -28px !important;
    width: 65% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 24px !important;
    opacity: 0.9;
    font-size: 15px !important;
    font-weight: 500;
    width: 60%;
    text-align: justify;
  }

  .homeBannerbtn {
    padding: 9px 0px;
    border-radius: 20px;
    font-size: 15px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 140px;
    text-align: center;
    cursor: pointer;
  }

  /* .carousel-indicators {
    display: none !important;
  } */
}

@media (min-width: 620px) and (max-width: 700px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 24px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 9px !important;
    margin-top: -36px !important;
    width: 80% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 24px !important;
    opacity: 0.9;
    font-size: 15px !important;
    font-weight: 500;
    width: 70%;
    text-align: justify;
  }

  .homeBannerbtn {
    padding: 9px 0px;
    border-radius: 20px;
    font-size: 15px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 140px;
    text-align: center;
    cursor: pointer;
  }

  /* .carousel-indicators {
    display: none !important;
  } */
}


@media (min-width: 500px) and (max-width: 620px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 24px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 9px !important;
    margin-top: -20px !important;
    width: 100% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 22px !important;
    opacity: 0.9;
    font-size: 15px !important;
    font-weight: 500;
    width: 75%;
    text-align: justify;
  }

  .homeBannerbtn {
    padding: 7px 0px;
    border-radius: 20px;
    font-size: 16px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 120px;
    text-align: center;
    cursor: pointer;
  }

  /* .carousel-indicators {
    display: none !important;
  } */
}

@media (min-width: 410px) and (max-width: 500px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 20px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 10px !important;
    margin-top: -15px !important;
    width: 100% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 22px !important;
    opacity: 0.9;
    font-size: 13px !important;
    font-weight: 500;
    width: 94%;
    text-align: justify;
    margin-bottom: 12px;
  }

  .homeBannerbtn {
    padding: 9px 0px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 120px;
    text-align: center;
    cursor: pointer;
  }

  /* .carousel-indicators {
    display: none !important;
  } */
}

@media (min-width: 338px) and (max-width: 410px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 17px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 7px !important;
    margin-top: -10px !important;
    width: 95% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 20px !important;
    opacity: 0.9;
    font-size: 13px !important;
    font-weight: 500;
    width: 90%;
    text-align: justify;
    margin-bottom: 12px;
  }

  .homeBannerbtn {
    padding: 9px 0px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 120px;
    text-align: center;
    cursor: pointer;
  }

  /* .carousel-indicators {
    display: none !important;
  } */
}


@media (min-width: 200px) and (max-width: 338px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 16px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 10px !important;
    margin-top: -8px !important;
    width: 94% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 19px !important;
    opacity: 0.9;
    font-size: 12px !important;
    font-weight: 500;
    width: 90%;
    text-align: justify;
    margin-bottom: 6px;
  }

  .homeBannerbtn {
    padding: 9px 0px;
    border-radius: 20px;
    font-size: 12px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 95px;
    text-align: center;
    cursor: pointer;
  }

  /* .carousel-indicators {
    display: none !important;
  } */
}


.homeBannerbtn a {
  color: #ffffff !important;
}

.butn a {
  color: #ffffff !important;
}


/* new update ui  */
@media (max-width:600px){
  .banner1 .carousel-caption {
    position: relative;
    top: 10px !important;
    left: 3%;
    color: #fff;
    text-align: left !important;
  }
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 8px !important;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #c83363;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .7;
  transition: opacity .6s ease;
}

/* .banner1 .carousel-indicators{
  display: none !important;
} */

@media (max-width:600px){
  .carousel-indicators {
   display: none !important;
}
}
.banner2 {
  padding: 40px 3%;
}

.banner2-div2 {
  padding: 30px 0;
  box-shadow: 0 0 3px #0003;
  text-align: center;
  border-radius: 5px;
}

.b2-img {
  /* border: 1px solid #eeeeee; */
  border-radius: 100%;
  min-width: 75px;
  min-height: 75px;
  padding: 18px 0;
  text-align: center;
  transition: 0.3s all ease;
  display: inline-block;
}

.b2-img img {
  width: 65px;
  height: 65px;
}

.banner2-col {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.banner2-col h2 {
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 10px;
  font-size: 18px;
  text-align: left;
}

.banner2-col p {
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 15px;
  letter-spacing: 0;
  color: #4c4f4e;
}

.banner2-div2 ul {
  list-style: none;
  display: inline-block;
}

.banner2-div2 li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
}

.li-left {
  text-align: left;
  padding: 0em 1em;
  margin-top: 1em;
  width: 70%;
}

.mg-0 {
  margin: 0px;
}

.pd-0b {
  padding: 0px;
}

@media (max-width: 995px) {
  .banner2 {
    padding: 40px 3% !important;
  }

  .li-left {
    text-align: center !important;
  }

  .banner2-div2 {
    padding: 18px 0px !important;
  }

  .li-left p {
    font-size: 12px !important;
  }
}

.footer-lastCard {
  width: 30%;
}

.footercart {
  width: 325px;
  background-color: #fff5f8;
  border-radius: 10px;
  box-shadow: none;
  margin-left: 5px;
  border: 1px solid #fff5f8;
}

.footerCard {
  display: flex;
}

.footercart img {
  transition: all .5s;
}

.footerCard img {
  width: 50%;
  margin-top: auto;
}

.CustomerPara {
  margin-top: 20px;
  margin-left: 2px;
}

.footer-card-main {
  padding: 40px 3%;
}





/* chnage by laxmi------------------- add (min-width) */
@media (min-width:769px) and (max-width: 1280px) {
  .phnTabDisplayWriteup {
    display: none !important;
  }

  .footerCard {
    display: block;
  }

  .footerCard img {
    width: 50%;
    margin-left: 25%;
    margin-left: 2;
  }

  /* mobile view change change by laxmi*/
  .CustomerPara {
    margin-left: 0px !important;
    text-align: center !important;
  }
}

/* add by laxmi */

@media(min-width:280px) and (max-width:769px) {
  .footer-card-main {
    padding: 0;
  }

  .DeskTabDisplayWriteup {
    display: none !important;
  }


  .writeupDivPhnView {
    background-color: #fff5f8;
    border-radius: 10px !important;
    box-shadow: none;
    margin-left: 0px;
    border: 1px solid #fff5f8;
    margin-bottom: 25px !important;
    text-align: center !important;
  }

  .imgWriteupHeight {
    height: 120px !important;
    margin: auto;
  }

  .CustomerPara {
    margin-top: 20px;
    margin-left: 2px;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

@media (min-width:995px) {
  .phnTabDisplayWriteup {
    display: none;
  }
}
.content-head {
  padding: 40px 3%;
}

.content-head h2 {
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 20px;
  text-align: center !important;
  font-size: 40px;
}

.content-head1 {
  font-size: 16px !important;
  text-transform: capitalize;
  font-weight: 500;
  color: #2b2b2b;
  max-width: 850px;
  margin: auto;
  line-height: 25px;
  text-align: center;
}

.slider1-con1 {
  padding: 0 15px;
  width: 100%;
}

.slider1-card {
  width: 100% !important;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 0px;
}

.slider1-con1 {
  padding-bottom: 2em;
}

.slider1-con1 p {
  font-size: 16px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 12px;
}

.slider1-con1 h6 {
  text-align: center;
}

.slider1-con1 ul {
  text-decoration: none;
  text-align: center;
  padding: 0em;
}

.slider1-con1 li {
  display: inline-block;
  color: #ababab;
}

.content-pd {
  padding-top: 30px;
}

.content-head .slick-prev,
.content-head .slick-next {
  width: 40px !important;
  height: 40px !important;
  top: 49% !important;
}

.content-head .slick-prev {
  z-index: 1 !important;
}

.content-head .slick-prev:before,
.content-head .slick-next:before {
  color: #123b4f !important;
  font-size: 38px !important;
  z-index: -1 !important;
}

.margin-1card {
  padding: 0.5em;
}

@media (max-width: 995px) {

  .content-head .slick-prev,
  .content-head .slick-next {
    display: none !important;
  }
}


/* add by Laxmi */
@media (min-width:600px) and (max-width:995px) {
  .content-head h2 {
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 6px;
    text-align: center !important;
    font-size: 30px !important;
  }

  .content-head1 {
    font-size: 15px !important;
    text-transform: capitalize;
    font-weight: 500;
    color: #2b2b2b;
    max-width: 850px;
    margin: auto;
    line-height: 22px;
    text-align: center;
  }
}

@media (min-width:500px) and (max-width:600px) {

  .content-head h2 {
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 6px;
    text-align: center !important;
    font-size: 30px !important;
  }

  .content-head1 {
    font-size: 15px !important;
    text-transform: capitalize;
    font-weight: 500;
    color: #2b2b2b;
    max-width: 850px;
    margin: auto;
    line-height: 22px;
    text-align: center;
  }
}

@media (min-width:400px) and (max-width:500.9px) {

  .content-head h2 {
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 6px;
    text-align: center !important;
    font-size: 30px !important;
  }

  .content-head1 {
    font-size: 13px !important;
    text-transform: capitalize;
    font-weight: 500;
    color: #2b2b2b;
    max-width: 850px;
    margin: auto;
    line-height: 22px;
    text-align: center;
  }
}

@media (min-width:280px) and (max-width:400px) {
  .content-head h2 {
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 6px;
    text-align: center !important;
    font-size: 22px !important;
  }

  .content-head1 {
    font-size: 13px !important;
    text-transform: capitalize;
    font-weight: 500;
    color: #2b2b2b;
    max-width: 850px;
    margin: auto;
    line-height: 18px;
    text-align: center;
  }
}

@media (min-width:481px) and (max-width:700px) {
  .butn2 {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 12px;
    background-color: #c83363;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
}
/* @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap");

body {
  font-family: "Nunito", sans-serif;
} */

.listing-page-container {
  justify-content: space-between;
  padding: 1em 0em;
  /* width: 86vw; */
  margin: 0 3%;
  overflow-x: hidden;
}

.heading {
  font-size: 2.25rem;
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 30px;
  font-family: "Nunito", sans-serif;
}

.heading h5 {
  font-size: 2.5rem;
  font-weight: 700;
}

.wbsortbar {
  margin-bottom: 30px;
  margin-top: 15px;
  align-items: center;
  border: 1px solid #eeeeee;
  margin-left: 0;
  margin-right: 0;
  border-radius: 10px;
  padding: 5px 0;
}

.wbglbtn button {
  background-color: transparent;
  border: none;
  padding: 0;
  transition: 0.3s ease;
}

.listv.wblistgridbtn {
  margin: 0 15px;
}

.listing-page svg {
  width: 14px;
  height: 14px;
  transition: 0.3s ease;
  vertical-align: middle;
}

.wbcatsort #FacetFiltersForm {
  display: flex;
  justify-content: right;
  align-items: center;
  float: right;
}

.wbcatsort .product-count {
  color: var(--color-wbmaincolors-text);
}

.wbcatsort .product-count__text {
  font-size: calc(var(--font-size-base) * 1.29px);
  text-transform: capitalize;
  margin: 0 30px;
}

.caption {
  font-size: calc(var(--font-size-base) * 1px);
  letter-spacing: 0.07rem;
}

.facet-filters__field {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.caption span {
  color: 0, 0, 0;
}

.facet-filters__label label {
  margin: 0 10px;
}

.facet-filters__field .select {
  width: auto;
}

.facet-filters__sort {
  height: auto;
  margin: 0;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 6px 25px 6px 10px;
  letter-spacing: 0.5px;
}

.facet-filters__sort,
.facet-filters__sort:hover {
  box-shadow: none;
}

.facet-filters__sort+.icon-caret {
  right: 10px;
  width: 10px;
}

.select .icon-caret,
.customer select+svg {
  height: 0.6rem;
  pointer-events: none;
  position: absolute;
  top: calc(50% - 0.2rem);
  right: 1.5rem;
}

.wbcatsort #FacetFiltersForm {
  display: flex;
  justify-content: right;
  align-items: center;
}

.wblistgridbtn.active svg,
.wblistgridbtn svg:hover {
  fill: #3d405b;
}

.text-area,
.select {
  display: inline-block;
  position: relative;
  width: 100%;
}

.select__select,
.customer select {
  cursor: pointer;
  /* padding: 0 2rem 0 0.5rem; */
}

select {
  word-wrap: normal;
}

.select #option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

.facets-container .disclosure-has-popup {
  margin-bottom: 30px;
}

.disclosure-has-popup {
  position: relative;
}

.facets__summary {
  font-size: calc(14 * 1.15px);
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 12px;
}

summary {
  cursor: pointer;
  list-style: none;
  position: relative;
}

summary {
  display: list-item;
  cursor: pointer;
}

details>* {
  box-sizing: border-box;
}

.mobile-facets__summary>div,
.facets__summary>div {
  display: flex;
  align-items: center;
}

.facets__summary span {
  transition: -webkit-text-decoration 0.1s ease;
  transition: text-decoration 0.1s ease;
  transition: text-decoration 0.1s ease, -webkit-text-decoration 0.1s ease;
}

.facets__disclosure[open] .facets__display {
  animation: animateMenuOpen 0.2s ease;
}

.facets__header {
  display: flex;
  justify-content: space-between;
  letter-spacing: 0;
  padding-bottom: 15px;
}

.facets__reset {
  margin-left: auto;
}

.link {
  cursor: pointer;
  display: inline-block;
  border: none;
  box-shadow: none;
  text-decoration: none;
  text-underline-offset: 0.3rem;
  background-color: transparent;
  font-family: inherit;
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.facets__item {
  display: flex;
  align-items: center;
}

.facets__item label,
.facets__item input[type="checkbox"] {
  cursor: pointer;
}

.facet-checkbox {
  padding: 0;
  flex-grow: 1;
  position: relative;
  font-size: rgb(0, 0, 0);
  word-break: break-word;
  transition: 0.3s ease;
  margin-bottom: 10px;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.facet-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 1;
  width: 20px;
  height: 20px;
  top: 2px;
  left: -1px;
  z-index: -1;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid var(--color-wbbordercolor);
}

.facets__item label,
.facets__item input[type="checkbox"] {
  cursor: pointer;
}

input[type="checkbox"] {
  display: inline-block;
  width: auto;
  margin-right: 0.5rem;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.facet-checkbox>svg {
  background-color: rgb(var(--color-background));
  margin-right: 10px;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  transition: 0.3s ease;
  opacity: 0;
}

.facet-checkbox .icon-checkmark {
  visibility: hidden;
  position: absolute;
  left: 0;
  z-index: 5;
  top: 3px;
  opacity: 1;
}

@media (max-width: 768px) {
  #main-collection-filters {
    display: none;
  }

  /* #for-mobile-view {
    display: block;
  } */

  /* #for-desktop-view {
    display: none;
  } */
}

@media (min-width: 768px) {
  #for-mobile-view {
    display: none;
  }

  /* #for-desktop-view {
    display: block;
  } */
}

select:focus-visible {
  outline: none !important;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #55585b !important;
  border-radius: 10px;
  top: 0;
}

.slider1-con1 {
  padding: 0 15px;
  width: 100%;
}

.slider1-card {
  width: 100% !important;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 0px;
}

.slider1-con1 {
  padding-bottom: 2em;
}

.slider1-con1 p {
  font-size: 16px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 12px;
  color: #2b2b2b;
}

/* Card CSS */

.price h6 {
  /* text-align: center; */
  color: #22b7c0;
}

.wbproductdes ul {
  text-decoration: none;
  text-align: center;
  padding: 0em;
}

.wbproductdes li {
  display: inline-block;
  color: #f5db3c !important;
}

.content-pd {
  padding-top: 30px;
}

.wbproduct-container {
  background-color: #f5f5f5;
  transition: 0.3s ease;
  border-radius: 10px;
}

.wbimgblock {
  position: relative;
  text-align: center;
}

.card__badge span {
  position: absolute;
  left: 15px;
  top: 15px;
  margin: 0 auto;
  display: inline-block;
  text-transform: capitalize;
  line-height: normal;
  font-size: calc(14 * 1px);
  border: none;

  background-color: #c83363;
  color: #ffffff;
  padding: 2px 10px;
  border-radius: 4px;
}

.product_badge {
  position: absolute;
  right: 15px;
  top: 15px;
  margin: 0 auto;
  display: inline-block;
  text-transform: capitalize;
  line-height: normal;
  font-size: calc(14 * 1px);
  border: none;
  background-color: #c83363;
  color: #ffffff;
  padding: 2px 10px;
  border-radius: 4px;
}

.wbproductdes {
  padding: 0 15px 10px;
  text-align: center;
}

.wbproductdes {
  padding: 0 15px 10px;
  text-align: center;
}

.product-title {
  font-size: 16px;
  text-transform: capitalize;
  transition: 0.3s ease;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 12px;
  font-weight: 600;
  margin-bottom: 0;
}

a {
  color: rgb(0, 0, 0);
  transition: 0.3s ease;
}

#product-grid .grid__item {
  margin-bottom: 30px;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #3d405b;
  border-color: #3d405b;
}

.rless {
  margin-left: -15px;
  margin-right: -15px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #3d405b;
  border-color: #3d405b;
}

.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.product-list .wbproduct-container {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.product-list .wbimgblock {
  float: left;
  width: 21%;
}

.product-list .wbproductdes {
  float: left;
  width: 70%;
  text-align: left;
  padding: 15px;
}

.product-list .wbhprice {
  margin-bottom: 0;
}

.product-list .wbproduct-container .product-form {
  position: unset;
  opacity: 1;
  margin-top: 10px;
  text-align: left;
  display: inline-block;
}

/* Card CSS End */

.product-list .grid__item {
  flex: 0 0 100%;
  max-width: 100%;
}

.product-list .product-form .cartb,
.product-list .product-form .wbquickv,
.product-list .product-form .wbwish a {
  border: 1px solid var(--color-wbbordercolor);
  margin: 0 4px;
  border-radius: 4px;
}

.product-list .wbproduct-container .product-form form,
.product-list .wbproduct-container .button-group {
  position: unset;
  opacity: 1;
  display: inline-block;
}

.product-form .cartb,
.product-form .wbquickv,
.product-form .wbwish a {
  border: none;
  text-transform: capitalize;
  background: rgb(255, 255, 255);
  min-width: 40px;
  min-height: 40px;
  display: inline-block;
  vertical-align: middle;
  color: rgb(0, 0, 0);
  text-align: center;
  transition: 0.3s ease;
  letter-spacing: 0.5px;
  padding: 8px;
  margin: 5px 0;
  border-radius: 4px;
  box-shadow: 0 0 5px #0003;
}

.product-list .product-form .cartb,
.product-list .product-form .wbquickv,
.product-list .product-form .wbwish a {
  border: 1px solid #eeeeee;
  margin: 0 4px;
  border-radius: 4px;
}

.form .product-form__submit {
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  line-height: 1.25;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 10px;
  background: #3d405b;
  color: #ffffff;
  transition: 0.3s ease;
  -webkit-appearance: none;
  appearance: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
}

#for-desktop-view .form {
  padding: 15px;
}

.wblistgridbtn.active svg,
.wblistgridbtn svg:hover {
  fill: #3d405b;
}

.wbglbtn button svg {
  width: 23px;
  height: 23px;
  fill: rgba(0, 0, 0, 0.5);
}

.facet-checkbox>svg {
  background-color: rgb(255, 255, 255);
  margin-right: 10px;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  transition: 0.3s ease;
  opacity: 0;
}

.facet-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 1;
  width: 20px;
  height: 20px;
  top: 2px;
  left: -1px;
  z-index: -1;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid var(--color-wbbordercolor);
}

.facets__item label,
.facets__item input[type="checkbox"] {
  cursor: pointer;
}

input[type="checkbox"] {
  display: inline-block;
  width: auto;
  margin-right: 0.5rem;
}

.facet-checkbox>input[type="checkbox"]:checked~.icon-checkmark {
  visibility: visible;
  background: #3d405b;
  color: #ffffff;
}

.butn2 {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.button-soldout {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #bfbebe;
  color: #ffffff;
  text-align: center;
  /* width: 30%; */
  cursor: default;
}

.button-soldout-list {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #bfbebe;
  color: #ffffff;
  text-align: center;
  width: 30%;
  cursor: default;
}

.butn3 {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  text-align: center;
  width: 30%;
  cursor: pointer;
}

.breadcrumb-content .breadcrumb {
  margin: 1rem 0px !important;
  background-color: transparent !important;
}

.list-breadcrumb-flex {
  display: grid;
  justify-content: end;
  align-items: center;
}

.listingpage-banner img {
  width: 100%;
}

.listingpage-product-list ul {
  text-decoration: none;
  text-align: inherit;
  padding: 0em;

}

.listing-page-breadcumb .breadcrumb-content {
  /* background: #f7f7f7; */
  border-radius: 10px;
  /* border: 1px solid #eeeeee; */
  text-transform: capitalize;
  padding: 1px 15px;
  margin: 0px 0px !important;
}

.wbglbtn {
  margin: 0 30px !important;
}

.wbproduct-container-list {
  /* background-color: #f5f5f5; */
  transition: 0.3s ease;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
}


/* add by laxmi */

@media (max-width:768px) {

  .wbglbtn,
  .product-count {
    display: none !important;
  }

  .listing-page-breadcumb .breadcrumb-content {
    border-radius: 10px;
    text-transform: capitalize;
    padding: 0px 0px;
    margin: 0px 0px !important;
    font-size: 13px;
  }

  .select {
    font-size: 13px;
  }
}

@media (max-width:500px) and (min-width:380px) {
  .breadcrumb-content .breadcrumb {
    margin: 0px !important;
    background: transparent;
  }

  .facet-filters__sort {
    height: auto;
    margin: 0;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 6px 0px 6px 0px;
    letter-spacing: 0.1px;
  }

  .select {
    font-size: 11px;
  }

  .listing-page-breadcumb .breadcrumb-content {
    border-radius: 10px;
    text-transform: capitalize;
    padding: 0px 0px;
    margin: 0px 0px !important;
    font-size: 11px;
  }

  .wbsortbar {
    margin-bottom: 30px;
    margin-top: 15px;
    align-items: center;
    border: 1px solid #eeeeee;
    margin-left: 0;
    margin-right: 0;
    border-radius: 10px;
    padding: 0px 0;
  }

  .button-soldout {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 15px;
    background-color: #bfbebe;
    color: #ffffff;
    text-align: center;
    /* width: 30%; */
    cursor: default;
  }
}

@media (max-width:380px) and (min-width:280px) {
  .wbsortbar {
    margin-bottom: 30px;
    margin-top: 15px;
    align-items: center;
    border: 1px solid #eeeeee;
    margin-left: 0;
    margin-right: 0;
    border-radius: 10px;
    padding: 0px 0;
  }

  .select {
    font-size: 8px;
  }

  .listing-page-breadcumb .breadcrumb-content {
    border-radius: 10px;
    text-transform: capitalize;
    padding: 0px 0px;
    margin: 0px 0px !important;
    font-size: 8px;
  }

  .button-soldout {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 15px;
    background-color: #bfbebe;
    color: #ffffff;
    text-align: center;
    /* width: 30%; */
    cursor: default;
  }

  .breadcrumb-content .breadcrumb {
    margin: 0px !important;
    background: transparent;
  }

  .facet-filters__sort {
    height: auto;
    margin: 0;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 6px 0px 6px 0px;
    letter-spacing: 0.1px;
  }

  /* cards item */
  .product-title {
    font-size: 14px;
    text-transform: capitalize;
    transition: 0.3s ease;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 0 2px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .price__sale h6 {
    font-size: 13px;
  }

  .wbproductdes {
    padding: 0 6px 10px;
    text-align: center;
  }

  .button-soldout {
    padding: 5px 0px;
    border-radius: 20px;
    font-size: 13px;
    background-color: #bfbebe;
    color: #ffffff;
    text-align: center;
    /* width: 30%; */
    cursor: default;
  }

  .listingpage-product-list {
    font-size: 13px;
  }
}

@media (min-width:768px) and (max-width:860px) {
  .wbsortbar {
    margin-bottom: 30px;
    margin-top: 15px;
    align-items: center;
    border: 1px solid #eeeeee;
    margin-left: 0;
    margin-right: 0;
    border-radius: 10px;
    padding: 0px 0;
  }

  .wbcatsort .product-count__text {
    font-size: 13px;
    text-transform: capitalize;
    margin: 0 30px;
    width: 108px;
  }
}


@media (min-width:769px) and (max-width:844px) {
  .select {
    font-size: 13px;
  }

  .listing-page-breadcumb .breadcrumb-content {
    border-radius: 10px;
    text-transform: capitalize;
    padding: 0px 0px;
    margin: 0px 0px !important;
    font-size: 13px;
  }
}

.butn2checkout{
  font-size: 18px !important;
}
body {
    background: #fff;
  
  }
  .usercard{
    padding: 20px;
    
  }
  
  .cards{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    background: #fff;
    padding: 10px;
    margin: 10px;
  }
.banner3 {
  padding: 40px 3%;
}
.banner3 img {
  /* max-width: 100%; */
  height: auto;
  width: 100%;
}

.content4 {
  padding: 40px 3%;
}
.content4 img {
  max-width: 80%;
  height: auto;
}

.blog-main {
  padding: 40px 3%;
}
.blog-main h2 {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px;
  /* text-align: center !important; */
  font-size: 40px;
}
.blog-main p {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  color: #3d405b;
  max-width: 850px;
  margin: auto;
  line-height: 25px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-img img {
  width: 100%;
  border-radius: 10px;
}
.blog-flex {
  display: flex;
  justify-content: space-between;
}
.blog-h2p {
  /* padding: 2em 0em 0em 0em; */
}
.blog-h2 {
  font-size: 35px;
  text-transform: capitalize;
  padding: 0 0 15px;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left !important;
}
.blog-h2p p {
  font-size: 16px;
  color: #928f8f;
  letter-spacing: 0;
  margin-bottom: 15px;
  text-align: left;
  font-weight: 500;
  text-align: justify;
}
.pad-row {
  padding-top: 2em;
  display: flex !important;
}
/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
}
.butn:hover {
  background-color: #ff3772;
} */
.but-margin-blog {
  margin-top: 40px;
}
@media (max-width: 995px) {
  .blog-main h2 {
    margin: 0px !important;
    font-size: 20px !important;
    padding: 0px;
    text-align: left;
  }
  .blog-h2p {
    padding: 0em !important;
  }
  .blog-h2p p {
    font-size: 13px !important;
    margin-bottom: 1px !important;
  }
  /* .butn {
    padding: 11px 0px !important;
    width: 30% !important;
    margin-top: 10px !important;

    text-align: center !important;
  } */
}

.IAygG {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  /* grid-template-rows: 25% 25% 25% 25%; */

  margin: 2% 2%;
}

.iSuBqU {
  padding: 0% 0.5%;
  height: 12rem;
  cursor: pointer;
}

.che img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.category-h2 {
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 27px;

  text-align: center !important;
  font-size: 40px;
}

.che .slick-prev,
.che .slick-next {
  width: 40px !important;
  height: 40px !important;
  top: 49% !important;
}

.che .slick-prev {
  z-index: 1 !important;
}

.che .slick-prev:before,
.che .slick-next:before {
  color: #123b4f !important;
  font-size: 38px !important;
  z-index: -1 !important;
}


/* add by laxmi */
.categoryDisplay {
  margin-right: 10px;
}

@media (max-width:480px) {
  .categoryDisplay {
    margin-right: 0px !important;
  }

}

@media (min-width:400px) and (max-width:995px) {
  .category-h2 {
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 22px !important;
    text-align: center !important;
    font-size: 30px !important;
  }
}

@media (min-width:280px) and (max-width:400px) {
  .category-h2 {
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 16px !important;
    text-align: center !important;
    font-size: 22px !important;
  }
}

@media (max-width:600px) {
  .categorynameFont {
    font-size: 15px !important;
  }
}

.categorynameFont {
  text-align: center;
  font-size: 1.2rem;
  padding-top: 0.5em;
}
.card4-row2 {
  margin-top: 25px;
}

.card4-div1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.card4-div2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.card4-div1 img {
  max-width: 100%;
  height: 100%;
  border-radius: 10px;
}

.card4-div2 img {
  height: 100%;
  border-radius: 10px;
  max-width: 100%;
}

.card4-mainrow {
  position: relative;
  width: 100%;
  height: 100%;
}

.card4-mainrow .collff {
  position: absolute;
  top: 82px;
  /* width: 55%; */
  width: 70%;
  left: 3%;
  display: flex;
  flex-direction: column;
}

.collff1 {
  position: absolute;
  top: 82px;
  /* width: 70%; */
  width: 90%;
  left: 8%;
  display: flex;
  flex-direction: column;
}

.collff1 h2 {
  font-size: 25px;
  text-transform: capitalize;
}

/* add by laxmi */
.collff h2 {
  font-size: 25px;
  text-transform: capitalize;
}

.collff1 p {
  text-transform: capitalize;
  font-weight: 600;
  /* color: #917a70;  */
}

.collff p {
  text-transform: capitalize;
  font-weight: 600;
  /* color: #917a70; */
}

.btnprimary-div {
  margin-top: 40px;
}

.btnprimary {
  text-decoration: none;
  color: black;
  padding: 12px 35px;
  border-radius: 10px;
  background-color: #fff;
  padding: 12px 35px;
  font-size: 14px;
  font-weight: 500;
}

.card4-pd {
  padding: 40px 3%;
}

.ban2-none {
  display: block !important;

  /* add by laxmi */
  font-size: 14px !important;
}


@media (min-width:781px) and (max-width: 995px) {
  
   .collff h2 {
    font-size: 19px !important;
    text-transform: capitalize;
  }
  .collff1 h2 {
    font-size: 19px !important;
  }
}


@media (min-width:665px) and (max-width: 781.9px) {  
  .collff h2 {
   font-size: 16px !important;
   text-transform: capitalize;
 }
 .collff1 h2 {
   font-size: 16px !important;
 }
}

@media (min-width:500px) and (max-width: 665.9px) {  
  .collff h2 {
   font-size: 14px !important;
   text-transform: capitalize;
 }
 .collff1 h2 {
   font-size: 14px !important;
 }
}

@media (min-width:595px) and (max-width: 995px) {
  .ban2-none {
    display: none !important;
  }

  /* add by laxmi-----------------*/
  .btnprimary-div {
    margin-top: 11px !important;
  }

  .card4butn {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 13px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 100px;
    text-align: center;
    cursor: pointer;
  }

  .card4-mainrow .collff {
    top: 35% !important;
  }

  .collff1 {
    top: 35% !important;
  }


 
  /* add by laxmi-------------------------------------------- */

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }
  .collff1 {
    position: absolute;
    top: 68px;
    width: 86% !important;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 12px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

}

/* add by laxmi */
@media (min-width:500px) and (max-width: 595px) {
  .collff1 h2 {
    font-size: 13px !important;
  }

  .collff h2 {
    font-size: 13px !important;
    text-transform: capitalize;
  }

  .ban2-none {
    display: none !important;
  }

  .btnprimary-div {
    margin-top: 12px !important;
  }

  .card4butn {
    padding: 7px 0px;
    border-radius: 20px;
    font-size: 12px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 90px;
    text-align: center;
    cursor: pointer;
  }

  .card4-mainrow .collff {
    top: 35% !important;
  }

  .collff1 {
    top: 35% !important;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }

  .collff1 {
    position: absolute;
    top: 68px;
    width: 86% !important;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 6px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
}

@media (min-width:440px) and (max-width: 500px) {
  .ban2-none {
    display: none !important;
  }

  .btnprimary-div {
    margin-top: 12px !important;
  }

  .card4butn {
    padding: 4px 0px;
    border-radius: 20px;
    font-size: 9px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 67px;
    text-align: center;
    cursor: pointer;
  }

  .card4-mainrow .collff {
    top: 35% !important;
  }

  .collff1 {
    top: 35% !important;
  }

  .collff1 h2 {
    font-size: 10px !important;
  }

  .collff h2 {
    font-size: 10px !important;
    text-transform: capitalize;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }

  .collff1 {
    position: absolute;
    top: 68px;
    width: 86% !important;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 6px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
}

@media (min-width:342px) and (max-width: 440px) {
  .ban2-none {
    display: none !important;
  }

  .btnprimary-div {
    margin-top: 4px !important;
  }

  .card4butn {
    padding: 3px 0px;
    border-radius: 20px;
    font-size: 7px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 54px;
    text-align: center;
    cursor: pointer;
  }

  .collff1 {
    top: 35% !important;
  }

  .card4-mainrow .collff {
    top: 35% !important;
  }

  .collff1 h2 {
    font-size: 8px !important;
  }

  .collff h2 {
    font-size: 8px !important;
    text-transform: capitalize;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }

  .collff1 {
    position: absolute;
    top: 68px;
    width: 86% !important;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 6px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
}

@media (min-width:280px) and (max-width: 342px) {
  .ban2-none {
    display: none !important;
  }

  .btnprimary-div {
    margin-top: 6px !important;
  }

  .card4butn {
    padding: 3px 0px;
    border-radius: 20px;
    font-size: 5px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 41px;
    text-align: center;
    cursor: pointer;
  }

  .collff1 {
    top: 36% !important;
  }

  .card4-mainrow .collff {
    top: 36% !important;
  }

  .collff1 h2 {
    font-size: 6px !important;
  }

  .collff h2 {
    font-size: 6px !important;
    text-transform: capitalize;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }

  .collff1 {
    position: absolute;
    top: 68px;
    width: 86% !important;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 6px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
}

@media (min-width:995px) and (max-width: 1065px) {

  .collff h2,
  .collff1 h2 {
    font-size: 19px !important;
  }

  .ban2-none {
    font-size: 14px !important;
  }

  .btnprimary-div {
    margin-top: 12px !important;
  }

  .card4butn {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px;
    text-align: center;
    cursor: pointer;
  }

  .card4-mainrow .collff {
    top: 24% !important;
  }

  .collff1 {
    top: 24% !important;
  }

  .collff h2 {
    text-transform: capitalize;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 55% !important;
    left: 3%;
    display: flex;
    text-align: justify;
    flex-direction: column;
  }

  .collff1 {
    position: absolute;
    top: 68px;
    width: 80% !important;
    text-align: justify;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 6px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
}

@media (min-width:1065px) and (max-width: 1300px) {

  .collff h2,
  .collff1 h2 {
    font-size: 20px !important;
  }

  .ban2-none {
    font-size: 16px !important;
  }

  .btnprimary-div {
    margin-top: 12px !important;
  }

  .card4butn {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px;
    text-align: center;
    cursor: pointer;
  }

  .card4-mainrow .collff {
    top: 24% !important;
  }

  .collff1 {
    top: 24% !important;
  }

  .collff h2 {
    text-transform: capitalize;
  }

  .card4-mainrow .collff {
    position: absolute;
    top: 68px;
    width: 75% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
  }

  .collff1 {
    position: absolute;
    top: 68px;
    width: 90% !important;
    left: 8%;
    display: flex;
    flex-direction: column;
  }

  .card4-row2 {
    margin-top: 6px;
  }

  .card4LeftPaddingRow1Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow1Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }

  .card4LeftPaddingRow2Phn1 {
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card4RightPaddingRow2Phn1 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
}


@media (min-width:380px) and (max-width:559px){
  .deskview{
    display: none;
  }
  .collffphn h2{
    font-size: 4vmin !important;
    text-transform: capitalize;
  }
  .card4-mainrow .collffphn {
    position: absolute;
    top: 20px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
    top: 11px !important;
}
.card4butnphn {
  padding: 7px 0px;
  border-radius: 20px;
  font-size: 14px;
  background-color: #c83363;
  color: #ffffff !important;
  width: 100px;
  text-align: center;
  cursor: pointer;
}
.collffphntopmargin{
  margin-top: 14px !important;
}

.collffphntopmargin h2{
  font-size: 4vmin !important;
  text-transform: capitalize;
}
.card4-mainrow .collffphntopmargin {
  position: absolute;
  top: 0;
  width: 88% !important;
  left: 3%;
  display: flex;
  flex-direction: column;
}
.collffphntopmargintop{
  margin-top: 11px !important;
}
}


@media (min-width:0px) and (max-width:380px){
  .deskview{
    display: none;
  }
  .collffphn h2{
    font-size: 5vmin !important;
    text-transform: capitalize;
  }
  .card4-mainrow .collffphn {
    position: absolute;
    top: 20px;
    width: 88% !important;
    left: 3%;
    display: flex;
    flex-direction: column;
    top: 11px !important;
}
.card4butnphn {
  padding: 5px 0px !important;
  border-radius: 20px;
  font-size: 11px !important;
  background-color: #c83363;
  color: #ffffff !important;
  width: 80px !important;
  text-align: center;
  cursor: pointer;
}

.collffphntopmargin{
  margin-top: 14px !important;
}

.collffphntopmargin h2{
  font-size: 5vmin !important;
  text-transform: capitalize;
}
.card4-mainrow .collffphntopmargin {
  position: absolute;
  top: 0;
  width: 88% !important;
  left: 3%;
  display: flex;
  flex-direction: column;
}
.collffphntopmargintop{
  margin-top: 11px !important;
}
}

@media (min-width:560px){
  .phnview{
    display: none;
  }
}

.blog-main {
  padding: 40px 3%;
}

.blog-main h2 {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px;
  /* text-align: center !important; */
  font-size: 40px;
}

.blog-main p {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  color: #3d405b;
  max-width: 850px;
  margin: auto;
  line-height: 25px;
  /* text-align: center; */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* change by laxmi */

/* .blog-img img {
  width: 100%;
  border-radius: 10px;
} */

.blog-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* change by laxmi end*/

.blog-flex {
  display: flex;
  justify-content: space-between;
}

.blog-h2p {
  /* padding: 2em 0em 0em 0em; */
}

.blog-h2 {
  font-size: 35px;
  text-transform: capitalize;
  padding: 0 0 15px;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left !important;
}

.rightup-p {
  font-size: 16px;
  color: #928f8f;
  letter-spacing: 0;
  margin-bottom: 15px;
  text-align: left;
  font-weight: 500;
  text-align: justify;
}

.pad-row {
  padding-top: 2em;
  display: flex !important;
}

.but-margin-blogbelowTestimonial {
  /* change by laxmi */
  /* margin-top: 40px; */
  padding-bottom: 40px;
  padding-top: 40px;
}

@media (max-width: 995px) {
  .blog-main h2 {
    margin: 0px !important;
    font-size: 20px !important;
    padding: 0px;
    text-align: left;
  }

  .blog-h2p {
    padding: 0em !important;
  }

  .blog-h2p p {
    font-size: 13px !important;
    margin-bottom: 1px !important;
  }

  /* add by laxmi---------------------------------------------------------- */
  .butnWriteup {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px;
    text-align: center;
    cursor: pointer;
  }
}


/* add by laxmi------------------------------------------------ */
@media (max-width:768px) {
  .writeupPehraPhn {
    margin-top: 15px;
  }

  .but-margin-blogbelowTestimonial {
    padding-bottom: 0px !important;
    padding-top: 20px !important;
  }
}
.banner3 {
  padding: 40px 3%;
}

.banner3 img {
  /* max-width: 100%; */
  height: auto;
  width: 100%;
}

@media (min-width: 1400px) and (max-width: 1629px) {
  .container {
    max-width: 1370px;
  }
}

.wblbookmain {
  text-align: center;
  position: relative;
}

.wblbook img {
  border-radius: 10px;
}

.wblbookmain .wblookbook {
  position: absolute;
  cursor: pointer;
  min-width: 25px;
  min-height: 25px;
}

.wblbookmain {
  text-align: center;
  position: relative;
}

.wblbookmain .wblookbook .wblookbtn {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 25px;
  min-height: 25px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transition: all .3s ease;
  z-index: 2;
  background: #3d405b;
  border-radius: 100%;
}

.wblbookmain .wblookbook .wblookbtn {
  width: 25px;
  height: 25px;
  padding: 6px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: rgba(255, 255, 255, .8);
  border-radius: 100%;
  transition: padding .3s ease;
}

.wblbookmain .wblookbook .wblookbtn svg {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 25px;
  min-height: 25px;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transition: all .3s ease;
  z-index: 2;
  background: #3d405b;
  border-radius: 100%;
  padding: 6px;
}

.wblbookmain .wblookbook .wblookbtn:after {
  content: "";
  position: absolute;
  /* left: 50%;
  top: 50%; */
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  border-radius: 100%;
  animation: pulse 1.5s ease-out infinite;
}

.wblbookmain .wblookbook .wblookbtn svg path {
  fill: #fff !important;
}

.wblbookmain .wblookbtn.active~.wblbookcnt,
.wblookbleft.active {
  display: block;
}

.wblbookmain .wblookbook .wblbookcnt {
  display: none;
  z-index: 3;
  width: 150px;
  position: relative;
  background: rgb(255, 255, 255);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, .05);
  border-radius: 10px;
  margin-top: 32px;
  left: -60px;
  padding: 10px 0;
}

.wblbookmain .wblookbook .wblookimg a {
  display: block;
}

.wblbook img {
  border-radius: 10px;
}

.wblookdesc {
  padding: 10px 5px 0;
}

.wblookdesc h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(14 * 1px);
  margin-bottom: 4px;
  font-weight: 500;
  text-transform: capitalize;
}

.wblookprice span {
  font-weight: 600;
}

.wblookprice span.old-price {
  text-decoration: line-through;
  font-weight: 400;
  font-size: 13px;
  padding-left: 2px;
  color: rgba(0, 0, 0, .5);
}

.wblookimg img {
  width: 65%;
  height: 65%;
}

.pulse {
  margin: 100px;
  display: none;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: rgb(0, 0, 0);
  cursor: pointer;
  box-shadow: 0 0 0 rgb(0, 0, 0);
  animation: pulse 2s infinite;
}

.pulse:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(0, 0, 0);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}


/* add by laxmi */

@media (max-width:381px) {
  .wblbookmain .wblookbook .wblbookcnt {
    display: none;
    z-index: 3;
    width: 100px !important;
    position: relative;
    background: rgb(255, 255, 255);
    box-shadow: 2px 2px 8px rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin-top: 25px !important;
    left: -32px !important;
    padding: 0px 0 !important;
  }
  .wblookimg img {
    width: 50%;
    height: 50%;
}
  .wblookprice span {
    font-size: 9px !important;
  }

  .wblookdesc h3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: calc(10* 1px) !important;
    margin-bottom: -5px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .wblookdesc {
    padding: 0px 2px 0 !important;
  }

  .butnPlus {
    padding: 5px 0px !important;
    border-radius: 20px !important;
    font-size: 7px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 81px !important;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 6px;
  }
}

@media (min-width:381px) and (max-width:995px) {
  /* .butnPlus {
    padding: 9px 0px !important;
    border-radius: 20px;
    font-size: 12px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 118px !important;
    text-align: center;
    cursor: pointer;
    margin-top: 6px;
  } */

  /* .butnPlus {
    padding: 8px 0px !important;
    border-radius: 20px;
    font-size: 11px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 114px !important;
    text-align: center;
    cursor: pointer;
    margin-top: 4px;
} */

.butnPlus {
  padding: 6px 0px !important;
  border-radius: 20px;
  font-size: 10px !important;
  background-color: #c83363;
  color: #ffffff !important;
  width: 114px !important;
  text-align: center;
  cursor: pointer;
  margin-top: 3px;
}
}


@media (min-width:381px) and (max-width:460px){
  .wblbookmain .wblookbook .wblbookcnt {
    display: none;
    z-index: 3;
    width: 140px;
    position: relative;
    background: rgb(255, 255, 255);
    box-shadow: 2px 2px 8px rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin-top: 28px;
    left: -60px;
    padding: 0px 4px 10px 4px;
}
.wblookimg img {
  width: 48%;
  height: 60%;
}
.wblookdesc {
  padding: 0px;
}
.wblookdesc h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(11 * 1px);
  margin-bottom: -5px;
  font-weight: 500;
  text-transform: capitalize;
}
.wblookprice span {
  font-weight: 600;
  font-size: 11px !important;
}
}


@media (min-width:460px) and (max-width:570px){
  .wblbookmain .wblookbook .wblbookcnt {
    display: none;
    z-index: 3;
    width: 140px;
    position: relative;
    background: rgb(255, 255, 255);
    box-shadow: 2px 2px 8px rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin-top: 31px;
    left: -60px;
    padding: 10px 0;
}
.wblookimg img {
  width: 60%;
  height: 60%;
}
.wblookdesc {
  padding: 0px 3px 0;
}
.wblookdesc h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(13 * 1px);
  margin-bottom: -2px;
  font-weight: 500;
  text-transform: capitalize;
}
.wblookprice span {
  font-weight: 600;
  font-size: 13px !important;
}
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.slider {
    padding-bottom: 20px;
    position: relative;
}

.slider .owl-item.active.center .slider-card {
    transform: scale(1);
    background-color: #ffff;
    opacity: 1;
}

.slider-card {
    background-color: #f8f9fa;
    box-shadow: 0px 0px 15px 0px rgb(7 6 6 / 10%);
    transform: scale(0.9);
    transition: all 0.3s;
    border-radius: 3%;

    /* add by Laxmi */
    height: 500px !important;
}

.slider-card img {
    border-radius: 5px 2px 5px 2px;
}

.owl-nav owl-next {
    position: absolute;
    top: calc(50% - 25px);
    right: 0;
    font-size: 50px;
    opacity: 1;
    z-index: 1;
}

.owl-nav owl-prev {
    position: absolute;
    top: calc(50% - 25px);
    left: 0;
    font-size: 50px;
    opacity: 1;
    z-index: 1;
}

.testi {
    /* font-size:14px; */
    font-weight: bold !important;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: block !important;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    position: absolute;
    right: 40% !important;
    font-size: 150% !important;
    color: #fff !important;
    opacity: 1;
    z-index: 1;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font-size: 150% !important;
    color: #fff !important;
    position: absolute;
    left: 40% !important;
    font-size: 50px;
    opacity: 1;
    z-index: 1;
}

.testimonial-para p {
    font-size: 16px !important;
    text-transform: capitalize;
    font-weight: 500;
    color: #2b2b2b;
    max-width: 850px;
    margin: auto;
    line-height: 25px;
}

/* add by Laxmi */
@media (min-width:630px) and (max-width:995px) {
    .testimonial-para p {
        font-size: 13px !important;
        line-height: 22px;
    }

    .slider-card {
        background-color: #f8f9fa;
        box-shadow: 0px 0px 15px 0px rgb(7 6 6 / 10%);
        transform: scale(0.9);
        transition: all 0.3s;
        border-radius: 3%;
        height: 440px !important;
    }
}

@media (min-width:600px) and (max-width:630px) {
    .testimonial-para p {
        font-size: 15px !important;
        line-height: 25px;
    }

    .slider-card {
        background-color: #f8f9fa;
        box-shadow: 0px 0px 15px 0px rgb(7 6 6 / 10%);
        transform: scale(0.9);
        transition: all 0.3s;
        border-radius: 3%;
        height: 540px !important;
    }
}

@media (min-width:510px) and (max-width:600.9px) {
    .testimonial-para p {
        font-size: 13px !important;
        line-height: 22px;
    }

    .slider-card {
        background-color: #f8f9fa;
        box-shadow: 0px 0px 15px 0px rgb(7 6 6 / 10%);
        transform: scale(0.9);
        transition: all 0.3s;
        border-radius: 3%;
        height: 490px !important;
    }
}

@media (min-width:400px) and (max-width:510px) {
    .testimonial-para p {
        font-size: 13px !important;
        line-height: 22px;
        text-align: justify;
    }

    .slider-card {
        background-color: #f8f9fa;
        box-shadow: 0px 0px 15px 0px rgb(7 6 6 / 10%);
        /* box-shadow: unset !important; */
        transform: scale(0.9);
        transition: all 0.3s;
        border-radius: 3%;
        height: 450px !important;
    }
}

@media (min-width:280px) and (max-width:400px) {
    .testimonial-para p {
        font-size: 12px !important;
        line-height: 18px;
        text-align: justify;
    }

    .slider-card {
        background-color: #f8f9fa;
        box-shadow: 0px 0px 15px 0px rgb(7 6 6 / 10%);
        /* box-shadow: unset !important; */
        transform: scale(0.9);
        transition: all 0.3s;
        border-radius: 3%;
        height: 400px !important;
    }
}

@media (min-width:450px) {
    .textimonialpaddingallview {
        padding: 40px 3% 3px 3% !important;
    }
}

@media (max-width:450px) {
    .textimonialpaddingallview {
        padding: 40px 0% 0px 0% !important;
    }
}

.testimonialslickdotparent .testimonialslickdotchild .slick-dots {
    position: absolute;
    bottom: 5px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.breadcrumb-content {
 
     /* background: #f7f7f7; */
     border-radius: 10px;
     /* border: 1px solid #eeeeee; */
     text-transform: capitalize;
     padding: 1px 15px;
     margin: 40px 0px;
}
.breadcrumb-content .breadcrumb {
  margin: 1rem 0px !important;
  background-color: transparent !important;
}
a {
  text-decoration: none !important;
  color: #000000 !important;
}

.text-center-login {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 35px;
  text-align: center;
  font-size: 40px;
}
.card-login {
  border: 1px solid #eeeeee;
  padding: 15px;
  border-radius: 4px;
  height: 100%;
}
.card-login-but {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
  background-color: #3d405b;
  border-radius: 10px;
  color: #ffffff;
}
.custheading {
  font-size: 20px;
}
.card-login-p {
  background-color: #fff;
  color: #9e8f86;
  padding-bottom: 20px;
  font-size: 13px;
}
.form-control:focus {
  outline: none !important;
  box-shadow: none;
  border-color: #ced4da;
}
.text-right-login {
  text-align: right !important;
  margin-top: 1rem;
  display: block;
  text-decoration: none;
  background-color: transparent;
  color: #000000;
}
.btn:hover {
  color: white !important;
  text-decoration: none !important;
}
.btn-light:hover {
  color: black !important;
  /* background-color: transparent;
  border-color: #f9fafb !important; */
}

.pd-3 {
  padding: 0px 3%;
}

/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
  cursor: pointer;
}
.butn:hover {
  background-color: #ff3772;
} */

.Register {
  width: 50%;
  margin: auto;
  /* padding-top: 2em; */
}

.card-register-but {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
  background-color: #3d405b;
  border-radius: 10px;
  color: #ffffff;
  margin-top: 1em;
}

.breadcrumb-content {
  /* background: #f7f7f7; */
  border-radius: 10px;
  /* border: 1px solid #eeeeee; */
  text-transform: capitalize;
  padding: 0;
  margin: 0px 0px 25px 0px;
}

.breadcrumb-content .breadcrumb {
  margin: 1rem 0px;
  background: transparent;
}

a {
  text-decoration: none !important;
  color: #000000 !important;
}

.text-center-login {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 35px;
  text-align: center;
  font-size: 40px;
}

.card-login {
  border: 1px solid #eeeeee;
  padding: 15px;
  border-radius: 4px;
  height: 100%;
}

.card-login-but {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
  background-color: #3d405b;
  border-radius: 10px;
  color: #ffffff;
}

.custheading {
  font-size: 20px;
}

.card-login-p {
  background-color: #fff;
  color: #9e8f86;
  padding-bottom: 20px;
  font-size: 13px;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none;
  border-color: #ced4da;
}

.text-right-login {
  text-align: right !important;
  margin-top: 1rem;
  display: block;
  text-decoration: none;
  background-color: transparent;
  color: #000000;
}

.pd-3 {
  padding: 0px 3%;
}

/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
  cursor: pointer;
}
.butn:hover {
  background-color: #ff3772;
} */

.gender-input {
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.5 !important;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  /* appearance: none; */
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-icon-change-pass {
  float: right;
  bottom: 32px;
  position: relative;
  right: 9px;
}

.btnicons {
  width: 20px;
}

.birthdate {
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none !important;
}

/* Add by laxmi------------------------------------------------------------------------------------------------- */

@media (max-width: 767.9px) {
  .phnviewmarginlogin {
    margin-top: 15px;
  }
}

@media (max-width: 500px) and (min-width: 410px) {
  .text-center-login {
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
    text-align: center;
    font-size: 32px;
  }
}

@media (max-width: 410px) and (min-width: 350px) {
  .text-center-login {
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
  }

  .card-login-p {
    background-color: #fff;
    color: #9e8f86;
    padding-bottom: 20px;
    font-size: 15px !important;
    text-align: justify;
  }
}

@media (min-width: 350px) and (max-width: 410px) {
  .accountSignbutn {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 128px;
    text-align: center;
    cursor: pointer;
  }

  .forgotpassfont {
    font-size: 14px;
  }

  .card-login-p {
    background-color: #fff;
    color: #9e8f86;
    padding-bottom: 20px;
    font-size: 14px !important;
    text-align: justify;
  }

  .text-center-login {
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
  }
}

@media (min-width: 280px) and (max-width: 350px) {
  .accountSignbutn {
    padding: 7px 0px;
    border-radius: 20px;
    font-size: 12px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px;
    text-align: center;
    cursor: pointer;
  }

  .forgotpassfont {
    font-size: 12px;
  }

  .card-login-p {
    background-color: #fff;
    color: #9e8f86;
    padding-bottom: 20px;
    font-size: 14px !important;
    text-align: justify;
  }

  .text-center-login {
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
  }
}

@media (min-width: 600px) and (max-width: 992px) {
  .Register {
    width: 80%;
    margin: auto;
  }
}

@media (max-width: 600px) {
  .Register {
    width: 100%;
    margin: auto;
  }
}

@media (min-width: 351px) and (max-width: 450px) {
  .forgotpassPehra {
    font-size: 15px !important;
  }
}

@media (max-width: 350px) {
  .forgotpassPehra {
    font-size: 13px !important;
  }

  .forgotpassHeading {
    font-size: 15px !important;
  }

  .signphnforgetpass {
    margin-right: 8px;
  }

  .loginphnforgetpass {
    margin-left: 8px;
  }
}

.card-login-pspacecreateaccount {
  padding-bottom: 10px !important;
}

.Account {
  /* width: 50%;
  margin: auto; */
}

.text-center-account {
  /* font-weight: 700; */
  text-transform: capitalize;
  margin-top: 35px;
  text-align: center;
  font-size: 40px;
}

.Account-headtext {
  margin-bottom: 30px;
}

.Account-headtext h2 {
  font-size: 20px;
}

.Account-headtext p {
  color: #9e8f86;
  font-size: 13px;
  margin-bottom: 8px;
}

.register-but-flex {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.card-Account-but {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
  background-color: #3d405b;
  border-radius: 10px;
  color: #ffffff;
}

.pd-3 {
  padding: 0px 3%;
}

.butn-account {
  padding-right: 0.5rem;
}

.disabled-button-order {
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #cecece;
  color: #ffffff !important;
  width: 140px;
  text-align: center;
  cursor: pointer;
}


@media (max-width: 995px) {
  .card-login-but {
    font-size: 12px;
  }

  .butn-account {
    width: 50%;
    padding-right: 0rem;
  }
}

.account-details-card {
  padding: 5%;
  margin-top: 5%;
  border-radius: 20px;
}

tbody+thead {
  display: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff !important;
  background-color: #c83363;
  border-color: #c83363;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: rgba(0, 0, 0, 0.125);
  /* border-style: ridge; */
  border-width: thin;
}

/* add by laxmi--------------------------------- */
@media (min-width: 354px) and (max-width: 500px) {
  .text-center-account {
    text-transform: capitalize;
    margin-top: 35px;
    text-align: center;
    font-size: 30px;
  }
}

@media (max-width: 354px) {
  .text-center-account {
    display: none;
  }
}
.Address {
  width: 50%;
  margin: auto;
}
.text-center-address {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 35px;
  text-align: center;
  font-size: 40px;
}
.Address-return {
  color: #3d405b;
  font-weight: 600;
}
.but-address {
  padding-bottom: 30px;
}

.Address-Card {
  border: 1px solid #eeeeee;
  border-radius: 20px;
  display: block;
  padding: 15px;
  width: 20%;
  margin-right: 30px;
}

.Address-Card p {
  margin-top: 0;
  margin-bottom: 0.5rem !important;
}

.Address-form {
  border: 1px solid #eeeeee;
  border-radius: 20px;
  display: block;
  padding: 15px;
  width: 100%;
}
.Address-form-h2 {
  margin-bottom: 15px;
  font-size: 18px;
}
.edit-regedit {
  padding: 0px;
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}
.Address-form-edit h2 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 20px;
}
.card-address-but {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
  background-color: #3d405b;
  border-radius: 10px;
  color: #ffffff;
  margin-top: 1em;
  outline: none !important;
  box-shadow: none !important;
}
.pd-country {
  padding: 1rem 0.75rem;
}
.pd-3 {
  padding: 0 3%;
}
.formcheck-address .form-check-input {
  width: 1em !important;
}

/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
}
.butn:hover {
  background-color: #ff3772;
} */
.but-address-flex {
  display: flex;
  align-items: center;
}
.pd-aou-but {
  margin-right: 0.5rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-select:focus {
  border-color: #ced4da !important;
  outline: 0;
  box-shadow: none !important;
}

.spinner-border {
  width: 1.1rem;
  height: 1.1rem;
}

.address-edit-delete .butn {
  padding: 5px 0px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #c83363;
  color: #ffffff;
  width: 102px !important;
  text-align: center;
}

.address-edit-delete .butn:focus-visible {
  outline: #c83363 auto 1px;
}

.edit-address-card {
  border: 1px solid #eeeeee;
  border-radius: 20px;
  display: block;
  padding: 15px;
  width: 100%;
}

/* Add by laxmi */
.butnaddAddressNew{
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff !important;
  width: 170px !important;
  text-align: center;
  cursor: pointer;
}
.addresshowborder {
  border: 1px solid #eeeeee !important;
  border-radius: 20px !important;
  margin-right: -6px !important;
  padding:10px !important;
  margin-bottom: -30px !important;
}
.detail-page-container {
  justify-content: space-between;
  padding: 1em 0em;
  /* width: 86vw; */
  margin: 0 3%;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}

@media (min-width: 992px) {
  .product--medium:not(.product--no-media) .product__media-wrapper,
  .product--small:not(.product--no-media) .product__info-wrapper {
    max-width: 50%;
    width: calc(50% - 0.5rem);
  }

  .product--medium:not(.product--no-media) .product__info-wrapper,
  .product--small:not(.product--no-media) .product__media-wrapper {
    max-width: 50%;
    width: calc(50% - 0.5rem);
    padding-left: 20px;
  }
}

@media screen and (min-width: 768px) {
  .product--medium:not(.product--no-media) .product__info-wrapper,
  .product--small:not(.product--no-media) .product__media-wrapper {
    max-width: 50%;
    width: calc(50% - 0.5rem);
    padding-left: 20px;
  }

  .grid--2-col-tablet .grid__item {
    width: calc(50% - 0.5rem);
  }

  .grid--2-col-tablet .grid__item {
    width: calc(50% - 0.5rem);
  }

  .product__info-container > *:first-child {
    margin-top: 0;
  }

  .price--large {
    font-size: 1.8rem;
  }

  .price {
    margin-bottom: 0;
  }

  .product__info-container .price--on-sale .price-item--regular {
    font-size: calc(14 * 1.85px);
  }
}

.product__info-container .product__text {
  margin-bottom: 10px;
  text-transform: capitalize;
}

.product__text {
  margin-bottom: 0;
}

p {
  color: rgba(0, 0, 0, 0.6);
}

.product__info-container .product__title {
  font-size: calc(14 * 2px);
  margin-bottom: 10px;
  text-transform: capitalize;
}

.product__title {
  word-break: break-word;
}

.product__info-container .spr-starrating,
.mainp-price,
.product__info-container .product-form__input,
.product__info-container .product__description,
.product__info-container .product-form,
.product__info-container .share-button,
.product__info-container .product-popup-modal__opener {
  margin-bottom: 15px;
  justify-content: start !important;
}

.product__info-container .price {
  align-items: center;
  display: flex;
}

.price--large {
  font-size: 1.6rem;
  letter-spacing: 0.13rem;
}

.visually-hidden {
  display: none;
}

.product__info-container .price .price-item {
  font-size: calc(14 * 2.1px);
}

.price .price-item {
  margin: 0 3px 0 0;
  font-size: calc(14 * 1.43px);
  font-weight: 500;
  letter-spacing: 0;
  display: inline-block;
}

.price--on-sale .price-item--regular {
  text-decoration: line-through;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.6);
  font-size: calc(14 * 1.15px);
  display: inline-block;
}

.product__info-wrapper .product-form__input {
  flex: 0 0 100%;
  padding: 0;
  margin: 0 0 1.2rem;
  max-width: 37rem;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  border: none;
}

.product-form__input .form__label {
  padding-left: 0;
}

.form__label {
  display: block;
  margin-bottom: 0.6rem;
}

.product__info-container .product-form__input .quantity {
  width: 120px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
}

.quantity {
  position: relative;
  height: 45px;
  display: flex;
}

.product__info-container .product-form__input .quantity__button {
  width: 40px;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.quantity__button {
  flex-shrink: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

button,
input {
  overflow: visible;
}

.product__info-container .product-form__input .quantity__input {
  width: 40px;
  font-size: calc(14 * 1.29px);
}

.quantity__input {
  color: currentColor;
  font-weight: 500;
  opacity: 0.85;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 0 0.5rem;
  width: 100%;
  flex-grow: 1;
  -webkit-appearance: none;
  appearance: none;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.product__info-container .product-form__buttons .btn-primary {
  padding: 8px 10px;
  min-height: 45px;
  font-size: calc(var(--font-size-base) * 1.15px);
}

.product__info-container .product-form__submit {
  margin-bottom: 1rem;
}

.btn-primary,
.button,
.shopify-challenge__button,
.customer button {
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  line-height: 1.25;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 10px;
  background: #3d405b;
  color: #ffffff;
  transition: 0.3s ease;
  -webkit-appearance: none;
          appearance: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
}

.smallImagesScroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 530px;
  overflow-y: scroll;
}

.thumbnail-img img {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 0.3rem;
}

.product__description tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: white;
  border-width: thin;
}

.smallImagesScroll ::-webkit-scrollbar {
  display: none;
}

/* change by laxmi */
.detaildescriptionAlign {
  text-align: justify;
}

.large-img {
  overflow: hidden;
  border-radius: 5px;
}

@media (min-width: 400px) and (max-width: 550px) {
  .spaced-section {
    margin-top: 14px !important;
    overflow-x: hidden;
  }

  .headingDeatilsitemsproduct-page {
    font-size: calc(28 * 1px) !important;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 30px;
  }

  .customer-reviews-Description {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(101, 119, 134, 0.12);
    padding: 25px 0px !important;
  }

  .product-reviews {
    display: flex;
    padding: 10px !important;
    border-bottom: 1px solid rgba(101, 119, 134, 0.22);
  }

  .smallImagesScroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 370px !important;
    overflow-y: scroll;
  }

  .detailpagepehraphnview {
    padding-left: 0% !important;
  }

  .deatilpagebtnphnview {
    padding: 10px 0px !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px !important;
    text-align: center;
    cursor: pointer;
  }

  .writereviewphnview {
    padding: 10px 0px !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px !important;
    text-align: center;
    cursor: pointer;
    margin-left: 25px;
    text-transform: capitalize;
  }

  .ratings-exact {
    font-size: 22px !important;
    line-height: 40px;
    letter-spacing: -0.3px;
  }

  .ratings-upto {
    font-size: 22px !important;
    line-height: 40px;
  }

  .ratings-static {
    font-size: 14px !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: rgba(0, 19, 37, 0.92);
    display: block;
    margin-top: 0.5rem;
  }

  .overall-ratings {
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem 10px !important;
    border-right: 2px solid rgba(101, 119, 134, 0.22) !important;
  }
}

@media (min-width: 280px) and (max-width: 500px) {
  .spaced-section {
    margin-top: 14px !important;
    overflow-x: hidden;
  }

  .product__info-container .product__title {
    font-size: calc(10 * 2px) !important;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  .product__info-container .price .price-item {
    font-size: calc(9 * 2.1px) !important;
  }
}

@media (min-width: 280px) and (max-width: 400px) {
  .headingDeatilsitemsproduct-page {
    font-size: calc(28 * 1px) !important;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 30px;
  }

  .customer-reviews-Description {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(101, 119, 134, 0.12);
    padding: 25px 0px !important;
  }

  .ratings-exact {
    font-size: 22px !important;
    line-height: 40px;
    letter-spacing: -0.3px;
  }

  .ratings-upto {
    font-size: 22px !important;
    line-height: 40px;
  }

  .writereviewphnview {
    padding: 10px 0px !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px !important;
    text-align: center;
    cursor: pointer;
    margin-left: 25px;
    text-transform: capitalize;
  }

  .deatilpagebtnphnview {
    padding: 10px 0px !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px !important;
    text-align: center;
    cursor: pointer;
  }

  .smallImagesScroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 260px !important;
    overflow-y: scroll;
  }

  .detailpagepehraphnview {
    padding-left: 0% !important;
  }

  .product-reviews {
    display: flex;
    padding: 6px 0px !important;
    border-bottom: 1px solid rgba(101, 119, 134, 0.22);
  }

  .ratings-static {
    font-size: 13px !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: rgba(0, 19, 37, 0.92);
    display: block;
    margin-top: 0.5rem;
  }

  .verified-upto {
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0px;
    color: rgba(0, 19, 37, 0.72);
  }

  .overall-ratings {
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem 5px !important;
    border-right: 2px solid rgba(101, 119, 134, 0.22) !important;
  }

  .css-tm4hnq {
    font-size: 14px !important;
    line-height: 10px !important;
    letter-spacing: -0.1px;
    color: rgba(0, 19, 37, 0.92);
    padding-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .css-1n0nrdk {
    font-size: 12px !important;
    line-height: 1px !important;
    letter-spacing: 0px;
    color: rgba(0, 19, 37, 0.64);
  }

  .css-1at2wjx {
    background-color: rgb(0, 137, 69);
    font-size: 13px !important;
    font-weight: 600;
    line-height: 20px !important;
    letter-spacing: -0.1px;
    display: inline-flex;
    align-items: center;
    color: white;
    border-radius: 4px;
    padding: 3px 5px;
  }

  .css-177e1lt {
    font-size: 15px !important;
    line-height: 16px;
    letter-spacing: 0px;
    float: right;
    color: rgba(0, 19, 37, 0.52);
    margin-top: 0.25rem;
  }

  .customer-reviews-Description {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(101, 119, 134, 0.12);
    padding: 25px 0px !important;
  }
}

@media (max-width: 767px) {
  .product-reviews {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid rgba(101, 119, 134, 0.22);
  }
}

@media (min-width: 550px) and (max-width: 879px) {
  .overall-ratings {
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem 34px 0px 0px !important;
    border-right: 2px solid rgba(101, 119, 134, 0.22);
  }

  .customer-reviews-Description {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(101, 119, 134, 0.12);
    padding: 25px 0px !important;
  }
}

@media (max-width: 320px) {
  .butnYoumaylike {
    padding: 8px 0px !important;
    border-radius: 20px;
    font-size: 13px !important;
    background-color: #c83363;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
}

.flosil-product-reviews {
  padding: 15px 0 0px;
}

.product-reviews {
  display: flex;
  padding: 2rem 2rem 1.5rem 1rem;
  border-bottom: 1px solid rgba(101, 119, 134, 0.22);
}

.overall-ratings {
  display: flex;
  background-color: rgb(255, 255, 255);
  padding: 0.5rem 3.75rem;
  border-right: 2px solid rgba(101, 119, 134, 0.22);
}

.ratings {
  font-size: 36px;

  letter-spacing: -0.3px;
  line-height: 50px;
  margin-right: 0.5rem;
}

.ratings-exact {
  font-size: 36px;

  line-height: 40px;
  letter-spacing: -0.3px;
}

.ratings-upto {
  font-size: 36px;

  line-height: 40px;
}

.ratings-static {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: rgba(0, 19, 37, 0.92);
  display: block;
  margin-top: 0.5rem;
}

.ratings-verified {
  line-height: 16px;
  flex: 2 1;
}

.verified-upto {
  font-size: 13px;

  line-height: 20px;
  letter-spacing: 0px;
  color: rgba(0, 19, 37, 0.72);
}

.writereview {
  font-size: 14px;

  line-height: 20px;
  letter-spacing: 0px;
  font-stretch: normal;
  font-style: normal;
  color: rgba(0, 19, 37, 0.72);
  padding-bottom: 0.5rem;
}

.customer-reviews {
  padding: 25px 75px;
}

.customer-reviews-Description {
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(101, 119, 134, 0.12);
  padding: 25px 55px;
}

.userInfoSection {
  display: flex;
  padding: 0px 0px 16px;
}

.userImageIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  vertical-align: top;
  display: inline-block;
}

.verified-buyers-icon {
  font-size: 14px;

  line-height: 16px;
  letter-spacing: 0px;
  color: rgba(0, 19, 37, 0.52);
}

.userNameReviews {
  font-size: 17px;

  line-height: 20px;
  letter-spacing: -0.1px;
  display: block;
  color: rgba(0, 19, 37, 0.72);
  margin: 0.25rem 0px;
}

.UserInformationProductReviews {
  display: inline-block;
  margin-left: 10px;
  width: 165px;
  word-break: break-word;
}

.star-ratings {
  display: flex;
  justify-content: space-between;
  margin-right: 0.5rem;
}

.css-rwwnk2 {
  background-color: white;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  padding: 0.5rem 0rem;
}

.css-1at2wjx {
  background-color: rgb(0, 137, 69);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1px;
  display: inline-flex;
  align-items: center;
  color: white;
  border-radius: 4px;
  padding: 3px 5px;
}

.css-1at2way {
  background-color: #c30f0f;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1px;
  display: inline-flex;
  align-items: center;
  color: white;
  border-radius: 4px;
  padding: 3px 5px;
}

.css-w0v4um {
  display: inherit;
  margin-left: 0.25rem;
}

.css-177e1lt {
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0px;
  float: right;
  color: rgba(0, 19, 37, 0.52);
  margin-top: 0.25rem;
}

.css-tm4hnq {
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: rgba(0, 19, 37, 0.92);
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.css-1n0nrdk {
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0px;
  color: rgba(0, 19, 37, 0.64);
}

.css-1v6g5ho {
  padding: 0px 0rem;
}

.css-1rjb1jp {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1px;
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.css-1xv8iu0 {
  padding: 1rem 0px;
  display: block;
  color: rgba(0, 19, 37, 0.92);
  text-decoration: none;
  cursor: pointer;
}

.css-1a3bom8 {
  margin-right: 0.5rem;
  color: #3d405b;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.productForReview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-container {
  width: 768px;
  padding-top: 0px !important;
  padding: 40px;
}

.create-review {
  text-align: left;
  padding-bottom: 25px;
  padding-top: 25px;
}

.product-reviewImage {
  text-align: left;
  width: 30%;
}

.variantNameReview {
  text-align: left;
  width: 70%;
}

.over-all-rating {
  display: flex;
  justify-content: space-between;
}

.container-clear {
  text-align: right;
  cursor: pointer;
  color: #3d405b;
}

.headline {
  margin-top: 10px;
}

.submit-review-button {
  width: 30%;
  background: #3d405b;
  color: #fff;
}

.submit-review {
  text-align: right;
}

.star-review input[type="radio"] {
  display: none;
}

.star {
  cursor: pointer;
  transition: color 200ms;
}

.all-review-scroll {
  height: 700px;
  overflow-x: scroll;
}

.all-review-scroll::-webkit-scrollbar {
  display: none;
}

.flosil-product-reviews-all {
  padding: 0px 0 0px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .flosil-product-reviews-all {
    padding: 100px 0 0px;
  }

  .customer-reviews-Description {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(101, 119, 134, 0.12);
    padding: 25px 0px;
  }

  .overall-ratings {
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem 0.5rem;
    border-right: none;
  }
}

.rating-spinner {
  text-align: center;
  padding: 60px 0px;
  color: #0f3d74;
}

.btn-primary,
.button {
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  line-height: 1.25;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 10px;
  background: #3d405b;
  color: #ffffff;
  transition: 0.3s ease;
  -webkit-appearance: none;
  appearance: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
}

/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
}
.butn:hover {
  background-color: #ff3772;
} */
.PA-BO-RV {
  /* margin-bottom: 2rem; */
}

.inner-banner .main-banner img {
  width: 100%;
}

/* add by laxmi--------------------------- */

@media (max-width: 450px) {
  .box-container {
    width: 768px;
    padding-top: 0px !important;
    padding: 15px !important;
  }

  .product-reviewImage {
    text-align: left;
    width: 50% !important;
  }
}

.spaced-section {
  margin-top: 60px;
  overflow-x: hidden;
}
.heading {
  font-size: calc(40 * 1px);
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 30px;
}

.rless {
  margin-left: -15px;
  margin-right: -15px;
}

.cless {
  padding-left: 15px;
  padding-right: 15px;
}

/* .grid__item {
    flex-grow: 1;
    flex-shrink: 0;
} */

.twopro .wbproduct-container,
.product-recommendations .wbproduct-container {
  margin-bottom: 30px;
}

.related_products .grid__item {
  margin-bottom: 30px;
}
/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
}
.butn:hover {
  background-color: #ff3772;
} */

@media (max-width: 768px) {
  .cless {
    padding-left: 10px;
    padding-right: 10px;
  }
}

p {
  color: rgba(0, 0, 0, 0.6);
}

.cart-page-container {
  justify-content: space-between;
  padding: 1em 0em;
  /* width: 86vw; */
  margin: 0 3%;
}

.carthead {
  display: flex;
  justify-content: space-between;
}

.heading {
  font-size: calc(40 * 1px);
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 30px;
}

.carthead a.underlined-link {
  text-decoration: underline;
  color: #3d405b;
  font-size: calc(14 * 1.15px);
}

@media screen and (min-width: 768px) {
  .cart-items {
    border-spacing: 0;
    border-collapse: separate;
    box-shadow: none;
    width: 100%;
    display: table;
  }

  .medium-hide {
    display: none !important;
  }

  .price {
    margin-bottom: 0;
  }

  .cart-items thead th:first-child {
    width: 50%;
  }

  .cart-items th {
    border-bottom: 1px solid #eeeeee;
  }

  .cart-items th + th,
  .cart-item > td + td {
    padding-left: 15px;
  }

  .cart-item__media {
    width: 10rem;
  }

  .cart-item__media {
    position: relative;
  }

  .cart-item__details {
    width: 35rem;
  }

  .cart-item .loading-overlay {
    right: 0;
    padding-top: 4.5rem;
    bottom: auto;
  }

  .cart__footer {
    display: flex;
    justify-content: space-between;
  }

  .cart__footer > * {
    width: 35rem;
  }
}

@media screen and (min-width: 992px) {
  .cart-items thead th:first-child {
    width: 60%;
  }

  .cart-item__details {
    width: 50rem;
  }

  .large-up-hide {
    display: none !important;
  }

  .cart-item .cart-item__quantity,
  .cart-items .cart-items__heading--wide {
    padding-left: 15px;
  }
}

.cart-items thead th {
  text-transform: capitalize;
}

.cart-items th {
  padding-bottom: 10px;
  opacity: 0.85;
  font-weight: 400;
}

.cart-items td,
.cart-items th {
  padding: 0;
  border: none;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

.cart-item {
  display: table-row;
}

/* .cart-items td {
  padding-top: 4px !important;
} */

.cart-item__media img {
  border-radius: 4px;
  border: 1px solid #eeeeee;
}

.cart-item__name {
  display: block;
  font-size: calc(14 * 1.15px);
  text-transform: capitalize;
  font-weight: 700 !important;
}

.cart-item__details > * {
  margin: 0;
  max-width: 30rem;
}

.break {
  word-break: break-word;
}

.cart-item__details > * + * {
  margin-top: 8px;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  word-wrap: normal !important;
}

.cart-item__final-price {
  font-weight: 400;
  font-size: 1.1rem;
}

b,
strong {
  font-weight: bolder;
}

.cart-item__old-price {
  opacity: 0.7;
}

.discounts {
  font-size: calc(var(--font-size-base) * 1.15px);
}

.cart-item__name ~ .product-option {
  font-weight: 700;
  letter-spacing: 0;
}

.discounts {
  font-size: calc(14 * 1.15px);
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.discounts__discount {
  display: flex;
  align-items: center;
}

.discounts {
  font-size: calc(14 * 1.15px);
}

.discounts__discount > .icon {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.7rem;
}

/* svg {
    width: 14px;
    height: 14px;
    transition: .3s ease;
    vertical-align: middle;
} */
svg {
  overflow: hidden;
  vertical-align: middle;
}

.cart-item__totals {
  position: relative;
}

.right {
  text-align: right;
}

.cart-item__quantity-wrapper {
  display: flex;
}

.cart-item__quantity-wrapper quantity-input {
  border: 1px solid #eeeeee;
  position: relative;
  width: 120px;
  border-radius: 4px;
}

.quantity {
  position: relative;
  height: 30px;
  display: flex;
}

.cart-item cart-remove-button {
  display: inline-block;
  margin-left: 10px;
  align-items: center;
  display: flex;
}

a {
  color: rgb(0, 0, 0);
  transition: 0.3s ease;
}

.cart-item .loading-overlay {
  top: 0;
  left: auto;
  right: auto;
  bottom: 0;
  padding: 0;
}

.loading-overlay {
  position: absolute;
  z-index: 1;
  width: 1.8rem;
}

/* .hidden {
    display: none!important;
} */
.loading-overlay__spinner {
  width: 14px;
  display: inline-block;
}

.spinner {
  animation: rotator 1.4s linear infinite;
}

.cart-item__price-wrapper {
  font-weight: 500;
  font-size: calc(14 * 1.15px);
}

.cart-item__price-wrapper > * {
  display: block;
  margin: 0;
  padding: 0;
}

.cart-item__discounted-prices dd {
  margin: 0;
}

.price--end {
  justify-content: flex-end;
  font-weight: 700;
}

.cart-item__discounted-prices dd {
  margin: 0;
}

.cart-item__quantity-wrapper .quantity__input,
.cart-item__quantity-wrapper .quantity__button {
  width: 40px;
}

.quantity__input {
  color: currentColor;
  font-weight: 500;
  opacity: 0.85;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 0 0.5rem;
  width: 100%;
  flex-grow: 1;
  -webkit-appearance: none;
  appearance: none;
}

.cart__footer {
  text-align: right;
}

.cart__footer {
  border-top: 1px solid #eeeeee;
  margin-top: 30px;
}

.cart__footer > div:only-child {
  margin-left: auto;
}

.totals {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 20px;
}

.totals__subtotal {
  font-size: calc(14 * 1.15px);
  font-weight: 700;
}

.totals * {
  line-height: 1;
}

.totals > * {
  margin: 0;
}

.totals__subtotal-value {
  font-size: calc(14 * 1.57px) !important;
  color: rgb(0, 0, 0);
  font-weight: 700;
}

.totals > * + * {
  margin-left: 15px;
}

.tax-note {
  display: block;
  font-size: calc(14 * 1px);
  margin-top: 15px;
  margin-bottom: 15px;
}

.small,
small {
  font-size: 80%;
  font-weight: 700;
}

.cart__ctas {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.cart__ctas button {
  width: 100%;
}

.butn2 a {
  color: #fff !important;
}

.cart__checkout-button {
  max-width: 20rem;
}

.btn-primary,
.button,
.shopify-challenge__button,
.customer button {
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  line-height: 1.25;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 10px;
  background: #3d405b;
  color: #ffffff;
  transition: 0.3s ease;
  -webkit-appearance: none;
          appearance: none;
  font-size: rgb(0, 0, 0);
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
}
.cart-footer {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .cart-items td.cart-item__quantity {
    padding-top: 0;
    margin-top: -14px;
  }

  .cart-item {
    display: grid;
    grid-template: repeat(2, auto) / repeat(4, 1fr);
    grid-gap: 15px;
    gap: 15px;
    padding: 4px;
  }

  .cart-item__media {
    grid-row: 1 / 3;
  }

  .cart-item__details {
    grid-column: 2 / 4;
  }

  .cart-item__totals {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .cart-items td.cart-item__quantity {
    padding-top: 0;
    margin-top: -14px;
  }

  .cart-item__quantity {
    grid-column: 2 / 5;
  }

  .cart-item__quantity-wrapper {
    flex-wrap: wrap;
  }

  .cart-items thead tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
  }

  .small-hide {
    display: none !important;
  }

  .cart-item__price-wrapper {
    font-size: calc(var(--font-size-base) * 1px);
    letter-spacing: 0;
  }
}

.cart-remove-button .icon-remove {
  height: 20px !important;
  width: 20px !important;
}

/* add by laxmi-------------------------- */

@media (max-width: 500px) {
  .headingCartPage {
    font-size: calc(25 * 1px) !important;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 30px;
  }
}

@media (max-width: 388px) {
  .cart-item__name {
    display: block;
    font-size: calc(12 * 1.15px) !important;
    text-transform: capitalize;
    font-weight: 700 !important;
  }

  .cart-item__final-price {
    font-weight: 400;
    font-size: 13px !important;
  }

  .quantity {
    position: relative;
    height: 35px !important;
    display: flex;
  }
}

h2 {
  font-size: 18px;
  font-weight: 600;
}

.checkout-page-container {
  justify-content: space-between;
  padding: 1em 0em;
  /* width: 86vw; */
  margin: 0px 3%;
}

.anyflexbox body,
.anyflexbox .content,
.anyflexbox .content .wrap,
.anyflexbox .main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.content {
  overflow: hidden;
}

.anyflexbox .content .wrap {
  flex-direction: column-reverse;
}

.wrap {
  display: block;
  /* margin: 0 auto; */
  /* max-width: 45em; */
  zoom: 1;
  margin: 0 3%;
}

/* .main .logo {
    display: none;
} */

.logo {
  display: block;
  vertical-align: middle;
}

a,
.link {
  text-decoration: none;
  color: #3d405b;
  transition: color 0.2s ease-in-out;
}

.logo__image--medium {
  max-height: 2.8571428571em;
}

.logo__image {
  display: inline-block;
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff !important;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.anyflexbox .breadcrumb__item {
  display: flex;
  align-items: center;
}

.breadcrumb__item {
  display: inline;
  font-size: 0.8571428571em;
  color: #737373;
}

.breadcrumb__item--current {
  font-weight: 500;
  color: #333333;
}

.main .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor;
}

.breadcrumb__chevron-icon {
  margin: 0 0.6428571429em;
}

.icon-svg--size-10 {
  width: 10px;
  height: 10px;
}

.icon-svg {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
}

.sidebar {
  position: relative;
  color: #535353;
}

.sidebar__header {
  margin-bottom: 2.5em;
  display: none;
}

@media (min-width: 1000px) {
  .anyflexbox .content .wrap {
    flex-direction: row;
  }

  .wrap {
    max-width: 85.571429em;
  }

  .page--no-banner .main,
  .page--no-banner .sidebar {
    padding-top: 4em;
  }

  .main {
    width: 52%;
    width: 52%;
    padding-right: 6%;
    float: left;
  }

  .main__header {
    padding-bottom: 2em;
  }

  .page--logo-main .main .logo {
    display: block;
  }

  .logo__image--medium {
    max-height: 4.2857142857em;
  }

  .page--logo-main .breadcrumb {
    margin-top: 1em;
  }

  .page--no-banner .main,
  .page--no-banner .sidebar {
    padding-top: 4em;
  }

  .sidebar {
    width: 47%;
    padding-left: 4%;
    background-position: left top;
    float: right;
    padding-left: 4%;
    padding-top: 4%;
  }

  .order-summary__section:first-child {
    padding-top: 0;
  }

  .order-summary__sections {
    display: flex;
    flex-direction: column;
    /* height: calc(100vh - 20em); */
    width: 100%;
  }

  .order-summary__section--product-list {
    display: flex;
    flex: 0 1 auto;
    min-height: 7em;
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  .order-summary__section {
    flex: 0 0 auto;
  }

  .no-js .order-summary__section__content,
  .no-touchevents .order-summary__section__content {
    overflow: hidden;
  }

  .order-summary__section__content {
    min-width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .order-summary__section--product-list .product-table {
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    position: relative;
    z-index: 1;
  }
}

@media (min-width: 750px) {
  .main {
    padding-top: 1.5em;
  }

  h1,
  .heading-1 {
    font-size: 2em;
  }

  .main {
    padding-top: 1.5em;
  }

  .main__content {
    padding-bottom: 4em;
  }

  .section {
    padding-top: 3em;
  }

  .section__header {
    margin-bottom: 1.5em;
  }

  .section__header {
    margin-bottom: 1.5em;
  }

  .floating-labels .field--half {
    width: 50%;
  }

  .anyflexbox .step__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .step__footer {
    margin-top: 1.5em;
  }

  .step__footer__continue-btn {
    float: left;
  }

  .step__footer__previous-link,
  .step__footer__info {
    float: right;
  }

  .anyflexbox .step__footer__previous-link,
  .anyflexbox .step__footer__info {
    margin-left: 1.5em;
  }

  .order-summary__section--product-list+.order-summary__section {
    border: 0;
  }

  .section {
    padding-top: 3em;
  }
}

.section__header {
  position: relative;
  margin-bottom: 1em;
}

form {
  margin: 0;
}

.anyflexbox .main__content {
  flex: 1 0 auto;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  /* -webkit-appearance: none; */
  -webkit-appearance: auto;
          appearance: auto;
  -webkit-font-smoothing: inherit;
  border: none;
  background: transparent;
  line-height: normal;
}

.section:first-child {
  padding-top: 0;
}

.layout-flex--loose-horizontal {
  margin-left: -0.5714285714rem;
  margin-right: -0.5714285714rem;
}

.layout-flex--tight-vertical {
  margin-bottom: -0.1428571429rem;
  margin-top: -0.1428571429rem;
}

.layout-flex--wrap {
  flex-wrap: wrap;
}

.layout-flex {
  align-items: baseline;
  display: flex;
  margin: -0.2857142857rem;
}

.layout-flex--loose-horizontal .layout-flex__item {
  padding-left: 0.5714285714rem;
  padding-right: 0.5714285714rem;
}

.layout-flex--tight-vertical .layout-flex__item {
  padding-bottom: 0.1428571429rem;
  padding-top: 0.1428571429rem;
}

.layout-flex--loose-horizontal .layout-flex__item {
  padding-left: 0.5714285714rem;
  padding-right: 0.5714285714rem;
}

.layout-flex--tight-vertical .layout-flex__item {
  padding-bottom: 0.1428571429rem;
  padding-top: 0.1428571429rem;
}

.section {
  position: relative;
  padding-top: 2em;
}

.main h2,
.main .heading-2 {
  color: #333333;
}

.section__content {
  zoom: 1;
}

.field {
  width: 100%;
  float: left;
  padding: 0.4285714286em;
  box-sizing: border-box;
}

.field__input {
  border: 1px transparent solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0.7em 0.7857142857em;
  word-break: normal;
  line-height: inherit;
}

.main .field__input {
  background-color: white;
  color: #333333;
  border-color: #d9d9d9;
}

.field__input-wrapper {
  position: relative;
}

.floating-labels .main .field__label {
  color: #737373;
}

.animate-floating-labels .field__label {
  transition: all 0.2s ease-out;
}

.floating-labels .field__label {
  font-size: 0.8571428571em;
  font-weight: normal;
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 0.4285714286em;
  margin-left: 1px;
  padding: 0 0.9166666667em;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  transform: translateY(3px);
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  opacity: 0;
}

.field__label {
  text-align: left;
  font-weight: 500;
  margin: 0.5em 0;
  display: none;
}

.field__label--visible {
  display: block;
}

.animate-floating-labels .field__input {
  transition: all 0.2s ease-out;
}

.step__footer {
  z-index: 2;
  /* position: relative; */
  margin-top: 2em;
}

.step__footer__previous-link,
.step__footer__info {
  display: block;
}

.btn-light:focus {}

/* .btn,
.sp-modal-toggle {
  cursor: pointer;
  display: inline-block;
  background-color: #3d405b;
  background-clip: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px transparent solid;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  padding: 1.4em 1.7em;
  text-align: center;
  position: relative;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
} */

.main__footer {
  padding: 1em 0 0 0;
  border-top: 1px solid #e6e6e6;
  margin-top: 3em;
  align-items: center;
}

.main__footer-shipping {
  padding: 1em 0 1rem 0;
  border-top: 1px solid #e6e6e6;
  margin-top: 3em;
  align-items: center;
}

.copyright-text {
  font-size: 0.8571428571em;
  color: #737373;
  text-align: center;
  margin: 0;
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

.js .visually-hidden-if-js {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

.sidebar h2,
.sidebar .heading-2 {
  color: #323232;
}

.order-summary__section {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
}

.product-table__header th {
  white-space: nowrap;
  font-weight: 500;
  color: #333333;
}

.product:first-child td {
  padding-top: 0;
}

.product td {
  padding-top: 1em;
}

.product-table td {
  border-color: #fff;
}

td:first-child,
th:first-child {
  padding-left: 0;
  text-align: left;
}

.product-table th,
.product-table td {
  padding-top: 0;
  padding-bottom: 0;
}

.product-thumbnail {
  width: 4.6em;
  height: 4.6em;
  border-radius: 8px;
  background: #fff;
  position: relative;
  border: 1px solid rgba(175, 175, 175, 0.34);
}

.product-thumbnail__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  z-index: 1;
}

.product-thumbnail__quantity {
  font-size: 0.8571428571em;
  font-weight: 500;
  line-height: 1.75em;
  white-space: nowrap;
  text-align: center;
  border-radius: 1.75em;
  background-color: rgba(114, 114, 114, 0.9);
  color: #fff;
  box-sizing: border-box;
  min-width: 1.75em;
  height: 1.75em;
  padding: 0 0.5833333333em;
  position: absolute;
  right: -0.75em;
  top: -0.75em;
  z-index: 3;
}

.product-thumbnail__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.product__description {
  text-align: left;
  width: 50%;
}

.sidebar .order-summary__emphasis {
  color: #323232;
}

.product__description__name,
.product__description__variant,
.product__description__property {
  display: block;
}

.product__description__name {
  width: 1em;
  min-width: 100%;
}

.order-summary__emphasis {
  font-weight: 500;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
}

.sidebar .order-summary__small-text {
  color: #717171;
}

/* .order-summary__small-text {
  font-size: 0.8571428571em;
} */
.product__description .reduction-code {
  margin-top: 0.2857142857em;
}

.sidebar .icon-svg--color-adaptive-light {
  color: #717171;
  fill: currentColor;
}

.reduction-code__icon {
  margin-right: 0.1428571429em;
  vertical-align: top;
}

.icon-svg--size-16 {
  width: 16px;
  height: 16px;
}

.icon-svg {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
}

td,
th {
  padding-left: 1em;
}

.product__description .reduction-code__text {
  color: #717171;
}

.sidebar .reduction-code__text {
  color: #323232;
}

.reduction-code__text {
  font-size: 1em;
}

.order-summary__section~.order-summary__section {
  border-top: 1px solid rgba(175, 175, 175, 0.34);
  /* border-bottom: 1px solid rgba(175, 175, 175, 0.34); */
}

.order-summary__section {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}

.total-line:first-child th,
.total-line:first-child td {
  padding-top: 0;
}

.total-line th,
.total-line td {
  padding-top: 0.75em;
}

td:first-child,
th:first-child {
  padding-left: 0;
  text-align: left;
}

.total-line:first-child th,
.total-line:first-child td {
  padding-top: 0;
}

.total-line th,
.total-line td {
  padding-top: 0.75em;
}

td:last-child,
th:last-child {
  text-align: right;
}

.total-line__price {
  padding-left: 1.5em;
  text-align: right;
  white-space: nowrap;
}

.total-line-table__tbody+.total-line-table__tbody .total-line:first-child th,
.total-line-table__tbody+.total-line-table__tbody .total-line:first-child td,
.total-line-table__tbody+.total-line-table__footer .total-line:first-child th,
.total-line-table__tbody+.total-line-table__footer .total-line:first-child td {
  padding-top: 1.5em;
  position: relative;
}

table {
  width: 100%;
}

.layout-flex__item--stretch {
  flex: 1 1 auto;
}

.total-line__name_plain {
  padding-top: 1.75em !important;
}

.total-line__price_plain {
  padding-top: 1.75em !important;
}

a,
.link {
  text-decoration: none !important;
  color: #3d405b !important;
  transition: color 0.2s ease-in-out !important;
}

.section:first-child {
  padding-top: 0;
}

.content-box:first-of-type,
.content-box-spacing:first-of-type {
  margin-top: 0;
}

.main .content-box {
  /* border-color: #d9d9d9; */
  border-color: #eeeeee;
}

.content-box {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}

.content-box__row:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.content-box__row:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.display-table .content-box__row {
  display: table;
  box-sizing: border-box;
  width: 100%;
}

.content-box__row--tight-spacing-vertical {
  padding-top: 0.8571428571em;
  padding-bottom: 0.8571428571em;
}

.review-block {
  display: flex;
}

.review-block__inner {
  display: flex;
  flex: 1 1;
}

.review-block__label {
  color: #737373;
  padding-right: 1.1428571429em;
  flex: 0 1 5em;
}

.review-block__content {
  flex: 5 1;
  color: #333333;
  padding-right: 1.1428571429em;
}

bdo {
  unicode-bidi: bidi-override;
}

.review-block__link {
  max-width: 10em;
  font-size: 0.8571428571em;
}

.review-block~.review-block {
  margin-top: 0.8571428571em;
  padding-top: 0.8571428571em;
  border-top: 1px solid #e6e6e6;
}

.radio-wrapper:last-child,
.checkbox-wrapper:last-child {
  margin-bottom: 0;
}

.display-table .radio-wrapper,
.display-table .checkbox-wrapper {
  display: table;
  box-sizing: border-box;
  width: 100%;
}

.radio-wrapper,
.checkbox-wrapper {
  zoom: 1;
  margin-bottom: 1em;
  display: flex;
}

.display-table .radio__input,
.display-table .checkbox__input {
  display: table-cell;
}

address {
  margin-bottom: 0 !important;
  font-style: normal;
  line-height: inherit;
}

.radio__input,
.checkbox__input {
  padding-right: 0.75em;
  white-space: nowrap;
}

.input-radio:checked {
  border-width: 7px;
}

.input-checkbox:checked,
.input-radio:checked {
  border-color: #3d405b;
}

.input-checkbox:checked,
.input-radio:checked {
  border-color: rgb(61, 64, 91);
}

.content-box .input-checkbox,
.content-box .input-radio {
  border-color: #d9d9d9;
  background-color: white;
}

.main .input-checkbox,
.main .input-radio {
  border-color: #d9d9d9;
  background-color: white;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.input-radio {
  border-radius: 50%;
}

.input-radio:checked {
  border-width: 7px;
}

.main .input-checkbox,
.main .input-radio {
  border-color: #d9d9d9;
  background-color: white;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.input-radio {
  border-radius: 50%;
}

.input-checkbox,
.input-radio {
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  vertical-align: -4px;
  border: 1px solid;
}

.display-table .radio__label,
.display-table .checkbox__label {
  display: table-cell;
  width: 100%;
}

.radio__label,
.checkbox__label {
  cursor: pointer;
  vertical-align: middle;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
}

.display-table .radio__label__primary {
  display: table-cell;
  width: 100%;
}

.radio__label__primary {
  cursor: inherit;
  font-family: inherit;
  vertical-align: top;
}

.display-table .radio__label__accessory {
  display: table-cell;
}

.radio__label__accessory {
  text-align: right;
  padding-left: 0.75em;
  white-space: nowrap;
}

.radio__label,
.checkbox__label {
  cursor: pointer;
  vertical-align: middle;
}

@media (max-width: 999px) {
  .wrap {
    width: 94%;
    box-sizing: border-box;
    padding: 0 1em;
  }

  .continuetopayment-tab {
    display: block;
  }

  .continuetopayment-desktop {
    display: none;
  }

  .content-box-tab {
    margin-top: 15px;
  }
}

@media (min-width: 999px) {
  .continuetopayment-tab {
    display: none;
  }

  .continuetopayment-desktop {
    display: block;
  }
}

.section__text {
  font-weight: 600;
  font-size: 15px;
}

.radio-wrapper.content-box__row,
.checkbox-wrapper.content-box__row {
  margin-bottom: 0;
}

.content-box__row~.content-box__row {
  border-top: 1px solid #d9d9d9;
}

.content-box__row {
  padding: 1.1428571429em;
  position: relative;
  zoom: 1;
}

.input-checkbox:checked:hover,
.input-radio:checked:hover {
  border-color: rgb(61, 64, 91);
}

.content-box .input-checkbox:hover,
.content-box .input-radio:hover {
  border-color: rgb(204, 204, 204);
}

.main .input-checkbox:hover,
.main .input-radio:hover {
  border-color: rgb(204, 204, 204);
}

.btn:hover {
  color: #fff;
  text-decoration: none;
}

.margin-2 {
  margin-top: 2em;
}

.Information_page_login {
  margin-left: 5px;
}

input:focus-visible {
  outline-offset: none !important;
  outline: 0px;
}

.return_to_previous_page {
  margin-top: 15px;
  margin-bottom: 37px;
}

.return_to_previous_page svg {
  display: none;
}

@media (max-width: 749px) {

  .card-login-but,
  .sp-modal-toggle {
    width: 100%;
    padding-top: 1.25em;
    padding-bottom: 1.25em;
  }

  .return_to_previous_page {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
  }

  .return_to_previous_page svg {
    display: -webkit-inline-box;
    margin-bottom: 4px;
    margin-right: 5px;
  }

  .main {
    padding-top: 1.5em;
  }

  .returbackbtnphn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .section {
    position: relative;
    padding-top: 1em;
  }
}

.order-summary-toggle {
  background: #fafafa;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 1.25em 0;
  flex-shrink: 0;
  text-align: left;
  width: 100%;
}

@media (min-width: 1000px) {
  .order-summary-toggle {
    display: none;
  }
}

/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
}
.butn:hover {
  background-color: #ff3772;
} */
.shiping-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
}

.shiping-flex .butn {
  width: 100%;
  margin: 0px;
}

.checkout-box .boxes {
  padding: 12px;
  margin-top: 20px;
  border: 1px solid #eee;
  box-sizing: border-box;
  border-radius: 5px;
}

.checkout-box .address-header {
  display: flex;
  align-items: baseline;
}

.checkout-box .address-box {
  background: #fff;
}

.checkout-box p {
  margin-bottom: 0.2rem;
}

.coupon {
  border: 1px dashed #a9abb2;
  color: #535665;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 15px;
  margin: 10px 0 0px;
  font-weight: 400;
  height: 50px;
}

.invalid-coupon-code-textbox {
  margin-top: 5px;
  font-size: 14px;
  color: #f94a5b !important;
}

.invalid-coupon-code-textbox-color {
  margin-top: 5px;
  font-size: 14px;
  color: rgb(96, 178, 70);
}

.apply-offer-select-address {
  /* margin-left: 0px; */
  padding: 14px 0;
}

.section--shipping-method {
  padding-top: 2rem;
}

.content-shipping {
  overflow: hidden;
}

.store-close {
  padding: 10px;
  font-weight: 700;
  background: #ffb3cc;
  border-radius: 10px;
  border: 1px solid #535353;
}

/* add by laxmi------------------------------ */

@media (max-width: 650px) and (min-width: 280px) {
  .shippingbtn {
    padding: 10px 0px;
    border-radius: 20px;
    font-size: 14px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 184px !important;
    text-align: center;
    cursor: pointer;
  }
}

@media (max-width: 650px) and (min-width: 400px) {
  .addnewaddressbtn {
    padding: 10px 0px;
    border-radius: 20px;
    font-size: 14px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 140px !important;
    text-align: center;
    cursor: pointer;
  }
}

@media (max-width: 400px) and (min-width: 280px) {
  .checkoutimgitem {
    width: 20% !important;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 13px;
  }

  .addnewaddressbtn {
    padding: 10px 0px;
    border-radius: 20px;
    font-size: 12px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 120px !important;
    text-align: center;
    cursor: pointer;
  }

  /* .shippingaddresstexFont {
    font-size: 14px !important;
  } */

  .checkoutshowitemfontphn {
    font-size: 14px !important;
  }

  .wrap {
    display: block;
    zoom: 1;
    margin: 0 2% !important;
  }

  .wrap {
    width: 96%;
    box-sizing: border-box;
    padding: 0 10px !important;
  }

  .radio__label__primary {
    cursor: inherit;
    font-family: inherit;
    vertical-align: top;
    font-size: 13px !important;
  }
}

/* for desktop view shipping continue button ------------------*/

@media (min-width: 999px) and (max-width: 1490px) {
  .shippingbtn {
    width: 45% !important;
  }
}

/* add by laxmi------------------------------ */

@media (max-width: 430px) and (min-width: 340px) {
  .wrapperSidedrawFixBottmidth {
    padding: 8px 8px !important;
    background: #fff;
    box-shadow: 0 -4px 16px rgb(0 0 0 / 16%);
    border-radius: 5px;
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100% !important;
  }
}

@media (max-width: 340px) and (min-width: 280px) {
  .wrapperSidedrawFixBottmidth {
    padding: 8px 8px !important;
    background: #fff;
    box-shadow: 0 -4px 16px rgb(0 0 0 / 16%);
    border-radius: 5px;
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100% !important;
  }

  .sidedrawFixBottombutn2 {
    padding: 10px 0px;
    border-radius: 20px;
    font-size: 17px !important;
    background-color: #c83363;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }

  .sidedrawtotalFixBottombutn2 {
    font-size: 17px !important;
  }
}

.invalid-coupon-code-textbox {
  margin-top: 5px;
  font-size: 14px;
  color: #f94a5b !important;
}

.invalid-coupon-code-textbox-color {
  margin-top: 5px;
  font-size: 14px;
  color: rgb(96, 178, 70) !important;
}

.rgbox-bottom {
  position: relative;
}

.rgbox-bottom p:before {
  border-bottom: 1px solid #767676;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 10px;
  margin: 0 auto;
}

.rgbox-bottom p label {
  background-color: #fff;
  margin-bottom: 0;
  color: #767676;
  position: relative;
  padding: 0 5px;
}

.coupon-card {
  padding: 25px 40px;
  border: 1px solid #e9e9eb;
  color: #3e4152;
}

.coupon-card h5 {
  background-color: wheat;
  width: 50%;
  padding: 10px 20px;
  text-align: center;
  font-weight: 400;
}

.coupon-terms-condition {
  font-size: 0.92rem;
  color: #686b78;
}

.coupon-terms-condition {
  font-size: 0.92rem;
  color: #686b78;
}

.coupon-more {
  color: #c83363 !important;
  font-size: 1rem;
  cursor: pointer;
}

.removeCoupon {
  float: right;
}

.return_to_previous_page svg {
  display: -webkit-inline-box;
  margin-bottom: 4px;
  margin-right: 5px;
}

@media (max-width: 999px) {
  .shippingphnmargintop {
    margin-top: 3rem !important;
  }

  .section__contentphnview {
    margin-top: 0px !important;
  }

  .sectionphnview {
    padding-top: 0em !important;
  }

  .ordersummerbottomphn {
    margin-top: -20px !important;
  }

  .contentphnview {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .checkout-box .boxes {
    margin-top: 0px;
  }

  .section__headerphnview {
    margin-bottom: 0px !important;
  }

  .sectiontoppadding {
    padding-top: 1em !important;
  }

  .orderphntopbottom {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
}

@media (min-width: 999px) {
  .section__contentphnview {
    margin-top: 2rem !important;
  }
}

@media (max-width: 750px) {
  .returntocartbtnphn {
    text-align: left !important;
  }

  .shippinphnblockview {
    display: block !important;
  }

  .returntocheckputphn {
    margin-top: 20px !important;
    margin-left: 8px !important;
  }
}

@media (max-width: 500px) {
  .coupon-card h5 {
    width: 100% !important;
  }
}
.pd-3 {
  padding: 0px 3%;
}
.flex-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rgbox-bottom {
    position: relative;
}

.rgbox-bottom p label {
    background-color: #fff;
    margin-bottom: 0;
    color: #767676;
    position: relative;
    padding: 0 5px;
}

.rgbox-bottom p:before {
    border-bottom: 1px solid #767676;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 10px;
    margin: 0 auto;
}
.static-page-container {
  justify-content: space-between;
  padding: 1em 0em;
  width: 86vw;
  margin: auto;
}

@media (max-width: 1070px) and (min-width: 960px) {
  .contact-us-captcha p {
    font-style: italic;
    background-color: wheat;
    /* padding: 8px; */
    padding: 16px 7px !important;
    letter-spacing: 26px;
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 480px) {
  .responsive-map-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }

  .responsive-map-container iframe,
  .responsive-map-container object,
  .responsive-map-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* .contact-us-captcha p {
        font-style: italic;
        background-color: wheat;
        padding: 8px;
        letter-spacing: 43px;
    } */

/* change by laxmi p */
.contact-us-captcha p {
  font-style: italic;
  background-color: wheat;
  /* padding: 8px; */
  padding: 16px 12px;
  letter-spacing: 26px;
  display: flex;
  align-items: center;
}

.blog-main {
  padding: 40px 3%;
}

.blog-main h2 {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px;
  /* text-align: center !important; */
  font-size: 40px;
}

.blog-main p {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  color: #3d405b;
  max-width: 100%;
  margin: auto;
  line-height: 25px;
  /* text-align: center; */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-img img {
  width: 100%;
  border-radius: 10px;
}

.blog-flex {
  display: flex;
  justify-content: space-between;
}

.blog-h2p {
  /* padding: 2em 0em 0em 0em; */
}

.blog-h2 {
  font-size: 35px;
  text-transform: capitalize;
  padding: 0 0 15px;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left !important;
}

.rightup-p {
  font-size: 16px;
  color: #928f8f;
  letter-spacing: 0;
  margin-bottom: 15px;
  text-align: left;
  font-weight: 500;
  text-align: justify;
}

.pad-row {
  padding-top: 2em;
  display: flex !important;
}

@media (min-width: 500px) and (max-width: 995px) {
  .blog-h2p p {
    font-size: 15px !important;
    margin-bottom: 1px !important;
    line-height: 30px;
    /* top: 13px; */
    margin-top: 10px;
  }
  .blog-h2p {
    padding: 0em !important;

    /* add by laxmi ---------------- */

    margin-top: 15px !important;

    /* end------------------- */
  }
  .blog-main h2 {
    margin: 0px !important;
    font-size: 20px !important;
    padding: 0px;
    text-align: left;
  }
}

@media (max-width: 500.9px) {
  .blog-main h2 {
    margin: 0px !important;
    font-size: 20px !important;
    padding: 0px;
    text-align: left;
  }

  .blog-h2p {
    padding: 0em !important;

    /* add by laxmi ---------------- */

    margin-top: 15px !important;

    /* end------------------- */
  }

  .blog-h2p p {
    font-size: 13px !important;
    margin-bottom: 1px !important;
    margin-top: 10px;
  }
}

/* add by laxmi */

@media (min-width: 768px) {
  .contactform {
    height: 100%;
    padding: 40px 45px 40px;
    /* background: rgb(255, 255, 255); */
    background: "#fff";
  }
}

@media (max-width: 768px) {
  .butnSubmitContactfrm {
    margin-top: 15px !important;
  }

  .contactform {
    height: 100%;
    padding: 20px 2px !important;
    background: rgb(255, 255, 255);
  }

  .such {
    margin-bottom: 20px !important;
    font-size: 14px !important;
  }

  .form-floating > label {
    position: absolute;
    font-size: 14px !important;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }

  .form-controlCaptch {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
            appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .form-controlOrderHistory {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 2.5vmin !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
            appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .such {
    margin-bottom: 20px !important;
    font-size: 18px !important;
  }

  .form-floating > label {
    position: absolute;
    font-size: 18px !important;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }

  .form-controlCaptch {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 3vmin !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
            appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

@media (min-width: 768px) and (max-width: 960px) {
  .contact-us-captcha p {
    font-style: italic;
    background-color: wheat;
    padding: 16px 8px;
    letter-spacing: 12px !important;
    display: flex;
    align-items: center;
  }

  .captchaPadding {
    padding-left: 0px !important;
  }

  .form-controlCaptch {
    display: block;
    width: 100%;
    padding-left: 4px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    /* padding: 0.375rem 0.75rem; */
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
            appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.butnSubmitContactfrm {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff !important;
  width: 140px;
  text-align: center;
  cursor: pointer;
}

.butnSubmitContactfrm:hover {
  background-color: #ff3772;
}

.texttransform-about p,
h2 {
  text-transform: none !important;
}

.termsandconditions-heading h2 {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px;
  /* text-align: center !important; */
  font-size: 40px;
}

.static-page-main {
  padding: 40px 3%;
}

.faqs .Collapsible__trigger {
  padding: 10px;
  border: 1px solid #ebebeb;
  width: 100%;
  display: block;
}

.faqs .Collapsible {
  margin-bottom: 10px;
}

.faqs .Collapsible__contentOuter p {
  padding: 10px;
  border: 1px solid #ebebeb;
  width: 100%;
}

