/* @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap");

body {
  font-family: "Nunito", sans-serif;
} */

.listing-page-container {
  justify-content: space-between;
  padding: 1em 0em;
  /* width: 86vw; */
  margin: 0 3%;
  overflow-x: hidden;
}

.heading {
  font-size: 2.25rem;
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 30px;
  font-family: "Nunito", sans-serif;
}

.heading h5 {
  font-size: 2.5rem;
  font-weight: 700;
}

.wbsortbar {
  margin-bottom: 30px;
  margin-top: 15px;
  align-items: center;
  border: 1px solid #eeeeee;
  margin-left: 0;
  margin-right: 0;
  border-radius: 10px;
  padding: 5px 0;
}

.wbglbtn button {
  background-color: transparent;
  border: none;
  padding: 0;
  transition: 0.3s ease;
}

.listv.wblistgridbtn {
  margin: 0 15px;
}

.listing-page svg {
  width: 14px;
  height: 14px;
  transition: 0.3s ease;
  vertical-align: middle;
}

.wbcatsort #FacetFiltersForm {
  display: flex;
  justify-content: right;
  align-items: center;
  float: right;
}

.wbcatsort .product-count {
  color: var(--color-wbmaincolors-text);
}

.wbcatsort .product-count__text {
  font-size: calc(var(--font-size-base) * 1.29px);
  text-transform: capitalize;
  margin: 0 30px;
}

.caption {
  font-size: calc(var(--font-size-base) * 1px);
  letter-spacing: 0.07rem;
}

.facet-filters__field {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.caption span {
  color: 0, 0, 0;
}

.facet-filters__label label {
  margin: 0 10px;
}

.facet-filters__field .select {
  width: auto;
}

.facet-filters__sort {
  height: auto;
  margin: 0;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 6px 25px 6px 10px;
  letter-spacing: 0.5px;
}

.facet-filters__sort,
.facet-filters__sort:hover {
  box-shadow: none;
}

.facet-filters__sort+.icon-caret {
  right: 10px;
  width: 10px;
}

.select .icon-caret,
.customer select+svg {
  height: 0.6rem;
  pointer-events: none;
  position: absolute;
  top: calc(50% - 0.2rem);
  right: 1.5rem;
}

.wbcatsort #FacetFiltersForm {
  display: flex;
  justify-content: right;
  align-items: center;
}

.wblistgridbtn.active svg,
.wblistgridbtn svg:hover {
  fill: #3d405b;
}

.text-area,
.select {
  display: inline-block;
  position: relative;
  width: 100%;
}

.select__select,
.customer select {
  cursor: pointer;
  /* padding: 0 2rem 0 0.5rem; */
}

select {
  word-wrap: normal;
}

.select #option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

.facets-container .disclosure-has-popup {
  margin-bottom: 30px;
}

.disclosure-has-popup {
  position: relative;
}

.facets__summary {
  font-size: calc(14 * 1.15px);
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 12px;
}

summary {
  cursor: pointer;
  list-style: none;
  position: relative;
}

summary {
  display: list-item;
  cursor: pointer;
}

details>* {
  box-sizing: border-box;
}

.mobile-facets__summary>div,
.facets__summary>div {
  display: flex;
  align-items: center;
}

.facets__summary span {
  transition: text-decoration 0.1s ease;
}

.facets__disclosure[open] .facets__display {
  animation: animateMenuOpen 0.2s ease;
}

.facets__header {
  display: flex;
  justify-content: space-between;
  letter-spacing: 0;
  padding-bottom: 15px;
}

.facets__reset {
  margin-left: auto;
}

.link {
  cursor: pointer;
  display: inline-block;
  border: none;
  box-shadow: none;
  text-decoration: none;
  text-underline-offset: 0.3rem;
  background-color: transparent;
  font-family: inherit;
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.facets__item {
  display: flex;
  align-items: center;
}

.facets__item label,
.facets__item input[type="checkbox"] {
  cursor: pointer;
}

.facet-checkbox {
  padding: 0;
  flex-grow: 1;
  position: relative;
  font-size: rgb(0, 0, 0);
  word-break: break-word;
  transition: 0.3s ease;
  margin-bottom: 10px;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.facet-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 1;
  width: 20px;
  height: 20px;
  top: 2px;
  left: -1px;
  z-index: -1;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid var(--color-wbbordercolor);
}

.facets__item label,
.facets__item input[type="checkbox"] {
  cursor: pointer;
}

input[type="checkbox"] {
  display: inline-block;
  width: auto;
  margin-right: 0.5rem;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.facet-checkbox>svg {
  background-color: rgb(var(--color-background));
  margin-right: 10px;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  transition: 0.3s ease;
  opacity: 0;
}

.facet-checkbox .icon-checkmark {
  visibility: hidden;
  position: absolute;
  left: 0;
  z-index: 5;
  top: 3px;
  opacity: 1;
}

@media (max-width: 768px) {
  #main-collection-filters {
    display: none;
  }

  /* #for-mobile-view {
    display: block;
  } */

  /* #for-desktop-view {
    display: none;
  } */
}

@media (min-width: 768px) {
  #for-mobile-view {
    display: none;
  }

  /* #for-desktop-view {
    display: block;
  } */
}

select:focus-visible {
  outline: none !important;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #55585b !important;
  border-radius: 10px;
  top: 0;
}

.slider1-con1 {
  padding: 0 15px;
  width: 100%;
}

.slider1-card {
  width: 100% !important;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 0px;
}

.slider1-con1 {
  padding-bottom: 2em;
}

.slider1-con1 p {
  font-size: 16px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 12px;
  color: #2b2b2b;
}

/* Card CSS */

.price h6 {
  /* text-align: center; */
  color: #22b7c0;
}

.wbproductdes ul {
  text-decoration: none;
  text-align: center;
  padding: 0em;
}

.wbproductdes li {
  display: inline-block;
  color: #f5db3c !important;
}

.content-pd {
  padding-top: 30px;
}

.wbproduct-container {
  background-color: #f5f5f5;
  transition: 0.3s ease;
  border-radius: 10px;
}

.wbimgblock {
  position: relative;
  text-align: center;
}

.card__badge span {
  position: absolute;
  left: 15px;
  top: 15px;
  margin: 0 auto;
  display: inline-block;
  text-transform: capitalize;
  line-height: normal;
  font-size: calc(14 * 1px);
  border: none;

  background-color: #c83363;
  color: #ffffff;
  padding: 2px 10px;
  border-radius: 4px;
}

.product_badge {
  position: absolute;
  right: 15px;
  top: 15px;
  margin: 0 auto;
  display: inline-block;
  text-transform: capitalize;
  line-height: normal;
  font-size: calc(14 * 1px);
  border: none;
  background-color: #c83363;
  color: #ffffff;
  padding: 2px 10px;
  border-radius: 4px;
}

.wbproductdes {
  padding: 0 15px 10px;
  text-align: center;
}

.wbproductdes {
  padding: 0 15px 10px;
  text-align: center;
}

.product-title {
  font-size: 16px;
  text-transform: capitalize;
  transition: 0.3s ease;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 12px;
  font-weight: 600;
  margin-bottom: 0;
}

a {
  color: rgb(0, 0, 0);
  transition: 0.3s ease;
}

#product-grid .grid__item {
  margin-bottom: 30px;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #3d405b;
  border-color: #3d405b;
}

.rless {
  margin-left: -15px;
  margin-right: -15px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #3d405b;
  border-color: #3d405b;
}

.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.product-list .wbproduct-container {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.product-list .wbimgblock {
  float: left;
  width: 21%;
}

.product-list .wbproductdes {
  float: left;
  width: 70%;
  text-align: left;
  padding: 15px;
}

.product-list .wbhprice {
  margin-bottom: 0;
}

.product-list .wbproduct-container .product-form {
  position: unset;
  opacity: 1;
  margin-top: 10px;
  text-align: left;
  display: inline-block;
}

/* Card CSS End */

.product-list .grid__item {
  flex: 0 0 100%;
  max-width: 100%;
}

.product-list .product-form .cartb,
.product-list .product-form .wbquickv,
.product-list .product-form .wbwish a {
  border: 1px solid var(--color-wbbordercolor);
  margin: 0 4px;
  border-radius: 4px;
}

.product-list .wbproduct-container .product-form form,
.product-list .wbproduct-container .button-group {
  position: unset;
  opacity: 1;
  display: inline-block;
}

.product-form .cartb,
.product-form .wbquickv,
.product-form .wbwish a {
  border: none;
  text-transform: capitalize;
  background: rgb(255, 255, 255);
  min-width: 40px;
  min-height: 40px;
  display: inline-block;
  vertical-align: middle;
  color: rgb(0, 0, 0);
  text-align: center;
  transition: 0.3s ease;
  letter-spacing: 0.5px;
  padding: 8px;
  margin: 5px 0;
  border-radius: 4px;
  box-shadow: 0 0 5px #0003;
}

.product-list .product-form .cartb,
.product-list .product-form .wbquickv,
.product-list .product-form .wbwish a {
  border: 1px solid #eeeeee;
  margin: 0 4px;
  border-radius: 4px;
}

.form .product-form__submit {
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  line-height: 1.25;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 10px;
  background: #3d405b;
  color: #ffffff;
  transition: 0.3s ease;
  -webkit-appearance: none;
  appearance: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
}

#for-desktop-view .form {
  padding: 15px;
}

.wblistgridbtn.active svg,
.wblistgridbtn svg:hover {
  fill: #3d405b;
}

.wbglbtn button svg {
  width: 23px;
  height: 23px;
  fill: rgba(0, 0, 0, 0.5);
}

.facet-checkbox>svg {
  background-color: rgb(255, 255, 255);
  margin-right: 10px;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  transition: 0.3s ease;
  opacity: 0;
}

.facet-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 1;
  width: 20px;
  height: 20px;
  top: 2px;
  left: -1px;
  z-index: -1;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid var(--color-wbbordercolor);
}

.facets__item label,
.facets__item input[type="checkbox"] {
  cursor: pointer;
}

input[type="checkbox"] {
  display: inline-block;
  width: auto;
  margin-right: 0.5rem;
}

.facet-checkbox>input[type="checkbox"]:checked~.icon-checkmark {
  visibility: visible;
  background: #3d405b;
  color: #ffffff;
}

.butn2 {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.button-soldout {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #bfbebe;
  color: #ffffff;
  text-align: center;
  /* width: 30%; */
  cursor: default;
}

.button-soldout-list {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #bfbebe;
  color: #ffffff;
  text-align: center;
  width: 30%;
  cursor: default;
}

.butn3 {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  text-align: center;
  width: 30%;
  cursor: pointer;
}

.breadcrumb-content .breadcrumb {
  margin: 1rem 0px !important;
  background-color: transparent !important;
}

.list-breadcrumb-flex {
  display: grid;
  justify-content: end;
  align-items: center;
}

.listingpage-banner img {
  width: 100%;
}

.listingpage-product-list ul {
  text-decoration: none;
  text-align: inherit;
  padding: 0em;

}

.listing-page-breadcumb .breadcrumb-content {
  /* background: #f7f7f7; */
  border-radius: 10px;
  /* border: 1px solid #eeeeee; */
  text-transform: capitalize;
  padding: 1px 15px;
  margin: 0px 0px !important;
}

.wbglbtn {
  margin: 0 30px !important;
}

.wbproduct-container-list {
  /* background-color: #f5f5f5; */
  transition: 0.3s ease;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
}


/* add by laxmi */

@media (max-width:768px) {

  .wbglbtn,
  .product-count {
    display: none !important;
  }

  .listing-page-breadcumb .breadcrumb-content {
    border-radius: 10px;
    text-transform: capitalize;
    padding: 0px 0px;
    margin: 0px 0px !important;
    font-size: 13px;
  }

  .select {
    font-size: 13px;
  }
}

@media (max-width:500px) and (min-width:380px) {
  .breadcrumb-content .breadcrumb {
    margin: 0px !important;
    background: transparent;
  }

  .facet-filters__sort {
    height: auto;
    margin: 0;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 6px 0px 6px 0px;
    letter-spacing: 0.1px;
  }

  .select {
    font-size: 11px;
  }

  .listing-page-breadcumb .breadcrumb-content {
    border-radius: 10px;
    text-transform: capitalize;
    padding: 0px 0px;
    margin: 0px 0px !important;
    font-size: 11px;
  }

  .wbsortbar {
    margin-bottom: 30px;
    margin-top: 15px;
    align-items: center;
    border: 1px solid #eeeeee;
    margin-left: 0;
    margin-right: 0;
    border-radius: 10px;
    padding: 0px 0;
  }

  .button-soldout {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 15px;
    background-color: #bfbebe;
    color: #ffffff;
    text-align: center;
    /* width: 30%; */
    cursor: default;
  }
}

@media (max-width:380px) and (min-width:280px) {
  .wbsortbar {
    margin-bottom: 30px;
    margin-top: 15px;
    align-items: center;
    border: 1px solid #eeeeee;
    margin-left: 0;
    margin-right: 0;
    border-radius: 10px;
    padding: 0px 0;
  }

  .select {
    font-size: 8px;
  }

  .listing-page-breadcumb .breadcrumb-content {
    border-radius: 10px;
    text-transform: capitalize;
    padding: 0px 0px;
    margin: 0px 0px !important;
    font-size: 8px;
  }

  .button-soldout {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 15px;
    background-color: #bfbebe;
    color: #ffffff;
    text-align: center;
    /* width: 30%; */
    cursor: default;
  }

  .breadcrumb-content .breadcrumb {
    margin: 0px !important;
    background: transparent;
  }

  .facet-filters__sort {
    height: auto;
    margin: 0;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 6px 0px 6px 0px;
    letter-spacing: 0.1px;
  }

  /* cards item */
  .product-title {
    font-size: 14px;
    text-transform: capitalize;
    transition: 0.3s ease;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 0 2px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .price__sale h6 {
    font-size: 13px;
  }

  .wbproductdes {
    padding: 0 6px 10px;
    text-align: center;
  }

  .button-soldout {
    padding: 5px 0px;
    border-radius: 20px;
    font-size: 13px;
    background-color: #bfbebe;
    color: #ffffff;
    text-align: center;
    /* width: 30%; */
    cursor: default;
  }

  .listingpage-product-list {
    font-size: 13px;
  }
}

@media (min-width:768px) and (max-width:860px) {
  .wbsortbar {
    margin-bottom: 30px;
    margin-top: 15px;
    align-items: center;
    border: 1px solid #eeeeee;
    margin-left: 0;
    margin-right: 0;
    border-radius: 10px;
    padding: 0px 0;
  }

  .wbcatsort .product-count__text {
    font-size: 13px;
    text-transform: capitalize;
    margin: 0 30px;
    width: 108px;
  }
}


@media (min-width:769px) and (max-width:844px) {
  .select {
    font-size: 13px;
  }

  .listing-page-breadcumb .breadcrumb-content {
    border-radius: 10px;
    text-transform: capitalize;
    padding: 0px 0px;
    margin: 0px 0px !important;
    font-size: 13px;
  }
}

.butn2checkout{
  font-size: 18px !important;
}