.rgbox-bottom {
    position: relative;
}

.rgbox-bottom p label {
    background-color: #fff;
    margin-bottom: 0;
    color: #767676;
    position: relative;
    padding: 0 5px;
}

.rgbox-bottom p:before {
    border-bottom: 1px solid #767676;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 10px;
    margin: 0 auto;
}