.footer {
  margin-top: 40px;
  color: #ffffff;
  background: #c83363;
  padding: 5px 3% 5px 3%;
}

.footer-content {
  padding: 20px 0 20px;
}

.footer-col1p {
  line-height: 28px;
  font-size: 14px;
  text-align: justify;
  color: #ffffff !important;
  width: 85%;
}

.foot-col2-h3 {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 15px;
  color: #ffffff !important;
}

.foot-col2-UL {
  list-style: none;
  /* text-align: justify; */

  /* change by laxmi */
  text-align: unset;
}

.foot-col2-UL li {
  list-style: none;
  line-height: 30px;
}

.footer-col1-ul {
  list-style: none;
  padding: 0em;
}

.footer-col1-ul li {
  display: inline-block;
  min-width: 40px;
  min-height: 40px;
  border-radius: 10px;
  box-shadow: 0 0 5px #0003;
  display: inline-block;
  text-align: center;
  padding-top: 8px;
  margin: 0 6px;
  background: white;
  color: black;
}

.footer-con2 {
  /* padding: 60px 60px; */
  text-align: center;
}

.footer-con2 p {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  line-height: 45px;
  color: #ffffff !important;
  font-size: 14px;
}

/* .footer-con2 p:after {
  content: "|";
  margin: 0 22px;
  color: #ffffff;
} */

.footer-end {
  background-color: #f5f5f5;
  padding: 15px 3%;
  font-size: 14px;
  text-align: center;
}

.footer-end-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
}

.footer-end-left img {
  width: 30px;
  /* height: 22px; */
}

.co-bac-foot {
  background-color: #f5f5f5;
}

.foot-col2-UL a {
  color: #ffffff !important;
}

.babystreetWaveWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 140px;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: auto;
  max-width: 103%;
}

.bgTop {
  z-index: 15;
  opacity: 0.5;
}

.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: -1px;
}

.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.waveTop {
  background-size: 50% 100px;
  background-image: url("https://babystreet.althemist.com/wp-content/themes/babystreet/image/wave-top.png");
}

.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat-x;
  background-position: 0 100%;
  transform-origin: center bottom;
}

.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}

.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}

.waveMiddle {
  background-size: 50% 120px;
  background-image: url("https://babystreet.althemist.com/wp-content/themes/babystreet/image/wave-mid.png");
}

.bgBottom {
  z-index: 5;
}

.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}

.waveBottom {
  background-size: 50% 100px;
  background-image: url("https://babystreet.althemist.com/wp-content/themes/babystreet/image/wave-bot.png");
}

.footer-logo-wrap {
  background-color: #ffffff;
  width: 100px;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 20px;
}

.we-accept.we-accept {
  color: #ffffff;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 10px;
}

.paymentImgPhnview.paymentImgPhnview {
  width: 45px;
  height: auto !important;
  border-radius: 3px !important;
  margin: auto;
}

.footer .slick-slider {
  width: 50%;
  margin: auto;
}

/* add by laxmi --------------------------------------------------------------*/
@media (max-width: 998px) {
  .addressphnview {
    display: flex !important;
  }

  .locationicon {
    margin-top: 8px !important;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .slick-arrow {
    display: none !important;
  }

  .slick-slider {
    width: 90%;
    margin: auto;
  }

  .copywriteGlobalname {
    justify-content: center !important;
    margin-bottom: 8px;
  }

  .footer-con2 p {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    line-height: 45px;
    color: #ffffff !important;
    font-size: 14px;
    font-weight: 600 !important;
  }

  .copywriteyear {
    justify-content: center !important;
  }

  .foot-col2-UL {
    list-style: none;
    text-align: unset;
    margin-top: 28px !important;
  }

  .foot-col2-h3 {
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 7px;
    color: #ffffff !important;
  }

  .footerPaddingPhnview {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  .paymentImgPhnview {
    width: 45px;
    height: auto !important;
    border-radius: 3px !important;
  }

  .payment-icons svg {
    width: 45px !important;
    height: auto !important;
  }

  .payment-icons {
    width: 70%;
    margin: auto;
  }
}

@media (min-width: 768px) {
  .copywriteGlobalname {
    float: left;
  }

  .copywriteyear {
    float: right;
  }
}

@media (min-width: 450px) and (max-width: 768px) {
  .footer-end {
    background-color: #f5f5f5;
    padding: 6px 3% !important;
    font-size: 15px !important;
    text-align: center;
    font-weight: 500 !important;
  }
}

@media (min-width: 280px) and (max-width: 450px) {
  .footer-end {
    background-color: #f5f5f5;
    padding: 8px 3% !important;
    font-size: 11px !important;
    text-align: center;
    font-weight: 500 !important;
  }

  .foot-col2-UL li {
    list-style: none;
    line-height: 28px;
    font-size: 14px !important;
  }

  .footer-col1p {
    line-height: 28px;
    font-size: 14px !important;
    text-align: justify;
    color: #ffffff !important;
    width: 100% !important;
  }
}
