.breadcrumb-content {
 
     /* background: #f7f7f7; */
     border-radius: 10px;
     /* border: 1px solid #eeeeee; */
     text-transform: capitalize;
     padding: 1px 15px;
     margin: 40px 0px;
}
.breadcrumb-content .breadcrumb {
  margin: 1rem 0px !important;
  background-color: transparent !important;
}
a {
  text-decoration: none !important;
  color: #000000 !important;
}

.text-center-login {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 35px;
  text-align: center;
  font-size: 40px;
}
.card-login {
  border: 1px solid #eeeeee;
  padding: 15px;
  border-radius: 4px;
  height: 100%;
}
.card-login-but {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
  background-color: #3d405b;
  border-radius: 10px;
  color: #ffffff;
}
.custheading {
  font-size: 20px;
}
.card-login-p {
  background-color: #fff;
  color: #9e8f86;
  padding-bottom: 20px;
  font-size: 13px;
}
.form-control:focus {
  outline: none !important;
  box-shadow: none;
  border-color: #ced4da;
}
.text-right-login {
  text-align: right !important;
  margin-top: 1rem;
  display: block;
  text-decoration: none;
  background-color: transparent;
  color: #000000;
}
.btn:hover {
  color: white !important;
  text-decoration: none !important;
}
.btn-light:hover {
  color: black !important;
  /* background-color: transparent;
  border-color: #f9fafb !important; */
}

.pd-3 {
  padding: 0px 3%;
}

/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
  cursor: pointer;
}
.butn:hover {
  background-color: #ff3772;
} */
