@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap");

body {
  margin: 0px;
  padding: 0px;
  font-family: "Raleway", sans-serif !important;
  overflow-x: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(0, 0, 0) !important;
}

p {
  color: #2b2b2b !important;
  font-size: 1rem !important;
}

.butn {
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff !important;
  width: 145px;
  text-align: center;
  cursor: pointer;
}

.butn:hover {
  background-color: #ff3772;
}

/* Add by laxmi */
.pagenotfoundbtn {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff !important;
  width: 140px;
  text-align: center;
  cursor: pointer;
}

.pagenotfoundbtn:hover {
  background-color: #ff3772;
}

.payment-icons svg {
  height: 70px;
  width: 70px;
}

.payment-icons li {
  list-style: none;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
}

.contact-list {
  justify-content: center;
}

/* add by laxmi------------------- Account css */

@media (max-width: 310px) {
  .accountbutn {
    padding: 9px 0px !important;
    border-radius: 20px;
    font-size: 14px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 128px;
    text-align: center;
    cursor: pointer;
  }
}

.butnsearchicondrps {
  padding: 10px 13px !important;
  border-radius: 20px;
  font-size: 13px !important;
  background-color: #c83363;
  color: #ffffff !important;
  width: 140px;
  text-align: center;
  cursor: pointer;
}

.butnsearchicondrpssoldout {
  padding: 10px 13px !important;
  border-radius: 20px;
  font-size: 13px !important;
  color: #ffffff !important;
  width: 90px !important;
  text-align: center;
  cursor: pointer;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 4px !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 4px !important;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/");
}

/* banner arrow */

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  backface-visibility: visible;
  background: none !important;
}

/* .carousel-control-next-icon::after {
  background-image: none !important;
  content:'>';
  color: #c83363 !important;
  font-size: 50px;
  font-weight: 800;
}

.carousel-control-prev-icon::after {
  background-image: none !important;
  content:'<';
  color: #c83363 !important;
  font-size: 50px;
  font-weight: 800;
} */