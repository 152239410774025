.spaced-section {
  margin-top: 60px;
  overflow-x: hidden;
}
.heading {
  font-size: calc(40 * 1px);
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 30px;
}

.rless {
  margin-left: -15px;
  margin-right: -15px;
}

.cless {
  padding-left: 15px;
  padding-right: 15px;
}

/* .grid__item {
    flex-grow: 1;
    flex-shrink: 0;
} */

.twopro .wbproduct-container,
.product-recommendations .wbproduct-container {
  margin-bottom: 30px;
}

.related_products .grid__item {
  margin-bottom: 30px;
}
/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
}
.butn:hover {
  background-color: #ff3772;
} */

@media (max-width: 768px) {
  .cless {
    padding-left: 10px;
    padding-right: 10px;
  }
}
