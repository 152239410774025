.Register {
  width: 50%;
  margin: auto;
  /* padding-top: 2em; */
}

.card-register-but {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
  background-color: #3d405b;
  border-radius: 10px;
  color: #ffffff;
  margin-top: 1em;
}

.breadcrumb-content {
  /* background: #f7f7f7; */
  border-radius: 10px;
  /* border: 1px solid #eeeeee; */
  text-transform: capitalize;
  padding: 0;
  margin: 0px 0px 25px 0px;
}

.breadcrumb-content .breadcrumb {
  margin: 1rem 0px;
  background: transparent;
}

a {
  text-decoration: none !important;
  color: #000000 !important;
}

.text-center-login {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 35px;
  text-align: center;
  font-size: 40px;
}

.card-login {
  border: 1px solid #eeeeee;
  padding: 15px;
  border-radius: 4px;
  height: 100%;
}

.card-login-but {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
  background-color: #3d405b;
  border-radius: 10px;
  color: #ffffff;
}

.custheading {
  font-size: 20px;
}

.card-login-p {
  background-color: #fff;
  color: #9e8f86;
  padding-bottom: 20px;
  font-size: 13px;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none;
  border-color: #ced4da;
}

.text-right-login {
  text-align: right !important;
  margin-top: 1rem;
  display: block;
  text-decoration: none;
  background-color: transparent;
  color: #000000;
}

.pd-3 {
  padding: 0px 3%;
}

/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
  cursor: pointer;
}
.butn:hover {
  background-color: #ff3772;
} */

.gender-input {
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.5 !important;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  /* appearance: none; */
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-icon-change-pass {
  float: right;
  bottom: 32px;
  position: relative;
  right: 9px;
}

.btnicons {
  width: 20px;
}

.birthdate {
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none !important;
}

/* Add by laxmi------------------------------------------------------------------------------------------------- */

@media (max-width: 767.9px) {
  .phnviewmarginlogin {
    margin-top: 15px;
  }
}

@media (max-width: 500px) and (min-width: 410px) {
  .text-center-login {
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
    text-align: center;
    font-size: 32px;
  }
}

@media (max-width: 410px) and (min-width: 350px) {
  .text-center-login {
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
  }

  .card-login-p {
    background-color: #fff;
    color: #9e8f86;
    padding-bottom: 20px;
    font-size: 15px !important;
    text-align: justify;
  }
}

@media (min-width: 350px) and (max-width: 410px) {
  .accountSignbutn {
    padding: 8px 0px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 128px;
    text-align: center;
    cursor: pointer;
  }

  .forgotpassfont {
    font-size: 14px;
  }

  .card-login-p {
    background-color: #fff;
    color: #9e8f86;
    padding-bottom: 20px;
    font-size: 14px !important;
    text-align: justify;
  }

  .text-center-login {
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
  }
}

@media (min-width: 280px) and (max-width: 350px) {
  .accountSignbutn {
    padding: 7px 0px;
    border-radius: 20px;
    font-size: 12px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px;
    text-align: center;
    cursor: pointer;
  }

  .forgotpassfont {
    font-size: 12px;
  }

  .card-login-p {
    background-color: #fff;
    color: #9e8f86;
    padding-bottom: 20px;
    font-size: 14px !important;
    text-align: justify;
  }

  .text-center-login {
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
  }
}

@media (min-width: 600px) and (max-width: 992px) {
  .Register {
    width: 80%;
    margin: auto;
  }
}

@media (max-width: 600px) {
  .Register {
    width: 100%;
    margin: auto;
  }
}

@media (min-width: 351px) and (max-width: 450px) {
  .forgotpassPehra {
    font-size: 15px !important;
  }
}

@media (max-width: 350px) {
  .forgotpassPehra {
    font-size: 13px !important;
  }

  .forgotpassHeading {
    font-size: 15px !important;
  }

  .signphnforgetpass {
    margin-right: 8px;
  }

  .loginphnforgetpass {
    margin-left: 8px;
  }
}

.card-login-pspacecreateaccount {
  padding-bottom: 10px !important;
}
