.leftd-ul {
  list-style: none;
}

.leftd-ul li {
  border-top: 1px solid #dddbdb;
  font-size: 18px;
  font-weight: 500;
  color: black;
  padding: 15px;
}
.leftd-ul li:last-child {
  border-bottom: 1px solid #dddbdb;
}

.hiplus {
  float: right;
}

.hiplus-icon {
  font-size: 2em;
}

.mobile-menu-categories {
  margin-top: 10px;
  margin-left: 0px;
}

.mobile-menu-categories li {
  border: none !important;
}

.fixed-footer-drawer {
  padding: 0px 15px;
}

.footer-col1-ul li:hover {
  animation: shake 150ms 2 linear;
  -moz-animation: shake 150ms 2 linear;
  -webkit-animation: shake 150ms 2 linear;
  -o-animation: shake 150ms 2 linear;
}

@keyframes shake {
  0% {
    transform: translate(3px, 0);
  }

  50% {
    transform: translate(-3px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@-moz-keyframes shake {
  0% {
    -moz-transform: translate(3px, 0);
  }

  50% {
    -moz-transform: translate(-3px, 0);
  }

  100% {
    -moz-transform: translate(0, 0);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(3px, 0);
  }

  50% {
    -webkit-transform: translate(-3px, 0);
  }

  100% {
    -webkit-transform: translate(0, 0);
  }
}

@-ms-keyframes shake {
  0% {
    -ms-transform: translate(3px, 0);
  }

  50% {
    -ms-transform: translate(-3px, 0);
  }

  100% {
    -ms-transform: translate(0, 0);
  }
}

@-o-keyframes shake {
  0% {
    -o-transform: translate(3px, 0);
  }

  50% {
    -o-transform: translate(-3px, 0);
  }

  100% {
    -o-transform: translate(0, 0);
  }
}

/* add by laxmi */
.logosidedraw {
  height: 20px;
}

.socialiconDraw {
  border-top: 1px solid #dddbdb;
}

.offcanvas-body {
  overflow-y: auto;
  overflow-x: hidden !important;
}

.socialiconDrawmargin {
  margin-top: 30px;
}

.offcanvas-header {
  justify-content: flex-end;
}

.got-question {
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-wrapper {
  padding: 15px 15px;
  font-weight: 500;
  border-bottom: 1px solid #dddbdb;
}
.drawer-contact {
  margin-bottom: 0;
}
.drawer-contact a {
  color: #c83363 !important;
  font-size: 26px !important;
  font-weight: bold;
}

@media (min-width: 360px) and (max-width: 710px) {
  .listIconDraw {
    display: block !important;
    text-align: left;
  }
  .drawsocialicon {
    height: 25px !important;
    width: 25px !important;
    color: #fff;
  }

  .footer-col1-ul .facebook {
    background-color: #4267b2;
    border-radius: 50%;
  }

  .footer-col1-ul .instagram {
    background: -webkit-radial-gradient(
        32% 106%,
        circle cover,
        rgb(255, 225, 125) 0%,
        rgb(255, 205, 105) 10%,
        rgb(250, 145, 55) 28%,
        rgb(235, 65, 65) 42%,
        transparent 82%
      ),
      -webkit-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190)
            58%);
    background: -moz-radial-gradient(
        32% 106%,
        circle cover,
        rgb(255, 225, 125) 0%,
        rgb(255, 205, 105) 10%,
        rgb(250, 145, 55) 28%,
        rgb(235, 65, 65) 42%,
        transparent 82%
      ),
      -moz-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
    background: -ms-radial-gradient(
        32% 106%,
        circle cover,
        rgb(255, 225, 125) 0%,
        rgb(255, 205, 105) 10%,
        rgb(250, 145, 55) 28%,
        rgb(235, 65, 65) 42%,
        transparent 82%
      ),
      -ms-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
    background: -o-radial-gradient(
        32% 106%,
        circle cover,
        rgb(255, 225, 125) 0%,
        rgb(255, 205, 105) 10%,
        rgb(250, 145, 55) 28%,
        rgb(235, 65, 65) 42%,
        transparent 82%
      ),
      -o-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
    background: radial-gradient(
        circle farthest-corner at 32% 106%,
        rgb(255, 225, 125) 0%,
        rgb(255, 205, 105) 10%,
        rgb(250, 145, 55) 28%,
        rgb(235, 65, 65) 42%,
        transparent 82%
      ),
      linear-gradient(135deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
    border-radius: 50%;
  }

  .fixediconsize {
    width: 100% !important;
  }

  .imgsizedraw {
    height: 90px;
    width: 130px;
  }
}

@media (max-width: 360px) {
  .listIconDraw {
    display: block !important;
    text-align: center;
  }

  .fixediconsize {
    width: 100% !important;
  }

  .imgsizedraw {
    height: 78px;
    width: 120px;
  }
  .drawsocialicon {
    height: 25px !important;
    width: 25px !important;
  }
}

.drawfontanchor {
  width: 100% !important;
}
.drawfontanchor:hover {
  text-decoration: none !important;
}
.drawfontanchor::after {
  text-decoration: none !important;
  background: transparent !important;
}

.left-drawer-logoposition {
  text-align: center;
}

@media (min-width: 710px) and (max-width: 994.9px) {
  .imgsizedraw {
    height: 90px;
    width: 130px;
  }
  .listIconDraw {
    display: block !important;
    text-align: center;
  }
  .drawsocialicon {
    height: 25px !important;
    width: 25px !important;
  }
}

@media (max-width: 994.9px) {
  .Collapsible__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Collapsible__trigger.is-open svg {
    transform: rotate(-90deg);
    font-size: 26px;
  }
  .Collapsible__trigger svg {
    font-size: 26px;
    transition: all ease-in-out 0.3s;
  }
}
