.banner3 {
  padding: 40px 3%;
}

.banner3 img {
  /* max-width: 100%; */
  height: auto;
  width: 100%;
}

@media (min-width: 1400px) and (max-width: 1629px) {
  .container {
    max-width: 1370px;
  }
}

.wblbookmain {
  text-align: center;
  position: relative;
}

.wblbook img {
  border-radius: 10px;
}

.wblbookmain .wblookbook {
  position: absolute;
  cursor: pointer;
  min-width: 25px;
  min-height: 25px;
}

.wblbookmain {
  text-align: center;
  position: relative;
}

.wblbookmain .wblookbook .wblookbtn {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 25px;
  min-height: 25px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transition: all .3s ease;
  z-index: 2;
  background: #3d405b;
  border-radius: 100%;
}

.wblbookmain .wblookbook .wblookbtn {
  width: 25px;
  height: 25px;
  padding: 6px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: rgba(255, 255, 255, .8);
  border-radius: 100%;
  transition: padding .3s ease;
}

.wblbookmain .wblookbook .wblookbtn svg {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 25px;
  min-height: 25px;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transition: all .3s ease;
  z-index: 2;
  background: #3d405b;
  border-radius: 100%;
  padding: 6px;
}

.wblbookmain .wblookbook .wblookbtn:after {
  content: "";
  position: absolute;
  /* left: 50%;
  top: 50%; */
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  border-radius: 100%;
  animation: pulse 1.5s ease-out infinite;
}

.wblbookmain .wblookbook .wblookbtn svg path {
  fill: #fff !important;
}

.wblbookmain .wblookbtn.active~.wblbookcnt,
.wblookbleft.active {
  display: block;
}

.wblbookmain .wblookbook .wblbookcnt {
  display: none;
  z-index: 3;
  width: 150px;
  position: relative;
  background: rgb(255, 255, 255);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, .05);
  border-radius: 10px;
  margin-top: 32px;
  left: -60px;
  padding: 10px 0;
}

.wblbookmain .wblookbook .wblookimg a {
  display: block;
}

.wblbook img {
  border-radius: 10px;
}

.wblookdesc {
  padding: 10px 5px 0;
}

.wblookdesc h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(14 * 1px);
  margin-bottom: 4px;
  font-weight: 500;
  text-transform: capitalize;
}

.wblookprice span {
  font-weight: 600;
}

.wblookprice span.old-price {
  text-decoration: line-through;
  font-weight: 400;
  font-size: 13px;
  padding-left: 2px;
  color: rgba(0, 0, 0, .5);
}

.wblookimg img {
  width: 65%;
  height: 65%;
}

.pulse {
  margin: 100px;
  display: none;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: rgb(0, 0, 0);
  cursor: pointer;
  box-shadow: 0 0 0 rgb(0, 0, 0);
  animation: pulse 2s infinite;
}

.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(0, 0, 0);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(0, 0, 0);
    box-shadow: 0 0 0 0 rgb(0, 0, 0);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}


/* add by laxmi */

@media (max-width:381px) {
  .wblbookmain .wblookbook .wblbookcnt {
    display: none;
    z-index: 3;
    width: 100px !important;
    position: relative;
    background: rgb(255, 255, 255);
    box-shadow: 2px 2px 8px rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin-top: 25px !important;
    left: -32px !important;
    padding: 0px 0 !important;
  }
  .wblookimg img {
    width: 50%;
    height: 50%;
}
  .wblookprice span {
    font-size: 9px !important;
  }

  .wblookdesc h3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: calc(10* 1px) !important;
    margin-bottom: -5px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .wblookdesc {
    padding: 0px 2px 0 !important;
  }

  .butnPlus {
    padding: 5px 0px !important;
    border-radius: 20px !important;
    font-size: 7px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 81px !important;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 6px;
  }
}

@media (min-width:381px) and (max-width:995px) {
  /* .butnPlus {
    padding: 9px 0px !important;
    border-radius: 20px;
    font-size: 12px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 118px !important;
    text-align: center;
    cursor: pointer;
    margin-top: 6px;
  } */

  /* .butnPlus {
    padding: 8px 0px !important;
    border-radius: 20px;
    font-size: 11px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 114px !important;
    text-align: center;
    cursor: pointer;
    margin-top: 4px;
} */

.butnPlus {
  padding: 6px 0px !important;
  border-radius: 20px;
  font-size: 10px !important;
  background-color: #c83363;
  color: #ffffff !important;
  width: 114px !important;
  text-align: center;
  cursor: pointer;
  margin-top: 3px;
}
}


@media (min-width:381px) and (max-width:460px){
  .wblbookmain .wblookbook .wblbookcnt {
    display: none;
    z-index: 3;
    width: 140px;
    position: relative;
    background: rgb(255, 255, 255);
    box-shadow: 2px 2px 8px rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin-top: 28px;
    left: -60px;
    padding: 0px 4px 10px 4px;
}
.wblookimg img {
  width: 48%;
  height: 60%;
}
.wblookdesc {
  padding: 0px;
}
.wblookdesc h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(11 * 1px);
  margin-bottom: -5px;
  font-weight: 500;
  text-transform: capitalize;
}
.wblookprice span {
  font-weight: 600;
  font-size: 11px !important;
}
}


@media (min-width:460px) and (max-width:570px){
  .wblbookmain .wblookbook .wblbookcnt {
    display: none;
    z-index: 3;
    width: 140px;
    position: relative;
    background: rgb(255, 255, 255);
    box-shadow: 2px 2px 8px rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin-top: 31px;
    left: -60px;
    padding: 10px 0;
}
.wblookimg img {
  width: 60%;
  height: 60%;
}
.wblookdesc {
  padding: 0px 3px 0;
}
.wblookdesc h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(13 * 1px);
  margin-bottom: -2px;
  font-weight: 500;
  text-transform: capitalize;
}
.wblookprice span {
  font-weight: 600;
  font-size: 13px !important;
}
}

