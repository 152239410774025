.banner1 .carousel-caption {
  position: absolute;
  top: 35px;
  left: 3%;
  /* padding-top: 1.25rem; */
  /* padding-bottom: 1.25rem; */
  color: #fff;
  text-align: left !important;
}

.left-text-banner {
  position: relative;
  width: 100%;
  padding: 5% 0px;
}

.left-text-banner h3 {
  color: #3d405b;
  font-size: 16px !important;
  font-weight: bolder !important;
  width: 50% !important;
}

.left-text-banner h2 {
  color: #000000;
  font-size: 48px !important;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 30px !important;
  margin-top: 30px !important;
  /* width: 50% !important; */

  /* change by laxmi */
  width: 60% !important;
}

.left-text-banner p {
  color: #000000;
  text-transform: capitalize;
  line-height: 24px !important;
  opacity: 0.9;
  font-size: 16px !important;
  font-weight: 500;
  width: 48%;
}

.but-banner {
  background: #3d405b;
  color: #ffffff;
  display: inline-flex;
  border-radius: 10px;
  font-size: 15px;
  margin-top: 40px;
  padding: 12px 35px;
  text-align: center;
}

.slick-slider {
  outline: none !important;
}

/* .left-text-banner::before {
  background-image: url(/../pinkparrotkids/src/Component/Images/ban1.webp);
  background-size: cover;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
} */
.text-ab {
  position: absolute;
  top: 20%;
  left: 30px;
  width: 100%;
  /* padding: 50px 0px; */
}

/* Add by laxmi */

.homeBannerbtn {
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff !important;
  width: 140px;
  text-align: center;
  cursor: pointer;
}

@media (min-width: 940px) and (max-width: 1150px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 35px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 30px !important;
    margin-top: 10px !important;
    width: 51% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 22px !important;
    opacity: 0.9;
    font-size: 15px !important;
    font-weight: 500;
    width: 48%;
  }

  /* .carousel-indicators {
    display: none !important;
  } */

  .banner1 .carousel-caption {
    position: absolute;
    top: 10px !important;
    left: 3%;
    color: #fff;
    text-align: left !important;
  }
}

@media (min-width: 700px) and (max-width: 940px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 28px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 13px !important;
    margin-top: -28px !important;
    width: 65% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 24px !important;
    opacity: 0.9;
    font-size: 15px !important;
    font-weight: 500;
    width: 60%;
    text-align: justify;
  }

  .homeBannerbtn {
    padding: 9px 0px;
    border-radius: 20px;
    font-size: 15px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 140px;
    text-align: center;
    cursor: pointer;
  }

  /* .carousel-indicators {
    display: none !important;
  } */
}

@media (min-width: 620px) and (max-width: 700px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 24px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 9px !important;
    margin-top: -36px !important;
    width: 80% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 24px !important;
    opacity: 0.9;
    font-size: 15px !important;
    font-weight: 500;
    width: 70%;
    text-align: justify;
  }

  .homeBannerbtn {
    padding: 9px 0px;
    border-radius: 20px;
    font-size: 15px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 140px;
    text-align: center;
    cursor: pointer;
  }

  /* .carousel-indicators {
    display: none !important;
  } */
}


@media (min-width: 500px) and (max-width: 620px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 24px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 9px !important;
    margin-top: -20px !important;
    width: 100% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 22px !important;
    opacity: 0.9;
    font-size: 15px !important;
    font-weight: 500;
    width: 75%;
    text-align: justify;
  }

  .homeBannerbtn {
    padding: 7px 0px;
    border-radius: 20px;
    font-size: 16px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 120px;
    text-align: center;
    cursor: pointer;
  }

  /* .carousel-indicators {
    display: none !important;
  } */
}

@media (min-width: 410px) and (max-width: 500px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 20px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 10px !important;
    margin-top: -15px !important;
    width: 100% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 22px !important;
    opacity: 0.9;
    font-size: 13px !important;
    font-weight: 500;
    width: 94%;
    text-align: justify;
    margin-bottom: 12px;
  }

  .homeBannerbtn {
    padding: 9px 0px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 120px;
    text-align: center;
    cursor: pointer;
  }

  /* .carousel-indicators {
    display: none !important;
  } */
}

@media (min-width: 338px) and (max-width: 410px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 17px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 7px !important;
    margin-top: -10px !important;
    width: 95% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 20px !important;
    opacity: 0.9;
    font-size: 13px !important;
    font-weight: 500;
    width: 90%;
    text-align: justify;
    margin-bottom: 12px;
  }

  .homeBannerbtn {
    padding: 9px 0px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 120px;
    text-align: center;
    cursor: pointer;
  }

  /* .carousel-indicators {
    display: none !important;
  } */
}


@media (min-width: 200px) and (max-width: 338px) {
  .left-text-banner h2 {
    color: #000000;
    font-size: 16px !important;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 10px !important;
    margin-top: -8px !important;
    width: 94% !important;
  }

  .left-text-banner p {
    color: #000000;
    text-transform: capitalize;
    line-height: 19px !important;
    opacity: 0.9;
    font-size: 12px !important;
    font-weight: 500;
    width: 90%;
    text-align: justify;
    margin-bottom: 6px;
  }

  .homeBannerbtn {
    padding: 9px 0px;
    border-radius: 20px;
    font-size: 12px;
    background-color: #c83363;
    color: #ffffff !important;
    width: 95px;
    text-align: center;
    cursor: pointer;
  }

  /* .carousel-indicators {
    display: none !important;
  } */
}


.homeBannerbtn a {
  color: #ffffff !important;
}

.butn a {
  color: #ffffff !important;
}


/* new update ui  */
@media (max-width:600px){
  .banner1 .carousel-caption {
    position: relative;
    top: 10px !important;
    left: 3%;
    color: #fff;
    text-align: left !important;
  }
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 8px !important;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #c83363;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .7;
  transition: opacity .6s ease;
}

/* .banner1 .carousel-indicators{
  display: none !important;
} */

@media (max-width:600px){
  .carousel-indicators {
   display: none !important;
}
}