.Address {
  width: 50%;
  margin: auto;
}
.text-center-address {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 35px;
  text-align: center;
  font-size: 40px;
}
.Address-return {
  color: #3d405b;
  font-weight: 600;
}
.but-address {
  padding-bottom: 30px;
}

.Address-Card {
  border: 1px solid #eeeeee;
  border-radius: 20px;
  display: block;
  padding: 15px;
  width: 20%;
  margin-right: 30px;
}

.Address-Card p {
  margin-top: 0;
  margin-bottom: 0.5rem !important;
}

.Address-form {
  border: 1px solid #eeeeee;
  border-radius: 20px;
  display: block;
  padding: 15px;
  width: 100%;
}
.Address-form-h2 {
  margin-bottom: 15px;
  font-size: 18px;
}
.edit-regedit {
  padding: 0px;
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}
.Address-form-edit h2 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 20px;
}
.card-address-but {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
  background-color: #3d405b;
  border-radius: 10px;
  color: #ffffff;
  margin-top: 1em;
  outline: none !important;
  box-shadow: none !important;
}
.pd-country {
  padding: 1rem 0.75rem;
}
.pd-3 {
  padding: 0 3%;
}
.formcheck-address .form-check-input {
  width: 1em !important;
}

/* .butn {
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff;
  width: 140px;
  text-align: center;
}
.butn:hover {
  background-color: #ff3772;
} */
.but-address-flex {
  display: flex;
  align-items: center;
}
.pd-aou-but {
  margin-right: 0.5rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-select:focus {
  border-color: #ced4da !important;
  outline: 0;
  box-shadow: none !important;
}

.spinner-border {
  width: 1.1rem;
  height: 1.1rem;
}

.address-edit-delete .butn {
  padding: 5px 0px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #c83363;
  color: #ffffff;
  width: 102px !important;
  text-align: center;
}

.address-edit-delete .butn:focus-visible {
  outline: #c83363 auto 1px;
}

.edit-address-card {
  border: 1px solid #eeeeee;
  border-radius: 20px;
  display: block;
  padding: 15px;
  width: 100%;
}

/* Add by laxmi */
.butnaddAddressNew{
  padding: 10px 0px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #c83363;
  color: #ffffff !important;
  width: 170px !important;
  text-align: center;
  cursor: pointer;
}
.addresshowborder {
  border: 1px solid #eeeeee !important;
  border-radius: 20px !important;
  margin-right: -6px !important;
  padding:10px !important;
  margin-bottom: -30px !important;
}