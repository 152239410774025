.offcanvas-end {
  width: 430px !important;
  height: 100% !important;
  background: #fbfbfb !important;
  box-shadow: -5px 0 74px rgb(0 0 0 / 80%) !;
}

.offcanvas-body {
  padding: 0px !important;
}

.free-delivery-text {
  background: #417505;
  padding: 8px 0;
  margin-bottom: 10px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  margin: 0 auto;
}

.li-cart-container {
  overflow: hidden;
  bottom: 0;
  margin: 10px 0px 10px;
  padding: 0px 5px 15px 5px;
}

.li-cart-message {
  text-align: center;
  padding: 5px;
  border: 1px solid rgb(200, 51, 99) !important;
  border-radius: 10px;
  /* border-left: 1px solid #cbcbcb !important;
  border-right: 1px solid #cbcbcb !important; */

  background: #f9fffa;
  display: none;
  /* margin-bottom: 5px; */
  display: block;
}

.item-available {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background: #fff;
  /* border-top: 1px solid #cbcbcb !important; */
  border-radius: 10px;
  box-shadow: 0 5px 15px -5px rgb(0 0 0 / 10%);
}

.item-available ul {
  list-style: none;
  padding: 0em;
  margin: 0px;
}

.item-available li {
  border-bottom: 1px solid #cbcbcb !important;
  margin-top: 10px;
  list-style: none;
}

.item-available-list {
  display: flex;
  justify-content: space-between;
}

.item-available-text span {
  background: #f2f2f2;
  margin-right: 10px;
  color: #6d6e71;
  padding: 4px 7px;
}

.item-available-text {
  color: rgb(47, 47, 47);
  font-weight: 700;
  font-size: 15px;
  vertical-align: middle;
}

.item-available-list2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  padding: 15px 0;
}

.item-qty {
  border: 1px solid #6d6e71;
  border-radius: 5px;
  /* margin-left: 32px; */
  padding: 3px 5px;
}

.text-red-rupee {
  margin-left: 5px;
  font-weight: 700;
  color: #c83363;
}

.discount-rupee {
  color: #6d6e71;
  text-decoration: line-through;
  margin-left: 5px;
  font-weight: 700;
}

.qty-minus {
  background-color: #f2f2f2;
  border-radius: 2px;
  padding: 0.5em;
  cursor: pointer;
  font-size: 13px;
  color: #c83363;
}

.qty-add {
  padding: 15px;
  font-size: 13px;
}

.qty-plus {
  border-radius: 2px;
  padding: 0.5em;
  font-size: 13px;
  color: #c83363;
  cursor: pointer;
  background-color: #f2f2f2;
}

.banner {
  padding: 1em 0em;
}

.banner-title {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #313131;
  margin: 12px 15px;
}

.banner-nm {
  cursor: pointer;
  border-radius: 5px;
  background: #ffdc93;
  margin: 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 2% 2% 2.3%;
  border: 1px solid #9b9b9b;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 16%);
}

.banner-nm-join {
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  color: #c83363;
  cursor: pointer;
}

.banner-nm-img img {
  height: 35px;
  width: 60%;
  object-fit: contain;
}

.bill {
  width: 95%;
  margin: 50px auto 140px;
  background-color: #fff;
  border: 1px dashed #979797;
  padding: 10px;
}

.bill-heading {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  width: 100%;
  margin-bottom: 7px;
}

.bill-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0px;
}

.bill-details-total {
  color: #6d6e71;
  font-size: 14px;
  font-weight: 700;
}

.bill-details-amount {
  color: #6d6e71;
  font-size: 14px;
  font-weight: 400;
}

.bill-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  margin: 5px 0 0;
  padding-top: 5px;
  border-top: solid 1px #979797;
}

.bill-details-totalam {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.bill-details-amru {
  color: #c83363;
  font-size: 14px;
  font-weight: 700;
}

.wrapper {
  padding: 10px 15px;
  background: #fff;
  box-shadow: 0 -4px 16px rgb(0 0 0 / 16%);
  border-radius: 5px;
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 428px;
  max-width: 100%;
  /* width: 95%;
  margin: 10px; */
}

.wrapper1-flex {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 20px 0px;
}

.wrapper1-flex-div1 {
  font-size: 24px;
  letter-spacing: 0;
  color: #000;
  font-weight: 600;
}

.wrapper1-flex-div2 {
  font-size: 16px;
  color: #c83363;
  font-weight: 700px;
}

.wrapper2-flex {
  display: flex;
  /* justify-content: space-between; */
}

.but-com {
  width: 50%;
}

.but-2 {
  border-radius: 5px;
  text-align: center;
  border: none;
  font-weight: 600;
  transition: all 50ms ease-out;
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
  padding: 12px 30px;
  height: 56px;
  font-size: 15px !important;
  /* background: #cbcbcb; */
  background-color: #c83363 !important;
  width: 100%;
}

.but-1 {
  border-radius: 5px;
  text-align: center;
  border: none;
  font-weight: 600;
  transition: all 50ms ease-out;
  /* box-shadow: 0 0 15px rgb(0 0 0 / 20%); */
  padding: 12px 30px;
  height: 56px;
  font-size: 15px !important;
  background: #cbcbcb;
  width: 100%;
}

.li-empty-cart-container {
  background: #fff;
  overflow: hidden;
  bottom: 0;
  margin: 15px 10px 10px;
}

.li-empty-cart-container .empty-cart {
  text-align: center;
}

.cart-drawer-image {
  width: 25% !important;
}

.cart-drawer-image img {
  width: 71%;
}

.cart-drawer-other-details {
  width: 75% !important;
}

/* .item-available li:last-child{
  border-bottom: none !important;
} */
.item-available li:nth-last-child(0n + 1) {
  border-bottom: none !important;
}

.empty-cart-msg {
  font-size: 1.5rem !important;
}

.clearCart {
  color: #c83363 !important;
  cursor: pointer;
}

.confirmtoDelete {
  padding: 5px 10px;
  background: #c83363;
  border-radius: 5px;
  color: #ffffff !important;
  font-size: 14px;
  text-decoration: none !important;
  cursor: pointer;
  text-transform: uppercase;
}

.cart-sum h1 {
  font-size: 18px;
}

@media (max-width: 330px) {
  .bill-details-amount {
    color: #6d6e71;
    font-size: 13px;
    font-weight: 400;
  }

  .bill {
    width: 90%;
    margin: 70px auto 140px;
    background-color: #fff;
    border: 1px dashed #979797;
    padding: 10px;
  }

  .bill-details-total {
    color: #6d6e71;
    font-size: 13px;
    font-weight: 700;
  }

  .bill-heading {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    width: 100%;
    margin-bottom: 7px;
  }

  .bill-details-amru {
    color: #c83363;
    font-size: 13px;
    font-weight: 700;
  }
}