.detail-page-container {
  justify-content: space-between;
  padding: 1em 0em;
  /* width: 86vw; */
  margin: 0 3%;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}

@media (min-width: 992px) {
  .product--medium:not(.product--no-media) .product__media-wrapper,
  .product--small:not(.product--no-media) .product__info-wrapper {
    max-width: 50%;
    width: calc(50% - 0.5rem);
  }

  .product--medium:not(.product--no-media) .product__info-wrapper,
  .product--small:not(.product--no-media) .product__media-wrapper {
    max-width: 50%;
    width: calc(50% - 0.5rem);
    padding-left: 20px;
  }
}

@media screen and (min-width: 768px) {
  .product--medium:not(.product--no-media) .product__info-wrapper,
  .product--small:not(.product--no-media) .product__media-wrapper {
    max-width: 50%;
    width: calc(50% - 0.5rem);
    padding-left: 20px;
  }

  .grid--2-col-tablet .grid__item {
    width: calc(50% - 0.5rem);
  }

  .grid--2-col-tablet .grid__item {
    width: calc(50% - 0.5rem);
  }

  .product__info-container > *:first-child {
    margin-top: 0;
  }

  .price--large {
    font-size: 1.8rem;
  }

  .price {
    margin-bottom: 0;
  }

  .product__info-container .price--on-sale .price-item--regular {
    font-size: calc(14 * 1.85px);
  }
}

.product__info-container .product__text {
  margin-bottom: 10px;
  text-transform: capitalize;
}

.product__text {
  margin-bottom: 0;
}

p {
  color: rgba(0, 0, 0, 0.6);
}

.product__info-container .product__title {
  font-size: calc(14 * 2px);
  margin-bottom: 10px;
  text-transform: capitalize;
}

.product__title {
  word-break: break-word;
}

.product__info-container .spr-starrating,
.mainp-price,
.product__info-container .product-form__input,
.product__info-container .product__description,
.product__info-container .product-form,
.product__info-container .share-button,
.product__info-container .product-popup-modal__opener {
  margin-bottom: 15px;
  justify-content: start !important;
}

.product__info-container .price {
  align-items: center;
  display: flex;
}

.price--large {
  font-size: 1.6rem;
  letter-spacing: 0.13rem;
}

.visually-hidden {
  display: none;
}

.product__info-container .price .price-item {
  font-size: calc(14 * 2.1px);
}

.price .price-item {
  margin: 0 3px 0 0;
  font-size: calc(14 * 1.43px);
  font-weight: 500;
  letter-spacing: 0;
  display: inline-block;
}

.price--on-sale .price-item--regular {
  text-decoration: line-through;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.6);
  font-size: calc(14 * 1.15px);
  display: inline-block;
}

.product__info-wrapper .product-form__input {
  flex: 0 0 100%;
  padding: 0;
  margin: 0 0 1.2rem;
  max-width: 37rem;
  min-width: fit-content;
  border: none;
}

.product-form__input .form__label {
  padding-left: 0;
}

.form__label {
  display: block;
  margin-bottom: 0.6rem;
}

.product__info-container .product-form__input .quantity {
  width: 120px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
}

.quantity {
  position: relative;
  height: 45px;
  display: flex;
}

.product__info-container .product-form__input .quantity__button {
  width: 40px;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.quantity__button {
  flex-shrink: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

button,
input {
  overflow: visible;
}

.product__info-container .product-form__input .quantity__input {
  width: 40px;
  font-size: calc(14 * 1.29px);
}

.quantity__input {
  color: currentColor;
  font-weight: 500;
  opacity: 0.85;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 0 0.5rem;
  width: 100%;
  flex-grow: 1;
  -webkit-appearance: none;
  appearance: none;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.product__info-container .product-form__buttons .btn-primary {
  padding: 8px 10px;
  min-height: 45px;
  font-size: calc(var(--font-size-base) * 1.15px);
}

.product__info-container .product-form__submit {
  margin-bottom: 1rem;
}

.btn-primary,
.button,
.shopify-challenge__button,
.customer button {
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  line-height: 1.25;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 10px;
  background: #3d405b;
  color: #ffffff;
  transition: 0.3s ease;
  appearance: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
}

.smallImagesScroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 530px;
  overflow-y: scroll;
}

.thumbnail-img img {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 0.3rem;
}

.product__description tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: white;
  border-width: thin;
}

.smallImagesScroll ::-webkit-scrollbar {
  display: none;
}

/* change by laxmi */
.detaildescriptionAlign {
  text-align: justify;
}

.large-img {
  overflow: hidden;
  border-radius: 5px;
}

@media (min-width: 400px) and (max-width: 550px) {
  .spaced-section {
    margin-top: 14px !important;
    overflow-x: hidden;
  }

  .headingDeatilsitemsproduct-page {
    font-size: calc(28 * 1px) !important;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 30px;
  }

  .customer-reviews-Description {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(101, 119, 134, 0.12);
    padding: 25px 0px !important;
  }

  .product-reviews {
    display: flex;
    padding: 10px !important;
    border-bottom: 1px solid rgba(101, 119, 134, 0.22);
  }

  .smallImagesScroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 370px !important;
    overflow-y: scroll;
  }

  .detailpagepehraphnview {
    padding-left: 0% !important;
  }

  .deatilpagebtnphnview {
    padding: 10px 0px !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px !important;
    text-align: center;
    cursor: pointer;
  }

  .writereviewphnview {
    padding: 10px 0px !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px !important;
    text-align: center;
    cursor: pointer;
    margin-left: 25px;
    text-transform: capitalize;
  }

  .ratings-exact {
    font-size: 22px !important;
    line-height: 40px;
    letter-spacing: -0.3px;
  }

  .ratings-upto {
    font-size: 22px !important;
    line-height: 40px;
  }

  .ratings-static {
    font-size: 14px !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: rgba(0, 19, 37, 0.92);
    display: block;
    margin-top: 0.5rem;
  }

  .overall-ratings {
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem 10px !important;
    border-right: 2px solid rgba(101, 119, 134, 0.22) !important;
  }
}

@media (min-width: 280px) and (max-width: 500px) {
  .spaced-section {
    margin-top: 14px !important;
    overflow-x: hidden;
  }

  .product__info-container .product__title {
    font-size: calc(10 * 2px) !important;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  .product__info-container .price .price-item {
    font-size: calc(9 * 2.1px) !important;
  }
}

@media (min-width: 280px) and (max-width: 400px) {
  .headingDeatilsitemsproduct-page {
    font-size: calc(28 * 1px) !important;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 30px;
  }

  .customer-reviews-Description {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(101, 119, 134, 0.12);
    padding: 25px 0px !important;
  }

  .ratings-exact {
    font-size: 22px !important;
    line-height: 40px;
    letter-spacing: -0.3px;
  }

  .ratings-upto {
    font-size: 22px !important;
    line-height: 40px;
  }

  .writereviewphnview {
    padding: 10px 0px !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px !important;
    text-align: center;
    cursor: pointer;
    margin-left: 25px;
    text-transform: capitalize;
  }

  .deatilpagebtnphnview {
    padding: 10px 0px !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    background-color: #c83363;
    color: #ffffff !important;
    width: 110px !important;
    text-align: center;
    cursor: pointer;
  }

  .smallImagesScroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 260px !important;
    overflow-y: scroll;
  }

  .detailpagepehraphnview {
    padding-left: 0% !important;
  }

  .product-reviews {
    display: flex;
    padding: 6px 0px !important;
    border-bottom: 1px solid rgba(101, 119, 134, 0.22);
  }

  .ratings-static {
    font-size: 13px !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: rgba(0, 19, 37, 0.92);
    display: block;
    margin-top: 0.5rem;
  }

  .verified-upto {
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0px;
    color: rgba(0, 19, 37, 0.72);
  }

  .overall-ratings {
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem 5px !important;
    border-right: 2px solid rgba(101, 119, 134, 0.22) !important;
  }

  .css-tm4hnq {
    font-size: 14px !important;
    line-height: 10px !important;
    letter-spacing: -0.1px;
    color: rgba(0, 19, 37, 0.92);
    padding-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .css-1n0nrdk {
    font-size: 12px !important;
    line-height: 1px !important;
    letter-spacing: 0px;
    color: rgba(0, 19, 37, 0.64);
  }

  .css-1at2wjx {
    background-color: rgb(0, 137, 69);
    font-size: 13px !important;
    font-weight: 600;
    line-height: 20px !important;
    letter-spacing: -0.1px;
    display: inline-flex;
    align-items: center;
    color: white;
    border-radius: 4px;
    padding: 3px 5px;
  }

  .css-177e1lt {
    font-size: 15px !important;
    line-height: 16px;
    letter-spacing: 0px;
    float: right;
    color: rgba(0, 19, 37, 0.52);
    margin-top: 0.25rem;
  }

  .customer-reviews-Description {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(101, 119, 134, 0.12);
    padding: 25px 0px !important;
  }
}

@media (max-width: 767px) {
  .product-reviews {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid rgba(101, 119, 134, 0.22);
  }
}

@media (min-width: 550px) and (max-width: 879px) {
  .overall-ratings {
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem 34px 0px 0px !important;
    border-right: 2px solid rgba(101, 119, 134, 0.22);
  }

  .customer-reviews-Description {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(101, 119, 134, 0.12);
    padding: 25px 0px !important;
  }
}

@media (max-width: 320px) {
  .butnYoumaylike {
    padding: 8px 0px !important;
    border-radius: 20px;
    font-size: 13px !important;
    background-color: #c83363;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
}
