.header-flex {
  display: flex;
  justify-content: space-between;
  background: #c83363;
  padding: 10px 30px;
}

/* .top-header {
  background: #3d405b;
  padding: 8px 0;
} */
.parent-1 p {
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  /* margin-top: revert; */
}

.parent-2 {
  display: flex;
  justify-content: space-between;
  width: 20%;
  align-items: center;
}

.parent-2 p {
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  /* margin-top: revert; */
}

.header-2 {
  display: flex;
  justify-content: space-between;
  padding: 1em 0em;
  width: 86vw;
  margin: auto;
}

.header-dropdown {
  position: absolute;
  width: 190px;
  left: auto;
  right: auto;
  padding: 15px 15px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  z-index: 9999;
  display: none;
}

.dropdown1:hover .header-dropdown {
  display: block;
}

.dropdown2:hover .header-dropdown {
  display: block;
}

.header-dropdown a {
  color: #22b7c0 !important;
  line-height: 28px;
  /* padding: 12px 16px; */
  padding: 3px 0px;
  text-decoration: none;
  display: block;
  text-transform: capitalize;
}

.header-dropdown a:hover {
  color: #c83363 !important;
}

.ul-inline {
  list-style: none;
  margin-top: auto;
}

.ul-inline li {
  position: relative;
  list-style: none;
  display: inline-block;
  padding-left: 1.5em;
  font-size: 18px;

  font-weight: 400;
}

/*  */

.li-link {
  display: inline-block;
  color: #22b7c0 !important;
  text-decoration: none;
}

.li-link:hover {
  color: #c83363 !important;
}

.li-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #22b7c0;
  transition: width 0.5s;
}

.li-link:hover::after {
  width: 100%;
  /* transition: width 0.3s; */
}

.header-3 {
  display: flex;
  justify-content: space-between;
}

.header-3-icon {
  width: 24px;
}

/* new */
.icons-header {
  font-size: 25px !important;
  color: #22b7c0 !important;
}

.icons-header a {
  color: #22b7c0 !important;
}

.header-text {
  /* padding-right: 1.5em; */
  font-size: 18px;
  color: #22b7c0 !important;
  font-weight: 400;
}

.header-text a {
  color: #22b7c0 !important;
}

.close-head {
  font-size: 1.5em;
  color: white;
}

.div-searchrecip {
  padding: 0.5em 0em;
}

.search-close-head {
  font-size: 2.5em;
}

/* add by laxmi */
.crossChngepassword {
  font-size: 2em !important;
  color: #c83363;
}

.offcanvas-top {
  top: 7% !important;
  height: 11vh !important;
}

.input-search {
  height: 40px !important;
  border-radius: 30px 0px 0px 30px !important;
  border: 1px solid #999 !important;
  /* box-shadow: 0 0 5px #0003 !important; */
}

.search-but-add {
  height: 40px !important;
  border-radius: 0px 30px 30px 0px !important;
  border: none !important;
  /* box-shadow: 0 0 3px #c83363 !important; */
  width: 9% !important;
  background-color: #c83363 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-but-icon {
  font-size: 30px;
  color: white;
}

.mb-32 {
  width: 70% !important;
  margin: 0px auto 0px auto !important;
  align-items: center !important;
  justify-content: space-around;
}

.contaner-head {
  width: 100%;
  display: flex;
  align-items: center;
  width: 96%;
  margin: auto;
}

@media (max-width: 912px) {
  /* .parent-2 {
    width: 35%;
    font-size: 0.8em;
  } */
}

.hide-mobile {
  /* display: block !important; */
}

.pd-r-but {
  display: none !important;
}

.flex-left-header {
  flex-direction: column !important;
  align-items: end !important;
  -webkit-justify-content: flex-end;
  align-items: flex-end !important;
}

.marin-ri-0 {
  margin-right: 0px !important;
}

/* change by laxmi */

@media (min-width: 800px) and (max-width: 995px) {
  .hide-mobile {
    display: none !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 2em;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 35%;
    font-size: 0.8em;
  }

  .logo {
    width: 65px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 17px !important;
    top: 29px !important;
  }
}

.link-flex-header {
  display: flex;
  align-items: center;
}

.pa-lr-text {
  padding: 0px 0.5em;
}

.account-dropdown {
  position: absolute;
  width: auto;
  left: auto;
  right: 20px;
  /* padding: 15px 15px; */
  padding: 11px 12px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  z-index: 5;
  display: none;
  font-size: 1.1rem;
}

.account-dropdown a {
  color: black;
  line-height: 28px;
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
  text-transform: capitalize;
}

.predictive-search {
  position: absolute;
  /* top: calc(100% + 0.1rem); */
  width: 68%;
  left: 15%;
  z-index: 3;
  background: rgb(255, 255, 255);
  border-radius: 4px;
}

#predictive-search-results {
  background: rgb(255, 255, 255);
  border: 1px solid #eeeeee;
  padding: 15px;
  border-radius: 4px;
  overflow-y: scroll;
  max-height: 500px;
}

.predictive-search__heading {
  margin: 0 auto 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(14 * 1.29px);
}

.predictive-search-results .list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.predictive-search__item--link {
  display: grid !important;
  grid-template-columns: 6rem 1fr;
  grid-column-gap: 0rem;
  grid-template-areas: "product-image product-content";
  align-items: center;
  margin-bottom: 15px;
}

.predictive-search__item {
  display: flex;
  text-align: left;
  width: 100%;
}

a {
  color: rgb(0, 0, 0);
  transition: 0.3s ease;
}

.predictive-search__image {
  grid-area: "product-image";
  object-fit: contain;
  font-family: "object-fit: contain";
  border: 1px solid #eeeeee;
  border-radius: 4px;
}

.predictive-search__item-content {
  display: flex;
  line-height: normal;
  justify-content: space-between;
}

.predictive-search__item-heading {
  font-size: calc(14 * 1.15px);
  text-transform: capitalize;
  margin-bottom: 10px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.price--on-sale .price__sale {
  display: block;
  letter-spacing: 0;
}

.predictive-search .price .price-item {
  margin: 0 3px 0 0;
  font-size: calc(14 * 1.15px);
  font-weight: 700;
  letter-spacing: 0;
  display: inline-block;
}

.predictive-search .price--on-sale .price-item--regular {
  text-decoration: line-through;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.6);
  font-size: calc(14 * 1.15px);
  display: inline-block;
}

.predictive-search__item--term {
  justify-content: space-between;
  align-items: center;
  padding: 0;
  word-break: break-all;
  margin: 0;
  font-size: calc(14 * 1.15px);
}

.predictive-search__item--term .icon-arrow {
  flex-shrink: 0;
}

.icon-arrow {
  width: 1.5rem;
}

.predictive-search .spinner {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0;
}

.spinner {
  animation: rotator 1.4s linear infinite;
}

predictive-search:not([loading]) .predictive-search__heading .spinner,
predictive-search:not([loading]) .predictive-search__loading-state,
predictive-search:not([loading]) .predictive-search-status__loading {
  display: none;
}

.wbhcartitem,
.wbwishcount {
  background: #c83363;
  color: #ffffff;
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 14px;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  right: 26px;
  top: 25px;
}

/* Add by laxmi */

.modal-open {
  padding-right: 0 !important;
}

body.modal-open {
  padding-right: 0 !important;
}

.modal {
  padding-right: 0px !important;
}

@media (min-width: 1300px) and (max-width: 995px) {
  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 21px;
    height: 20px;
    text-align: center;
    font-size: 11px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 27px;
    top: 29px;
  }
}

@media (min-width: 995px) and (max-width: 1414px) {
  .parent-2 {
    display: flex;
    justify-content: space-between;
    width: 30%;
    align-items: center;
  }
}

@media (min-width: 800px) and (max-width: 995px) {
  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .headerIconSize {
    font-size: 30px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 30px;
  }

  .logo {
    width: 55px !important;
  }
}

@media (min-width: 710px) and (max-width: 800px) {
  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 18px !important;
    height: 18px !important;
    text-align: center;
    font-size: 12px !important;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }

  .close-head {
    font-size: 25px;
    color: white;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 12px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 30%;
    font-size: 0.8em;
  }

  .headerIconSize {
    font-size: 25px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 25px;
  }

  .logo {
    width: 50px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 17px !important;
    top: 29px !important;
  }

  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
}

@media (min-width: 620px) and (max-width: 710px) {
  .paddingPhnView {
    padding: 0% !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 16px !important;
    height: 16px !important;
    text-align: center;
    font-size: 9px !important;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }

  .close-head {
    font-size: 20px !important;
    color: white;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 12px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 34%;
    font-size: 0.8em;
  }

  .headerIconSize {
    font-size: 20px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 20px;
  }

  .logo {
    width: 45px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 17px !important;
    top: 15px !important;
  }

  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
}

@media (min-width: 550px) and (max-width: 800px) {
  .mb-32 {
    width: 88% !important;
    margin: 0px auto 0px auto !important;
    align-items: center !important;
    justify-content: space-around;
  }
}

@media (min-width: 400px) and (max-width: 550.9px) {
  .search-but-add {
    height: 40px !important;
    border-radius: 0px 30px 30px 0px !important;
    border: none !important;
    width: 13% !important;
    background-color: #c83363 !important;
    justify-content: center;
  }
}

@media (min-width: 200px) and (max-width: 400.9px) {
  .search-but-add {
    height: 40px !important;
    border-radius: 0px 30px 30px 0px !important;
    border: none !important;
    width: 16% !important;
    background-color: #c83363 !important;
    justify-content: center;
  }
}

@media (min-width: 550px) and (max-width: 620px) {
  .paddingPhnView {
    padding: 0% !important;
  }

  .search-but-add {
    height: 40px !important;
    border-radius: 0px 30px 30px 0px !important;
    border: none !important;
    width: 13% !important;
    background-color: #c83363 !important;
    justify-content: center;
  }

  .headerIconSize {
    font-size: 20px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 20px;
  }

  .logo {
    width: 45px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 12px !important;
    top: 13px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 16px !important;
    height: 16px !important;
    text-align: center;
    font-size: 11px !important;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }

  .close-head {
    font-size: 20px;
    color: white;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    background: #c83363;
    padding: 5px 19px;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 12px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 40%;
    font-size: 0.8em;
  }

  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
}

@media (min-width: 400px) and (max-width: 550px) {
  .mb-32 {
    width: 88% !important;
    margin: 0px auto 0px auto !important;
    align-items: center !important;
    justify-content: space-around;
  }

  .paddingPhnView {
    padding: 0% !important;
  }

  .headerIconSize {
    font-size: 20px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 20px;
  }

  .logo {
    width: 45px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 16px !important;
    height: 16px !important;
    text-align: center;
    font-size: 11px !important;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }

  .close-head {
    font-size: 20px;
    color: white;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    background: #c83363;
    padding: 4px 20px;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 10px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 44%;
    font-size: 0.8em;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 10px !important;
    top: 12px !important;
  }

  .freeShippingTop {
    padding-top: 0.25rem !important;
  }

  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
}

@media (min-width: 370px) and (max-width: 400px) {
  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .mb-32 {
    width: 88% !important;
    margin: 0px auto 0px auto !important;
    align-items: center !important;
    justify-content: space-around;
  }

  .paddingPhnView {
    padding: 0% !important;
  }

  .headerIconSize {
    font-size: 20px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 20px;
  }

  .logo {
    width: 45px !important;
  }

  .close-head {
    font-size: 20px;
    color: white;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    background: #c83363;
    padding: 4px 17px;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 10px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 48%;
    font-size: 0.8em;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 11px !important;
    top: 12px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 9px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }

  .freeShippingTop {
    padding-top: 0.25rem !important;
  }
}

@media (min-width: 340px) and (max-width: 370px) {
  .mb-32 {
    width: 88% !important;
    margin: 0px auto 0px auto !important;
    align-items: center !important;
    justify-content: space-around;
  }

  .paddingPhnView {
    padding: 0% !important;
  }

  .headerIconSize {
    font-size: 18px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 18px;
  }

  .logo {
    width: 40px !important;
  }

  .freeShippingTop {
    padding-top: 0.25rem !important;
  }

  .close-head {
    font-size: 18px;
    color: white;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    background: #c83363;
    padding: 4px 14px;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 9px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 48%;
    font-size: 0.8em;
  }

  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 10px !important;
    top: 12px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 16px;
    height: 16px;
    text-align: center;
    font-size: 10px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }
}

@media (min-width: 300px) and (max-width: 340px) {
  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .mb-32 {
    width: 88% !important;
    margin: 0px auto 0px auto !important;
    align-items: center !important;
    justify-content: space-around;
  }

  .paddingPhnView {
    padding: 0% !important;
  }

  .headerIconSize {
    font-size: 18px !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 18px;
  }

  .logo {
    width: 40px !important;
  }

  .freeShippingTop {
    padding-top: 0.25rem !important;
  }

  .close-head {
    font-size: 18px;
    color: white;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    background: #c83363;
    padding: 4px 14px;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 8px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 49%;
    font-size: 0.8em;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 10px !important;
    top: 12px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 16px;
    height: 16px;
    text-align: center;
    font-size: 10px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }
}

@media (min-width: 280px) and (max-width: 300px) {
  .headerphnseachiconPadding {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .mb-32 {
    width: 88% !important;
    margin: 0px auto 0px auto !important;
    align-items: center !important;
    justify-content: space-around;
  }

  .paddingPhnView {
    padding: 0% !important;
  }

  .headerIconSize {
    font-size: 17px !important;
  }

  .freeShippingTop {
    padding-top: 0.25rem !important;
  }

  .close-head {
    font-size: 17px;
    color: white;
  }

  .header-flex {
    display: flex;
    justify-content: space-between;
    background: #c83363;
    padding: 1px 12px;
  }

  .hide-mobile {
    display: none !important;
  }

  .offerFont {
    font-size: 6px !important;
  }

  .flex-left-header {
    justify-content: flex-end !important;
  }

  .pd-r-but {
    padding-right: 0.5em;
    font-size: 17px;
  }

  .pd-r-but {
    display: block !important;
  }

  .parent-2 {
    width: 42%;
    font-size: 0.8em;
  }

  .logo {
    width: 40px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    right: 10px !important;
    top: 12px !important;
  }

  .wbhcartitem,
  .wbwishcount {
    background: #c83363;
    color: #ffffff;
    width: 16px;
    height: 16px;
    text-align: center;
    font-size: 10px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    right: 26px;
    top: 25px;
  }
}

.header-flex marquee {
  color: #ffffff;
}

@media (max-width: 625px) {
  .butnsearchphnhome {
    display: none !important;
  }

  .pricephnserchome {
    display: none !important;
  }
}

@media (min-width: 760px) and (max-width: 800px) {
  .predictive-search {
    position: absolute;
    width: 75.3% !important;
    left: 8% !important;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }
}

@media (min-width: 621px) and (max-width: 760.9px) {
  .predictive-search {
    position: absolute;
    width: 75%;
    left: 8%;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }

  #predictive-search-results {
    background: rgb(255, 255, 255);
    border: 1px solid #eeeeee;
    padding: 8px;
    border-radius: 4px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 500px;
  }
}

@media (min-width: 760px) {
  .predictive-search {
    position: absolute;
    /* top: calc(100% + 0.1rem); */
    width: 59.8%;
    left: 17%;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }

  #predictive-search-results {
    background: rgb(255, 255, 255);
    border: 1px solid #eeeeee;
    padding: 11px;
    border-radius: 4px;
    overflow-y: scroll;
    max-height: 500px;
  }
}

@media (max-width: 621px) {
  #predictive-search-results {
    background: rgb(255, 255, 255);
    border: 1px solid #eeeeee;
    padding: 8px;
    border-radius: 4px;
    overflow-y: scroll;
    max-height: 500px;
  }
}

@media (min-width: 500px) and (max-width: 621.9px) {
  .predictive-search {
    position: absolute;
    width: 70%;
    left: 9%;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }
}

@media (min-width: 400px) and (max-width: 500.9px) {
  .predictive-search {
    position: absolute;
    width: 68% !important;
    left: 9%;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }
}

@media (min-width: 200px) and (max-width: 400.9px) {
  .predictive-search {
    position: absolute;
    width: 64% !important;
    left: 9%;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }
}

@media (max-width: 500px) {
  .searchdropitemlastarrow {
    font-size: 15px !important;
  }

  .predictive-search__item--link {
    display: grid !important;
    grid-template-columns: 4rem 1fr !important;
    grid-column-gap: 0rem;
    grid-template-areas: "product-image product-content";
    align-items: center;
    margin-bottom: 15px;
  }

  .predictive-search {
    position: absolute;
    /* top: calc(100% + 0.1rem); */
    width: 71.5%;
    left: 10%;
    z-index: 3;
    background: rgb(255, 255, 255);
    border-radius: 4px;
  }

  .predictive-search__item-heading {
    font-size: calc(11 * 1.15px) !important;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .imgphnviewsearchheader {
    width: 50px !important;
    height: 100% !important;
  }

  .predictive-search__heading {
    margin: 0px auto 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc(12 * 1.29px);
  }
}
