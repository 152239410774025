p {
  color: rgba(0, 0, 0, 0.6);
}

.cart-page-container {
  justify-content: space-between;
  padding: 1em 0em;
  /* width: 86vw; */
  margin: 0 3%;
}

.carthead {
  display: flex;
  justify-content: space-between;
}

.heading {
  font-size: calc(40 * 1px);
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 30px;
}

.carthead a.underlined-link {
  text-decoration: underline;
  color: #3d405b;
  font-size: calc(14 * 1.15px);
}

@media screen and (min-width: 768px) {
  .cart-items {
    border-spacing: 0;
    border-collapse: separate;
    box-shadow: none;
    width: 100%;
    display: table;
  }

  .medium-hide {
    display: none !important;
  }

  .price {
    margin-bottom: 0;
  }

  .cart-items thead th:first-child {
    width: 50%;
  }

  .cart-items th {
    border-bottom: 1px solid #eeeeee;
  }

  .cart-items th + th,
  .cart-item > td + td {
    padding-left: 15px;
  }

  .cart-item__media {
    width: 10rem;
  }

  .cart-item__media {
    position: relative;
  }

  .cart-item__details {
    width: 35rem;
  }

  .cart-item .loading-overlay {
    right: 0;
    padding-top: 4.5rem;
    bottom: auto;
  }

  .cart__footer {
    display: flex;
    justify-content: space-between;
  }

  .cart__footer > * {
    width: 35rem;
  }
}

@media screen and (min-width: 992px) {
  .cart-items thead th:first-child {
    width: 60%;
  }

  .cart-item__details {
    width: 50rem;
  }

  .large-up-hide {
    display: none !important;
  }

  .cart-item .cart-item__quantity,
  .cart-items .cart-items__heading--wide {
    padding-left: 15px;
  }
}

.cart-items thead th {
  text-transform: capitalize;
}

.cart-items th {
  padding-bottom: 10px;
  opacity: 0.85;
  font-weight: 400;
}

.cart-items td,
.cart-items th {
  padding: 0;
  border: none;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

.cart-item {
  display: table-row;
}

/* .cart-items td {
  padding-top: 4px !important;
} */

.cart-item__media img {
  border-radius: 4px;
  border: 1px solid #eeeeee;
}

.cart-item__name {
  display: block;
  font-size: calc(14 * 1.15px);
  text-transform: capitalize;
  font-weight: 700 !important;
}

.cart-item__details > * {
  margin: 0;
  max-width: 30rem;
}

.break {
  word-break: break-word;
}

.cart-item__details > * + * {
  margin-top: 8px;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  word-wrap: normal !important;
}

.cart-item__final-price {
  font-weight: 400;
  font-size: 1.1rem;
}

b,
strong {
  font-weight: bolder;
}

.cart-item__old-price {
  opacity: 0.7;
}

.discounts {
  font-size: calc(var(--font-size-base) * 1.15px);
}

.cart-item__name ~ .product-option {
  font-weight: 700;
  letter-spacing: 0;
}

.discounts {
  font-size: calc(14 * 1.15px);
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.discounts__discount {
  display: flex;
  align-items: center;
}

.discounts {
  font-size: calc(14 * 1.15px);
}

.discounts__discount > .icon {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.7rem;
}

/* svg {
    width: 14px;
    height: 14px;
    transition: .3s ease;
    vertical-align: middle;
} */
svg {
  overflow: hidden;
  vertical-align: middle;
}

.cart-item__totals {
  position: relative;
}

.right {
  text-align: right;
}

.cart-item__quantity-wrapper {
  display: flex;
}

.cart-item__quantity-wrapper quantity-input {
  border: 1px solid #eeeeee;
  position: relative;
  width: 120px;
  border-radius: 4px;
}

.quantity {
  position: relative;
  height: 30px;
  display: flex;
}

.cart-item cart-remove-button {
  display: inline-block;
  margin-left: 10px;
  align-items: center;
  display: flex;
}

a {
  color: rgb(0, 0, 0);
  transition: 0.3s ease;
}

.cart-item .loading-overlay {
  top: 0;
  left: auto;
  right: auto;
  bottom: 0;
  padding: 0;
}

.loading-overlay {
  position: absolute;
  z-index: 1;
  width: 1.8rem;
}

/* .hidden {
    display: none!important;
} */
.loading-overlay__spinner {
  width: 14px;
  display: inline-block;
}

.spinner {
  animation: rotator 1.4s linear infinite;
}

.cart-item__price-wrapper {
  font-weight: 500;
  font-size: calc(14 * 1.15px);
}

.cart-item__price-wrapper > * {
  display: block;
  margin: 0;
  padding: 0;
}

.cart-item__discounted-prices dd {
  margin: 0;
}

.price--end {
  justify-content: flex-end;
  font-weight: 700;
}

.cart-item__discounted-prices dd {
  margin: 0;
}

.cart-item__quantity-wrapper .quantity__input,
.cart-item__quantity-wrapper .quantity__button {
  width: 40px;
}

.quantity__input {
  color: currentColor;
  font-weight: 500;
  opacity: 0.85;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 0 0.5rem;
  width: 100%;
  flex-grow: 1;
  -webkit-appearance: none;
  appearance: none;
}

.cart__footer {
  text-align: right;
}

.cart__footer {
  border-top: 1px solid #eeeeee;
  margin-top: 30px;
}

.cart__footer > div:only-child {
  margin-left: auto;
}

.totals {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 20px;
}

.totals__subtotal {
  font-size: calc(14 * 1.15px);
  font-weight: 700;
}

.totals * {
  line-height: 1;
}

.totals > * {
  margin: 0;
}

.totals__subtotal-value {
  font-size: calc(14 * 1.57px) !important;
  color: rgb(0, 0, 0);
  font-weight: 700;
}

.totals > * + * {
  margin-left: 15px;
}

.tax-note {
  display: block;
  font-size: calc(14 * 1px);
  margin-top: 15px;
  margin-bottom: 15px;
}

.small,
small {
  font-size: 80%;
  font-weight: 700;
}

.cart__ctas {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.cart__ctas button {
  width: 100%;
}

.butn2 a {
  color: #fff !important;
}

.cart__checkout-button {
  max-width: 20rem;
}

.btn-primary,
.button,
.shopify-challenge__button,
.customer button {
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  line-height: 1.25;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 10px;
  background: #3d405b;
  color: #ffffff;
  transition: 0.3s ease;
  appearance: none;
  font-size: rgb(0, 0, 0);
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
}
.cart-footer {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .cart-items td.cart-item__quantity {
    padding-top: 0;
    margin-top: -14px;
  }

  .cart-item {
    display: grid;
    grid-template: repeat(2, auto) / repeat(4, 1fr);
    gap: 15px;
    padding: 4px;
  }

  .cart-item__media {
    grid-row: 1 / 3;
  }

  .cart-item__details {
    grid-column: 2 / 4;
  }

  .cart-item__totals {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .cart-items td.cart-item__quantity {
    padding-top: 0;
    margin-top: -14px;
  }

  .cart-item__quantity {
    grid-column: 2 / 5;
  }

  .cart-item__quantity-wrapper {
    flex-wrap: wrap;
  }

  .cart-items thead tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
  }

  .small-hide {
    display: none !important;
  }

  .cart-item__price-wrapper {
    font-size: calc(var(--font-size-base) * 1px);
    letter-spacing: 0;
  }
}

.cart-remove-button .icon-remove {
  height: 20px !important;
  width: 20px !important;
}

/* add by laxmi-------------------------- */

@media (max-width: 500px) {
  .headingCartPage {
    font-size: calc(25 * 1px) !important;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 30px;
  }
}

@media (max-width: 388px) {
  .cart-item__name {
    display: block;
    font-size: calc(12 * 1.15px) !important;
    text-transform: capitalize;
    font-weight: 700 !important;
  }

  .cart-item__final-price {
    font-weight: 400;
    font-size: 13px !important;
  }

  .quantity {
    position: relative;
    height: 35px !important;
    display: flex;
  }
}
