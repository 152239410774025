.Account {
  /* width: 50%;
  margin: auto; */
}

.text-center-account {
  /* font-weight: 700; */
  text-transform: capitalize;
  margin-top: 35px;
  text-align: center;
  font-size: 40px;
}

.Account-headtext {
  margin-bottom: 30px;
}

.Account-headtext h2 {
  font-size: 20px;
}

.Account-headtext p {
  color: #9e8f86;
  font-size: 13px;
  margin-bottom: 8px;
}

.register-but-flex {
  display: flex;
  align-items: center;
  gap: 5px;
}

.card-Account-but {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0.5rem 0.75rem;
  background-color: #3d405b;
  border-radius: 10px;
  color: #ffffff;
}

.pd-3 {
  padding: 0px 3%;
}

.butn-account {
  padding-right: 0.5rem;
}

.disabled-button-order {
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 15px;
  background-color: #cecece;
  color: #ffffff !important;
  width: 140px;
  text-align: center;
  cursor: pointer;
}


@media (max-width: 995px) {
  .card-login-but {
    font-size: 12px;
  }

  .butn-account {
    width: 50%;
    padding-right: 0rem;
  }
}

.account-details-card {
  padding: 5%;
  margin-top: 5%;
  border-radius: 20px;
}

tbody+thead {
  display: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff !important;
  background-color: #c83363;
  border-color: #c83363;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: rgba(0, 0, 0, 0.125);
  /* border-style: ridge; */
  border-width: thin;
}

/* add by laxmi--------------------------------- */
@media (min-width: 354px) and (max-width: 500px) {
  .text-center-account {
    text-transform: capitalize;
    margin-top: 35px;
    text-align: center;
    font-size: 30px;
  }
}

@media (max-width: 354px) {
  .text-center-account {
    display: none;
  }
}